import React, { useState, useEffect } from 'react';
import { Alert, Snackbar, Link } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';

const EmailVerificationAlert = () => {
  const { currentUser, isEmailVerified, setIsEmailVerified, websocketService } = useAuth();
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    console.log('EmailVerificationAlert: Component mounted');
    console.log('Current user:', currentUser);
    console.log('Is email verified:', isEmailVerified);

    if (currentUser && !isEmailVerified) {
      console.log('Setting up email verification event listener');

      const handleEmailVerificationChanged = ({ userId, isVerified }) => {
        console.log('Received emailVerificationChanged event:', { userId, isVerified });
        if (userId === currentUser.id) {
          console.log('Updating email verification status');
          setIsEmailVerified(isVerified);
          if (isVerified) {
            setNotification({
              open: true,
              message: 'Your email has been verified successfully!',
              severity: 'success'
            });
          }
        }
      };

      websocketService.on('emailVerificationChanged', handleEmailVerificationChanged);

      return () => {
        console.log('EmailVerificationAlert: Component unmounting');
        websocketService.off('emailVerificationChanged', handleEmailVerificationChanged);
      };
    }
  }, [currentUser, isEmailVerified, setIsEmailVerified, websocketService]);

  const handleResendVerification = async (e) => {
    e.preventDefault();
    try {
      console.log('Attempting to resend verification email');
      const response = await api.post('/auth/resend-verification-email');
      console.log('Resend verification email response:', response.data);
      setNotification({
        open: true,
        message: response.data.message,
        severity: 'success'
      });
    } catch (error) {
      console.error('Failed to resend verification email:', error);
      setNotification({
        open: true,
        message: 'Failed to resend verification email. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!currentUser || isEmailVerified) {
    console.log('EmailVerificationAlert: Not rendering (user verified or not logged in)');
    return null;
  }

  console.log('EmailVerificationAlert: Rendering alert');
  return (
    <>
      <Alert 
        severity="warning" 
        sx={{ 
          py: 0.5, 
          px: 2, 
          alignItems: 'center', 
          '& .MuiAlert-message': { 
            display: 'flex', 
            alignItems: 'center',
            fontSize: '0.875rem'
          } 
        }}
      >
        Your email is not verified. Please verify it before creating stories and characters.
        <Link
          component="button"
          variant="body2"
          onClick={handleResendVerification}
          sx={{
            color: 'inherit',
            textDecoration: 'underline',
            ml: 1,
            '&:hover': {
              textDecoration: 'none'
            }
          }}
        >
          Resend verification email
        </Link>
      </Alert>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EmailVerificationAlert;
