import React, { useState } from 'react';
import { Container, Typography, Box, Tabs, Tab, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserManagement from './UserManagement';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const AdminDashboard = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom component="h1" align="center">
          Admin Dashboard
        </Typography>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="admin dashboard tabs"
          centered
        >
          <Tab label="User Management" />
          {/* Add more tabs here as needed */}
        </StyledTabs>
        <Box mt={3}>
          {tabValue === 0 && <UserManagement />}
          {/* Add more tab panels here as needed */}
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default AdminDashboard;
