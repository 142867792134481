import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import api, { getAvatarUrl, getThumbnailUrl } from '../services/api';
import SharePopup from './SharePopup';
import StoryAudioControls from './player/StoryAudioControls';
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardMedia,
  Chip,
  Alert,
  Avatar,
  Grid,
  Link,
  Skeleton,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  margin: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
}));

const StoryViewer = () => {
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [thumbnailError, setThumbnailError] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(true);
  const [originalPrompt, setOriginalPrompt] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const fetchStory = useCallback(async () => {
    try {
      console.log('Fetching story with ID:', id);
      const response = await api.get(`/stories/${id}`);
      console.log('Received story data:', response.data);
      if (!response.data || !response.data.content) {
        throw new Error('Invalid story data received');
      }
      setStory(response.data);
      
      // Extract original prompt from full prompt
      const originalPromptMatch = response.data.prompt.match(/Story Prompt: (.+)$/m);
      setOriginalPrompt(originalPromptMatch ? originalPromptMatch[1] : 'Original prompt not found');
      
      setLoading(false);
    } catch (err) {
      console.error('Error fetching story:', err.response ? err.response.data : err.message);
      setError('Failed to fetch story. Please try again.');
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchStory();
  }, [fetchStory]);

  useEffect(() => {
    if (story && story.thumbnailUrl) {
      const img = new Image();
      img.src = getThumbnailUrl(story.thumbnailUrl);
      img.onload = () => setThumbnailLoading(false);
      img.onerror = () => {
        console.error('Error loading thumbnail:', img.src);
        setThumbnailError(true);
        setThumbnailLoading(false);
      };
    }
  }, [story]);

  const handleVisibilityChange = async (newVisibility) => {
    try {
      const response = await api.toggleStoryVisibility(id);
      setStory({ ...story, visibility: response.data.visibility });
    } catch (err) {
      setError('Failed to update story visibility. Please try again.');
      console.error('Error updating story visibility:', err.response ? err.response.data : err.message);
    }
  };

  const handleShareabilityChange = async (newShareability) => {
    try {
      const response = await api.toggleStoryShareability(id);
      setStory({ ...story, isShareable: response.data.isShareable, shareLink: response.data.shareLink });
    } catch (err) {
      setError('Failed to update story shareability. Please try again.');
      console.error('Error updating story shareability:', err.response ? err.response.data : err.message);
    }
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`/stories/${id}`);
      setDeleteDialogOpen(false);
      navigate('/library', { state: { message: 'Story deleted successfully' } });
    } catch (err) {
      setError('Failed to delete story. Please try again.');
      console.error('Error deleting story:', err);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (loading) return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    </Container>
  );

  if (error) return (
    <Container maxWidth="md">
      <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
    </Container>
  );

  if (!story) return (
    <Container maxWidth="md">
      <Typography variant="h6">Story not found.</Typography>
    </Container>
  );

  console.log('Rendering story:', story);

  if (!story.content) {
    console.error('Story content is missing');
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 2 }}>Story content is missing. Please try refreshing the page.</Alert>
      </Container>
    );
  }
  
  const thumbnailUrl = story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg';

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <StyledPaper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {story.title}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} width="100%">
          <Chip label={`Length: ${capitalizeFirstLetter(story.length)}`} color="primary" />
          <Chip label={`Genre: ${capitalizeFirstLetter(story.genre)}`} color="secondary" />
        </Box>
        <Box mb={3} width="100%">
          <Card sx={{ maxWidth: '100%' }}>
            {thumbnailLoading ? (
              <Skeleton variant="rectangular" width="100%" height={0} style={{ paddingTop: '100%' }} />
            ) : (
              <CardMedia
                component="img"
                sx={{
                  aspectRatio: '1 / 1',
                  objectFit: 'cover',
                }}
                image={thumbnailError ? '/placeholder-image.jpg' : thumbnailUrl}
                alt={story.title}
              />
            )}
          </Card>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} width="100%">
          <Typography variant="h6">Characters:</Typography>
          <Tooltip title={`Click to change visibility`} arrow>
            <Chip
              icon={story.visibility === 'public' ? <PublicIcon /> : <LockIcon />}
              label={capitalizeFirstLetter(story.visibility)}
              color={story.visibility === 'public' ? 'primary' : 'default'}
              variant="outlined"
              onClick={() => setShareDialogOpen(true)}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
        </Box>

        {/* Characters section */}
        {story.characters && story.characters.length > 0 && (
          <Box mb={3} width="100%">
            <Grid container spacing={2}>
              {story.characters.map((character) => (
                <Grid item key={character._id} xs={4} sm={3} md={2}>
                  <Link
                    component={RouterLink}
                    to={`/characters/${character._id}`}
                    underline="none"
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center',
                      '&:hover': {
                        '& .MuiAvatar-root': {
                          border: '2px solid #1976d2',
                        },
                        '& .MuiTypography-root': {
                          color: '#1976d2',
                        },
                      },
                    }}
                  >
                    <Avatar
                      alt={character.name}
                      src={getAvatarUrl(character.avatarUrl)}
                      sx={{ width: 48, height: 48, mb: 1 }}
                      imgProps={{
                        loading: "lazy",
                        onError: (e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder-avatar.jpg';
                        },
                      }}
                    />
                    <Typography variant="body2" align="center" sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                      {character.name}
                    </Typography>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Narration section */}
        {story.narrationUrl && (
          <StyledPaper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
            <Box 
              display="flex" 
              justifyContent="center"
              alignItems="center"
            >
              <StoryAudioControls story={story} />
            </Box>
          </StyledPaper>
        )}
        
        {/* Buttons section */}
        <Grid container spacing={2} sx={{ mt: 2, mb: 3 }}>
          <Grid item xs={12} sm={4}>
            <StyledButton
              component={RouterLink}
              to={`/edit/${story._id}`}
              variant="contained"
              color="primary"
              fullWidth
            >
              Edit Story
            </StyledButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledButton
              component={RouterLink}
              to={`/clone/${story._id}`}
              variant="outlined"
              color="secondary"
              fullWidth
            >
              Clone Story
            </StyledButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledButton
              onClick={handleDeleteClick}
              variant="outlined"
              color="error"
              fullWidth
              startIcon={<DeleteIcon />}
            >
              Delete Story
            </StyledButton>
          </Grid>
        </Grid>

        {/* Summary section */}
        <StyledPaper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
          <Typography variant="h6" gutterBottom>Summary:</Typography>
          <Typography variant="body1">{story.summary}</Typography>
        </StyledPaper>

        {/* Story content section */}
        <StyledPaper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
          <Typography variant="h6" gutterBottom>Story:</Typography>
          <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
            {story.content}
          </Typography>
        </StyledPaper>

        {/* Original Prompt section */}
        <StyledPaper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
          <Typography variant="h6" gutterBottom>Original Prompt:</Typography>
          <Typography variant="body1">{originalPrompt}</Typography>
        </StyledPaper>

        <Box mt={2} width="100%">
          <StyledButton
            component={RouterLink}
            to="/library"
            variant="outlined"
            color="primary"
            fullWidth
          >
            Back to Library
          </StyledButton>
        </Box>
      </StyledPaper>

      {story && (
        <SharePopup
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          story={story}
          onVisibilityChange={handleVisibilityChange}
          onShareabilityChange={handleShareabilityChange}
        />
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Story"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this story? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StoryViewer;

