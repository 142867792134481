class SubscriptionButtonController {
  constructor(currentPlan, currentBillingCycle) {
    this.currentPlan = currentPlan;
    this.currentBillingCycle = currentBillingCycle;
  }

  getButtonProps(plan, billingCycle) {
    if (this.currentPlan === 'Free') {
      return this.getFreeTierButtonProps(plan);
    } else if (this.currentPlan === 'Fun') {
      return this.getFunTierButtonProps(plan, billingCycle);
    } else if (this.currentPlan === 'Double the Fun') {
      return this.getDoubleFunTierButtonProps(plan, billingCycle);
    }
    // Default case (should not be reached)
    return { text: 'Subscribe', action: 'checkout', disabled: false };
  }

  getFreeTierButtonProps(plan) {
    if (plan.name === 'Free') {
      return { text: 'Current Plan', action: null, disabled: true };
    } else {
      return { text: 'Upgrade', action: 'checkout', disabled: false };
    }
  }

  getFunTierButtonProps(plan, billingCycle) {
    if (plan.name === 'Free') {
      return { text: 'Downgrade', action: 'manage', disabled: false };
    } else if (plan.name === 'Fun') {
      if (billingCycle === this.currentBillingCycle) {
        return { text: 'Current Plan', action: null, disabled: true };
      } else if (billingCycle === 'yearly' && this.currentBillingCycle === 'monthly') {
        return { text: 'Upgrade', action: 'manage', disabled: false };
      } else {
        return { text: 'Downgrade', action: 'manage', disabled: false };
      }
    } else if (plan.name === 'Double the Fun') {
      return { text: 'Upgrade', action: 'manage', disabled: false };
    }
  }

  getDoubleFunTierButtonProps(plan, billingCycle) {
    if (plan.name === 'Free' || plan.name === 'Fun') {
      return { text: 'Downgrade', action: 'manage', disabled: false };
    } else if (plan.name === 'Double the Fun') {
      if (billingCycle === this.currentBillingCycle) {
        return { text: 'Current Plan', action: null, disabled: true };
      } else if (billingCycle === 'yearly' && this.currentBillingCycle === 'monthly') {
        return { text: 'Upgrade', action: 'manage', disabled: false };
      } else if (billingCycle === 'monthly' && this.currentBillingCycle === 'yearly') {
        return { text: 'Downgrade', action: 'manage', disabled: false };
      }
    }
    // Default case (should not be reached)
    return { text: 'Subscribe', action: 'checkout', disabled: false };
  }
}

export default SubscriptionButtonController;
