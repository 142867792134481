import React, { useState, useMemo, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  useTheme,
  styled,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import {
  Close,
  PlayArrow,
  MoreVert,
  RemoveFromQueue,
  PlaylistAdd,
  Add as AddIcon,
  LibraryMusic,
  Delete,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useAudioPlayer } from '../../contexts/AudioPlayerContext';
import { getThumbnailUrl } from '../../services/api';

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .actions': {
    display: 'flex',
    gap: 4,
    marginLeft: 'auto',
    visibility: 'visible',
  },
  '& .MuiListItemText-root': {
    marginRight: '80px',
  },
  '& .MuiListItemText-primary': {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '& .MuiListItemText-secondary': {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  padding: '16px 16px 8px',
  letterSpacing: '0.1em',
  color: theme.palette.text.secondary,
}));

const PlaylistManager = ({ open, onClose, initialPlaylistId }) => {
  const theme = useTheme();
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(initialPlaylistId);
  const [isCreating, setIsCreating] = useState(false);
  const [playlistName, setPlaylistName] = useState('');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedPlaylistForMenu, setSelectedPlaylistForMenu] = useState(null);
  const [storyMenuAnchor, setStoryMenuAnchor] = useState(null);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);
  const { 
    playlists, 
    createPlaylist,
    deletePlaylist,
    reorderPlaylist,
    removeFromPlaylist,
    currentStory,
    isPlaying,
    handlePlayPlaylist,
    appendToQueue,
    addToQueue,
    loadStory,
    play,
    clearQueue,
  } = useAudioPlayer();

  // Update selected playlist when initialPlaylistId changes
  useEffect(() => {
    if (initialPlaylistId) {
      setSelectedPlaylistId(initialPlaylistId);
    }
  }, [initialPlaylistId]);

  // Reset selection when drawer closes
  useEffect(() => {
    if (!open) {
      setSelectedPlaylistId(null);
    }
  }, [open]);

  // Get selected playlist from global state
  const selectedPlaylist = useMemo(() => {
    return playlists.find(p => p._id === selectedPlaylistId);
  }, [playlists, selectedPlaylistId]);

  const handlePlaylistMenuOpen = (event, playlist) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    setSelectedPlaylistForMenu(playlist);
  };

  const handlePlaylistMenuClose = () => {
    setMenuAnchor(null);
    setSelectedPlaylistForMenu(null);
  };

  const handleStoryMenuOpen = (event, index) => {
    event.stopPropagation();
    event.preventDefault(); // Add this to prevent any default behavior
    setStoryMenuAnchor(event.currentTarget);
    setSelectedStoryIndex(index);
  };

  const handleStoryMenuClose = () => {
    setStoryMenuAnchor(null);
    setSelectedStoryIndex(null);
  };

  const handleCreatePlaylist = () => {
    if (playlistName.trim()) {
      createPlaylist(playlistName);
      setPlaylistName('');
      setIsCreating(false);
    }
  };

  const handlePlayClick = (event, playlist) => {
    event.stopPropagation(); // Prevent opening playlist view
    clearQueue();
    // Map stories to preserve isPublic flag
    // For system playlists of type 'publicFavorites', force isPublic to true
    const playlistWithFlags = {
      ...playlist,
      stories: playlist.stories.map(story => ({
        ...story,
        isPublic: playlist.isSystem && playlist.type === 'publicFavorites' ? true : story.isPublic
      }))
    };
    handlePlayPlaylist(playlistWithFlags);
  };

  const handlePlayStory = (event, index) => {
    event.stopPropagation();
    if (selectedPlaylist) {
      clearQueue();
      const story = selectedPlaylist.stories[index];
      // For system playlists of type 'publicFavorites', force isPublic to true
      const isPublic = selectedPlaylist.isSystem && selectedPlaylist.type === 'publicFavorites' ? true : story.isPublic;
      loadStory(story._id, isPublic);
      play();
    }
    handleStoryMenuClose();
  };

  const handleAddPlaylistToQueue = () => {
    if (selectedPlaylistForMenu?.stories) {
      // Preserve isPublic flag for each story
      // For system playlists of type 'publicFavorites', force isPublic to true
      const storiesWithFlags = selectedPlaylistForMenu.stories.map(story => ({
        ...story,
        isPublic: selectedPlaylistForMenu.isSystem && selectedPlaylistForMenu.type === 'publicFavorites' ? true : story.isPublic
      }));
      appendToQueue(storiesWithFlags);
      handlePlaylistMenuClose();
    }
  };

  const handleAddToQueue = () => {
    if (selectedPlaylist && selectedStoryIndex !== null) {
      const story = selectedPlaylist.stories[selectedStoryIndex];
      // For system playlists of type 'publicFavorites', force isPublic to true
      const isPublic = selectedPlaylist.isSystem && selectedPlaylist.type === 'publicFavorites' ? true : story.isPublic;
      addToQueue({ ...story, isPublic });
      handleStoryMenuClose();
    }
  };

  const handleRemoveFromPlaylist = async () => {
    if (selectedPlaylist && selectedStoryIndex !== null) {
      const story = selectedPlaylist.stories[selectedStoryIndex];
      await removeFromPlaylist(selectedPlaylist._id, story._id);
      handleStoryMenuClose();
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination || !selectedPlaylist) return;
    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    
    if (sourceIndex === destinationIndex) return;
    
    // Pass isSystem and type for system playlists
    reorderPlaylist(
      selectedPlaylist._id,
      sourceIndex,
      destinationIndex,
      selectedPlaylist.isSystem,
      selectedPlaylist.type
    );
  };

  const renderPlaylistContent = () => (
    <>
      <DrawerHeader>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => setSelectedPlaylistId(null)} edge="start">
            <Close />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 1 }}>
            {selectedPlaylist?.name}
          </Typography>
          {selectedPlaylist?.isSystem && (
            <Typography
              variant="caption"
              sx={{
                ml: 1,
                px: 1,
                py: 0.5,
                borderRadius: 1,
                bgcolor: 'primary.main',
                color: 'primary.contrastText'
              }}
            >
              System Playlist
            </Typography>
          )}
        </Box>
        <IconButton 
          onClick={(e) => handlePlayClick(e, selectedPlaylist)}
          color="primary"
        >
          <PlayArrow />
        </IconButton>
      </DrawerHeader>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="playlist">
          {(provided) => (
            <List 
              {...provided.droppableProps}
              ref={provided.innerRef}
              disablePadding
            >
              {selectedPlaylist?.stories.map((story, index) => (
                <Draggable 
                  key={`${story._id}-${index}`}
                  draggableId={`${story._id}-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <StyledListItem
                        sx={{
                          backgroundColor: snapshot.isDragging ? 'action.hover' : 'inherit',
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            variant="square"
                            src={getThumbnailUrl(story.thumbnailUrl)}
                            alt={story.title}
                            sx={{ width: 40, height: 40 }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={story.title}
                          secondary={`By: ${story.user?.name || 'Unknown'}`}
                          primaryTypographyProps={{
                            variant: 'body1',
                            sx: { 
                              fontWeight: 'normal',
                              color: currentStory?._id === story._id ? 'primary.main' : 'inherit'
                            }
                          }}
                          secondaryTypographyProps={{
                            variant: 'body2'
                          }}
                        />
                        <Box className="actions">
                          <IconButton
                            size="small"
                            onClick={(e) => handlePlayStory(e, index)}
                            color="primary"
                          >
                            <PlayArrow />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={(e) => handleStoryMenuOpen(e, index)}
                            sx={{ 
                              color: 'text.secondary',
                              cursor: 'pointer',
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </Box>
                      </StyledListItem>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );

  const renderPlaylistsList = () => (
    <>
      <DrawerHeader>
        <Typography variant="h6">Your Library</Typography>
        <IconButton onClick={() => setIsCreating(true)} color="primary">
          <AddIcon />
        </IconButton>
      </DrawerHeader>

      {/* System Playlists Section */}
      <SectionTitle>System Playlists</SectionTitle>
      <List>
        {playlists.filter(p => p.isSystem).map((playlist) => (
          <StyledListItem 
            key={playlist._id}
            onClick={() => setSelectedPlaylistId(playlist._id)}
            sx={{ cursor: 'pointer' }}
          >
            <ListItemIcon>
              <LibraryMusic />
            </ListItemIcon>
            <ListItemText
              primary={playlist.name}
              secondary={`${playlist.stories.length} stories`}
              primaryTypographyProps={{ noWrap: true }}
            />
            <Box className="actions">
              <IconButton
                size="small"
                onClick={(e) => handlePlayClick(e, playlist)}
                color="primary"
              >
                <PlayArrow />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => handlePlaylistMenuOpen(e, playlist)}
                sx={{ color: 'text.secondary' }}
              >
                <MoreVert />
              </IconButton>
            </Box>
          </StyledListItem>
        ))}
      </List>

      {/* User Playlists Section */}
      <SectionTitle>Your Playlists</SectionTitle>
      <List>
        {playlists.filter(p => !p.isSystem).map((playlist) => (
          <StyledListItem 
            key={playlist._id}
            onClick={() => setSelectedPlaylistId(playlist._id)}
            sx={{ cursor: 'pointer' }}
          >
            <ListItemIcon>
              <LibraryMusic />
            </ListItemIcon>
            <ListItemText
              primary={playlist.name}
              secondary={`${playlist.stories.length} stories`}
              primaryTypographyProps={{ noWrap: true }}
            />
            <Box className="actions">
              <IconButton
                size="small"
                onClick={(e) => handlePlayClick(e, playlist)}
                color="primary"
              >
                <PlayArrow />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => handlePlaylistMenuOpen(e, playlist)}
                sx={{ color: 'text.secondary' }}
              >
                <MoreVert />
              </IconButton>
            </Box>
          </StyledListItem>
        ))}
      </List>
    </>
  );

  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 400 }
        }}
      >
        {selectedPlaylist ? renderPlaylistContent() : renderPlaylistsList()}
      </Drawer>

      {/* Create Playlist Dialog */}
      <Dialog open={isCreating} onClose={() => setIsCreating(false)}>
        <DialogTitle>Create New Playlist</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Playlist Name"
            fullWidth
            variant="outlined"
            value={playlistName}
            onChange={(e) => setPlaylistName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCreating(false)}>Cancel</Button>
          <Button onClick={handleCreatePlaylist} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Playlist Menu */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handlePlaylistMenuClose}
      >
        <MenuItem onClick={handleAddPlaylistToQueue}>
          <ListItemIcon>
            <PlaylistAdd fontSize="small" />
          </ListItemIcon>
          Add to Queue
        </MenuItem>

        {selectedPlaylistForMenu && !selectedPlaylistForMenu.isSystem && (
          <MenuItem 
            onClick={() => {
              deletePlaylist(selectedPlaylistForMenu._id);
              handlePlaylistMenuClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <ListItemIcon>
              <Delete fontSize="small" sx={{ color: 'error.main' }} />
            </ListItemIcon>
            Delete Playlist
          </MenuItem>
        )}
      </Menu>

      {/* Story Menu */}
      <Menu
        anchorEl={storyMenuAnchor}
        open={Boolean(storyMenuAnchor)}
        onClose={handleStoryMenuClose}
      >
        <MenuItem onClick={(e) => handlePlayStory(e, selectedStoryIndex)}>
          <ListItemIcon>
            <PlayArrow fontSize="small" />
          </ListItemIcon>
          Play
        </MenuItem>
        <MenuItem onClick={handleAddToQueue}>
          <ListItemIcon>
            <PlaylistAdd fontSize="small" />
          </ListItemIcon>
          Add to Queue
        </MenuItem>
        {!selectedPlaylist?.isSystem && (
          <MenuItem onClick={handleRemoveFromPlaylist}>
            <ListItemIcon>
              <RemoveFromQueue fontSize="small" />
            </ListItemIcon>
            Remove from Playlist
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default PlaylistManager;
