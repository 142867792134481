import React, { useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Avatar,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import { getServerBaseUrl } from '../../services/api';
import {
  StyledTextField,
  StyledFormControl,
  StyledSelect,
  StyledButton
} from './styles';

const StoryForm = ({
  title,
  setTitle,
  prompt,
  setPrompt,
  length,
  setLength,
  genre,
  setGenre,
  characters,
  selectedCharacters,
  handleCharacterSelect,
  handleCancel,
  openCharacterCreator,
  loading,
  error,
  isCreateDisabled,
  onSubmit
}) => {
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) {
      errors.title = 'Title is required';
    }
    if (!prompt.trim()) {
      errors.prompt = 'Prompt is required';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(e);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
      {error && (
        <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{error}</pre>
        </Alert>
      )}
      
      <StyledTextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
        disabled={isCreateDisabled}
        error={!!validationErrors.title}
        helperText={validationErrors.title}
      />
      
      <StyledTextField
        fullWidth
        label="Story Prompt"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        margin="normal"
        required
        multiline
        rows={4}
        disabled={isCreateDisabled}
        error={!!validationErrors.prompt}
        helperText={validationErrors.prompt}
      />
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledFormControl fullWidth margin="normal">
            <InputLabel id="story-length-label">Story Length</InputLabel>
            <StyledSelect
              labelId="story-length-label"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              label="Story Length"
              disabled={isCreateDisabled}
            >
              <MenuItem value="short">Short (approx. 1000 words)</MenuItem>
              <MenuItem value="medium">Medium (approx. 2500 words)</MenuItem>
              <MenuItem value="long">Long (approx. 5000 words)</MenuItem>
            </StyledSelect>
          </StyledFormControl>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <StyledFormControl fullWidth margin="normal">
            <InputLabel id="genre-label">Genre</InputLabel>
            <StyledSelect
              labelId="genre-label"
              value={genre}
              onChange={(e) => setGenre(e.target.value)}
              label="Genre"
              disabled={isCreateDisabled}
            >
              <MenuItem value="fantasy">Fantasy</MenuItem>
              <MenuItem value="scifi">Science Fiction</MenuItem>
              <MenuItem value="mystery">Mystery</MenuItem>
              <MenuItem value="adventure">Adventure</MenuItem>
              <MenuItem value="spooky">Spooky</MenuItem>
              <MenuItem value="romance">Romance</MenuItem>
              <MenuItem value="historical fiction">Historical Fiction</MenuItem>
              <MenuItem value="funny">Funny</MenuItem>
              <MenuItem value="western">Western</MenuItem>
              <MenuItem value="fairytale">Fairytale</MenuItem>
              <MenuItem value="mythology">Mythology</MenuItem>
              <MenuItem value="superhero">Superhero</MenuItem>
              <MenuItem value="real life">Real Life</MenuItem>
            </StyledSelect>
          </StyledFormControl>
        </Grid>
      </Grid>

      <StyledFormControl fullWidth margin="normal">
        <InputLabel id="characters-label">Characters</InputLabel>
        <StyledSelect
          labelId="characters-label"
          multiple
          value={selectedCharacters.map(char => char._id)}
          onChange={handleCharacterSelect}
          input={<OutlinedInput label="Characters" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedCharacters.map((char) => (
                <Chip
                  key={char._id}
                  label={char.name}
                  avatar={<Avatar alt={char.name} src={`${getServerBaseUrl()}${char.avatarUrl}`} />}
                />
              ))}
            </Box>
          )}
          disabled={isCreateDisabled}
        >
          {characters.map((char) => (
            <MenuItem key={char._id} value={char._id}>
              {char.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>

      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={4}>
          <StyledButton
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            Cancel
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledButton
            fullWidth
            variant="outlined"
            color="primary"
            onClick={openCharacterCreator}
            disabled={isCreateDisabled}
          >
            Create New Character
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledButton
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={isCreateDisabled}
          >
            {loading ? <CircularProgress size={24} /> : 'Create Story'}
          </StyledButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoryForm;
