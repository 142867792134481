import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  QueueMusic as QueueMusicIcon,
  PlaylistAdd as PlaylistAddIcon,
} from '@mui/icons-material';
import { useAudioPlayer } from '../../contexts/AudioPlayerContext';

const StoryAudioControls = ({ story, isPublic = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { 
    playlists, 
    addToPlaylist, 
    addToQueue,
    handlePlayPlaylist,
    clearQueue,
  } = useAudioPlayer();

  // Filter out system playlists
  const userPlaylists = playlists.filter(p => !p.isSystem);

  const handlePlaylistMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePlaylistMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddToPlaylist = (playlistId) => {
    addToPlaylist(playlistId, story._id);
    handlePlaylistMenuClose();
    // Open playlist drawer with the selected playlist
    const audioPlayer = document.querySelector('#audio-player');
    if (audioPlayer) {
      const playlistManagerEvent = new CustomEvent('openPlaylistManager', {
        detail: { playlistId }
      });
      audioPlayer.dispatchEvent(playlistManagerEvent);
    }
  };

  const handleAddToQueue = () => {
    // Add isPublic flag to the story object
    const storyWithFlag = { ...story, isPublic };
    addToQueue(storyWithFlag);
    // Open queue drawer
    const audioPlayer = document.querySelector('#audio-player');
    if (audioPlayer) {
      const queueDrawerEvent = new CustomEvent('openQueueDrawer');
      audioPlayer.dispatchEvent(queueDrawerEvent);
    }
  };

  const handlePlayNow = () => {
    // Create a temporary playlist with just this story
    const storyWithFlag = { ...story, isPublic };
    const tempPlaylist = {
      stories: [storyWithFlag]
    };
    
    // Clear queue and play the story
    clearQueue();
    handlePlayPlaylist(tempPlaylist);
  };

  return (
    <Stack 
      direction={isMobile ? "column" : "row"} 
      spacing={isMobile ? 1 : 2} 
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        maxWidth: isMobile ? '100%' : 400,
        px: isMobile ? 2 : 0,
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handlePlaylistMenuOpen}
        startIcon={<PlaylistAddIcon />}
        fullWidth={isMobile}
        sx={{
          borderRadius: '20px',
          textTransform: 'none',
          minWidth: isMobile ? '100%' : '130px',
          height: '36px',
        }}
      >
        Add to Playlist
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handlePlayNow}
        startIcon={<PlayArrowIcon />}
        fullWidth={isMobile}
        sx={{
          borderRadius: '20px',
          textTransform: 'none',
          minWidth: isMobile ? '100%' : '130px',
          height: '36px',
          fontWeight: 500,
          boxShadow: theme.shadows[3],
          '&:hover': {
            boxShadow: theme.shadows[5],
          },
        }}
      >
        Play Story
      </Button>

      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleAddToQueue}
        startIcon={<QueueMusicIcon />}
        fullWidth={isMobile}
        sx={{
          borderRadius: '20px',
          textTransform: 'none',
          minWidth: isMobile ? '100%' : '130px',
          height: '36px',
        }}
      >
        Add to Queue
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handlePlaylistMenuClose}
      >
        {userPlaylists.length === 0 ? (
          <MenuItem disabled>No playlists available</MenuItem>
        ) : (
          userPlaylists.map((playlist) => (
            <MenuItem
              key={playlist._id}
              onClick={() => handleAddToPlaylist(playlist._id)}
            >
              {playlist.name}
            </MenuItem>
          ))
        )}
      </Menu>
    </Stack>
  );
};

export default StoryAudioControls;
