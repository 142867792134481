import React from 'react';
import { Typography, Grid, Card, CardMedia } from '@mui/material';
import { StyledPaper } from './styles';

const CharacterPreview = ({ character }) => {
  if (!character) return null;

  return (
    <StyledPaper elevation={3} sx={{ mt: 4, p: 4 }}>
      <Typography variant="h5" gutterBottom align="center">
        Character Created Successfully
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              height="300"
              image={character.avatarUrl}
              alt={character.name}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">{character.name}</Typography>
          <Typography>Age: {character.age}</Typography>
          <Typography>Appearance: {character.appearance}</Typography>
          <Typography>Personality: {character.personality}</Typography>
          <Typography>Background: {character.background}</Typography>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default CharacterPreview;
