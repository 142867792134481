import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStory: null,
  queue: [],
  playlists: [],
  systemPlaylists: {
    publicFavorites: null
  },
  playback: {
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    volume: 1,
    isMuted: false,
    shuffle: false,
    repeat: false,
    autoplay: true // Add autoplay setting for queue continuation
  }
};

const audioPlayerSlice = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    setCurrentStory: (state, action) => {
      state.currentStory = action.payload;
    },
    setQueue: (state, action) => {
      state.queue = action.payload;
    },
    addToQueue: (state, action) => {
      state.queue.push(action.payload);
    },
    removeFromQueue: (state, action) => {
      state.queue = state.queue.filter(story => story._id !== action.payload);
    },
    setPlaylists: (state, action) => {
      state.playlists = action.payload;
    },
    addPlaylist: (state, action) => {
      state.playlists.push(action.payload);
    },
    updatePlaylist: (state, action) => {
      const index = state.playlists.findIndex(p => p._id === action.payload._id);
      if (index !== -1) {
        state.playlists[index] = action.payload;
      }
    },
    removePlaylist: (state, action) => {
      state.playlists = state.playlists.filter(p => p._id !== action.payload);
    },
    setPlaybackState: (state, action) => {
      state.playback = { ...state.playback, ...action.payload };
    },
    setIsPlaying: (state, action) => {
      state.playback.isPlaying = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.playback.currentTime = action.payload;
    },
    setDuration: (state, action) => {
      state.playback.duration = action.payload;
    },
    setVolume: (state, action) => {
      state.playback.volume = action.payload;
    },
    setMuted: (state, action) => {
      state.playback.isMuted = action.payload;
    },
    setShuffle: (state, action) => {
      state.playback.shuffle = action.payload;
    },
    setRepeat: (state, action) => {
      state.playback.repeat = action.payload;
    },
    setAutoplay: (state, action) => {
      state.playback.autoplay = action.payload;
    },
    // System playlist actions
    setSystemPlaylist: (state, action) => {
      const { type, playlist } = action.payload;
      state.systemPlaylists[type] = playlist;
    },
    updateSystemPlaylist: (state, action) => {
      const { type, story, actionType } = action.payload;
      const playlist = state.systemPlaylists[type];
      
      if (!playlist) return;

      switch (actionType) {
        case 'add':
          if (!playlist.stories.find(s => s._id === story._id)) {
            playlist.stories.push(story);
          }
          break;
        case 'remove':
          playlist.stories = playlist.stories.filter(s => s._id !== story._id);
          break;
      }
    },
    // Handle story visibility changes
    handleStoryVisibilityChange: (state, action) => {
      const { storyId, visibility } = action.payload;
      
      if (visibility === 'private') {
        // Remove from queue
        state.queue = state.queue.filter(story => story._id !== storyId);
        
        // Remove from public favorites
        if (state.systemPlaylists.publicFavorites) {
          state.systemPlaylists.publicFavorites.stories = 
            state.systemPlaylists.publicFavorites.stories.filter(
              story => story._id !== storyId
            );
        }
        
        // Remove from user playlists
        state.playlists.forEach(playlist => {
          playlist.stories = playlist.stories.filter(
            story => story._id !== storyId
          );
        });
      }
    }
  }
});

export const {
  setCurrentStory,
  setQueue,
  addToQueue,
  removeFromQueue,
  setPlaylists,
  addPlaylist,
  updatePlaylist,
  removePlaylist,
  setPlaybackState,
  setIsPlaying,
  setCurrentTime,
  setDuration,
  setVolume,
  setMuted,
  setShuffle,
  setRepeat,
  setAutoplay,
  setSystemPlaylist,
  updateSystemPlaylist,
  handleStoryVisibilityChange
} = audioPlayerSlice.actions;

export default audioPlayerSlice.reducer;
