import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Box,
  Alert,
  Avatar,
  CircularProgress,
  CardActionArea
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { useAuth } from '../contexts/AuthContext';
import api, { SERVER_BASE_URL } from '../services/api';

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '100%', // 1:1 aspect ratio
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  featuredStory: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    },
  },
  featureIcon: {
    fontSize: '4rem',
    marginBottom: theme.spacing(2),
  },
  moderateWidth: {
    maxWidth: '1000px',
    margin: '0 auto',
  },
  storyTitle: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '3em', // Adjust this value as needed
  },
  storySummary: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
  },
  charactersSection: {
    marginTop: theme.spacing(2),
  },
  featuredStoriesHeading: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  whyChooseSection: {
    marginTop: theme.spacing(8),
  },
  featuredStoriesGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(-2), // Negative margin to counteract padding
  },
  featuredStoryItem: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  freeOffer: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
}));

const FrontPage = () => {
  const classes = useStyles();
  const [featuredStories, setFeaturedStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.message) {
      setMessage(location.state.message);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  useEffect(() => {
    const fetchFeaturedStories = async () => {
      try {
        setLoading(true);
        const response = await api.getFeaturedStories();
        setFeaturedStories(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching featured stories:', error);
        setError('Failed to load featured stories. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedStories();
  }, []);

  const getFullUrl = (path) => {
    const fullUrl = `${SERVER_BASE_URL}${path.startsWith('/') ? '' : '/'}${path}`;
    console.log('Constructed URL:', fullUrl);
    return fullUrl;
  };

  const handleStoryClick = (story) => {
    if (story.shareLink) {
      const shareUrl = `/shared/${story.shareLink}`;
      navigate(shareUrl);
    } else {
      console.error('Share link not available for this story');
      // Optionally, you can show an error message to the user here
    }
  };

  const features = [
    {
      title: 'AI-Powered Creativity',
      description: 'Unleash your imagination with our AI assistant, helping you craft unique and engaging stories.',
      icon: <CreateIcon className={classes.featureIcon} />
    },
    {
      title: 'Vibrant Characters',
      description: 'Bring your stories to life with a diverse cast of characters, each with their own personality and backstory.',
      icon: <EmojiPeopleIcon className={classes.featureIcon} />
    },
    {
      title: 'Continuity in Stories',
      description: 'Experience a revolutionary storytelling universe where tales intertwine, creating an unparalleled, immersive journey that keeps your children spellbound across multiple adventures.',
      icon: <AutoStoriesIcon className={classes.featureIcon} />
    },
    {
      title: 'Audio Narration',
      description: 'Transform your written tales into captivating audio stories with our text-to-speech technology.',
      icon: <RecordVoiceOverIcon className={classes.featureIcon} />
    },
  ];

  return (
    <React.Fragment>
      <main>
        {message && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
        <div className={classes.hero}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              StoriesNow: Where Imagination Comes to Life
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Create magical stories for your little ones, powered by your creativity and a sprinkle of AI magic
            </Typography>
            <Typography variant="h6" align="center" className={classes.freeOffer}>
              Start your journey today with 5 FREE stories every month!
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                {currentUser ? (
                  <Grid item>
                    <StyledButton variant="contained" color="primary" component={RouterLink} to="/create">
                      Create Your Story
                    </StyledButton>
                  </Grid>
                ) : (
                  <Grid item>
                    <StyledButton variant="contained" color="primary" component={RouterLink} to="/login">
                    Create Your Free Story Now
                    </StyledButton>
                  </Grid>
                )}
              </Grid>
            </div>
          </Container>
        </div>
        
        <Container className={classes.moderateWidth}>
          <Typography variant="h4" align="center" color="textPrimary" gutterBottom className={classes.featuredStoriesHeading}>
            Featured Stories
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
          ) : featuredStories.length === 0 ? (
            <Typography variant="body1" align="center">No featured stories available at the moment.</Typography>
          ) : (
            <div className={classes.featuredStoriesGrid}>
              {featuredStories.map((story) => (
                <div className={classes.featuredStoryItem} key={story._id}>
                  <Card className={classes.featuredStory}>
                    <CardActionArea onClick={() => handleStoryClick(story)}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={getFullUrl(story.thumbnailUrl)}
                        title={story.title}
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.storyTitle}>
                          {story.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" className={classes.storySummary}>
                          {story.summary}
                        </Typography>
                        <Box className={classes.charactersSection}>
                          {story.characters.map((character) => (
                            <Avatar
                              key={character._id}
                              alt={character.name}
                              src={getFullUrl(character.avatarUrl)}
                              sx={{ width: 32, height: 32, display: 'inline-block', marginRight: 1 }}
                            />
                          ))}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                    {story.narrationUrl && (
                      <Box mt={2} px={2} pb={2}>
                        <audio controls src={getFullUrl(story.narrationUrl)} style={{ width: '100%' }}>
                          Your browser does not support the audio element.
                        </audio>
                      </Box>
                    )}
                  </Card>
                </div>
              ))}
            </div>
          )}
        </Container>

        <Container className={`${classes.moderateWidth} ${classes.whyChooseSection}`}>
          <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
            Why Choose StoriesNow?
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature) => (
              <Grid item key={feature.title} xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Box display="flex" justifyContent="center" mb={2}>
                      {feature.icon}
                    </Box>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                      {feature.title}
                    </Typography>
                    <Typography align="center" variant="body1">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container className={`${classes.cardGrid} ${classes.moderateWidth}`}>
          <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
            {currentUser ? 'Ready to Create More Stories?' : 'Ready to Start Your Free StoriesNow Journey?'}
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            {currentUser
              ? 'Continue crafting unforgettable tales and precious memories'
              : 'Join thousands of storytellers creating magical stories for their little ones - start with 5 free stories every month!'}
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                {currentUser ? (
                  <StyledButton variant="contained" color="primary" component={RouterLink} to="/create">
                    Create Your Story
                  </StyledButton>
                ) : (
                  <StyledButton variant="contained" color="primary" component={RouterLink} to="/login">
                    Start Your StoriesNow Journey
                  </StyledButton>
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
      </main>
    </React.Fragment>
  );
};

export default FrontPage;
