import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  useTheme,
  styled,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import {
  Close,
  PlayArrow,
  Pause,
  MoreVert,
  RemoveFromQueue,
  PlaylistAdd,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useAudioPlayer } from '../../contexts/AudioPlayerContext';
import { getThumbnailUrl } from '../../services/api';

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiListItemSecondaryAction-root': {
      visibility: 'visible',
    },
  },
  '& .MuiListItemSecondaryAction-root': {
    visibility: 'hidden',
    right: 8,
    display: 'flex',
    gap: 4,
  },
  '& .MuiListItemText-root': {
    marginRight: '80px',
  },
  '& .MuiListItemText-primary': {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '& .MuiListItemText-secondary': {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  padding: '16px 16px 8px',
  letterSpacing: '0.1em',
  color: theme.palette.text.secondary,
}));

const QueueDrawer = ({ open, onClose }) => {
  const theme = useTheme();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { 
    currentStory,
    queue,
    removeFromQueueByIndex,
    removeItemsUpToIndex,
    loadStory,
    play,
    isPlaying,
    togglePlay,
    reorderQueue
  } = useAudioPlayer();

  const handlePlay = async (story, index) => {
    // For stories from system playlists (like Public Favorites), force isPublic to true
    const isPublic = story.fromSystemPlaylist || story.isPublic;
    await loadStory(story._id, isPublic);
    play();
    removeItemsUpToIndex(index);
  };

  const handleRemove = (index) => {
    removeFromQueueByIndex(index);
    handleMenuClose();
  };

  const handleMenuOpen = (event, index) => {
    setMenuAnchor(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedIndex(null);
  };

  const handleAddToPlaylist = () => {
    // TODO: Implement add to playlist
    handleMenuClose();
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    
    if (sourceIndex === destinationIndex) return;
    
    reorderQueue(sourceIndex, destinationIndex);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 400 }
      }}
    >
      <DrawerHeader>
        <Typography variant="h6">Queue</Typography>
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DrawerHeader>

      <Box sx={{ overflow: 'auto' }}>
        {currentStory && (
          <>
            <SectionTitle>Now playing</SectionTitle>
            <List disablePadding>
              <StyledListItem>
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={getThumbnailUrl(currentStory.thumbnailUrl)}
                    alt={currentStory.title}
                    sx={{ width: 40, height: 40 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={currentStory.title}
                  secondary={`By: ${currentStory.user?.name || 'Unknown'}`}
                  primaryTypographyProps={{
                    variant: 'body1',
                    sx: { fontWeight: 'normal' }
                  }}
                  secondaryTypographyProps={{
                    variant: 'body2'
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton 
                    size="small"
                    onClick={togglePlay}
                    color="primary"
                  >
                    {isPlaying ? <Pause /> : <PlayArrow />}
                  </IconButton>
                </ListItemSecondaryAction>
              </StyledListItem>
            </List>
          </>
        )}

        {queue.length > 0 && (
          <>
            <SectionTitle>Next up</SectionTitle>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="queue">
                {(provided) => (
                  <List 
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    disablePadding
                  >
                    {queue.map((story, index) => (
                      <Draggable 
                        key={`${story._id}-${index}`}
                        draggableId={`${story._id}-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <StyledListItem
                              sx={{
                                backgroundColor: snapshot.isDragging ? 'action.hover' : 'inherit',
                              }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  variant="square"
                                  src={getThumbnailUrl(story.thumbnailUrl)}
                                  alt={story.title}
                                  sx={{ width: 40, height: 40 }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={story.title}
                                secondary={`By: ${story.user?.name || 'Unknown'}`}
                                primaryTypographyProps={{
                                  variant: 'body1',
                                  sx: { fontWeight: 'normal' }
                                }}
                                secondaryTypographyProps={{
                                  variant: 'body2'
                                }}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  size="small"
                                  onClick={() => handlePlay(story, index)}
                                  color="primary"
                                >
                                  <PlayArrow />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  {...provided.dragHandleProps}
                                  onClick={(e) => handleMenuOpen(e, index)}
                                  sx={{ 
                                    color: 'text.secondary',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <MoreVert />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </StyledListItem>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}

        {!queue.length && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={4}
            sx={{ color: 'text.secondary' }}
          >
            <Typography variant="body1">
              Your queue is empty
            </Typography>
            <Typography variant="body2">
              Add stories to your queue to start listening
            </Typography>
          </Box>
        )}

        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleRemove(selectedIndex)}>
            <ListItemIcon>
              <RemoveFromQueue fontSize="small" />
            </ListItemIcon>
            Remove from Queue
          </MenuItem>
          <MenuItem onClick={handleAddToPlaylist}>
            <ListItemIcon>
              <PlaylistAdd fontSize="small" />
            </ListItemIcon>
            Add to Playlist
          </MenuItem>
        </Menu>
      </Box>
    </Drawer>
  );
};

export default QueueDrawer;
