import React, { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Link,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CreateIcon from '@mui/icons-material/Create';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExploreIcon from '@mui/icons-material/Explore';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ColorModeContext } from '../App';

const Header = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { state: { message: 'Logged out successfully' } });
    } catch (error) {
      console.error('Logout error:', error);
      // You might want to show an error message to the user here
    }
  };

  const publicMenuItems = [
    { text: 'Home', icon: <HomeIcon />, link: '/' },
    { text: 'Pricing', icon: <AttachMoneyIcon />, link: '/pricing' },
    { text: 'Login', icon: <LoginIcon />, link: '/login' },
  ];

  const authenticatedMenuItems = [
    { text: 'Home', icon: <HomeIcon />, link: '/' },
    { text: 'Discover', icon: <ExploreIcon />, link: '/discover' },
    { text: 'Library', icon: <LibraryBooksIcon />, link: '/library' },
    { text: 'Characters', icon: <PeopleIcon />, link: '/characters' },
    { text: 'Create Story', icon: <CreateIcon />, link: '/create' },
    { text: 'Profile', icon: <AccountCircleIcon />, link: '/profile' },
    ...(currentUser && currentUser.role === 'admin' ? [{ text: 'Admin Dashboard', icon: <AdminPanelSettingsIcon />, link: '/admin' }] : []),
    { text: 'Logout', icon: <ExitToAppIcon />, onClick: handleLogout },
  ];

  const menuItems = currentUser ? authenticatedMenuItems : publicMenuItems;

  const renderMenuItems = () => (
    <>
      {menuItems.map((item) => (
        <Button
          key={item.text}
          color="inherit"
          component={item.link ? RouterLink : 'button'}
          to={item.link}
          onClick={item.onClick}
          startIcon={item.icon}
          sx={{ ml: 2 }}
        >
          {item.text}
        </Button>
      ))}
    </>
  );

  const renderMobileDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
      }}
    >
      <List sx={{ width: 250 }}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={item.link ? RouterLink : 'div'}
            to={item.link}
            onClick={() => {
              setDrawerOpen(false);
              if (item.onClick) item.onClick();
            }}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ListItemIcon sx={{ color: theme.palette.primary.main }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link component={RouterLink} to="/" color="inherit" underline="none">
            <span className="logo-font">StoriesNow</span>
          </Link>
        </Typography>
        <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setDrawerOpen(true)}
              sx={{ ml: 2 }}
            >
              <MenuIcon />
            </IconButton>
            {renderMobileDrawer()}
          </>
        ) : (
          <Box>{renderMenuItems()}</Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
