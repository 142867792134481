import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import api, { getAvatarUrl, getThumbnailUrl, getNarrationUrl } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { updateSystemPlaylist } from '../store/slices/audioPlayerSlice';
import StoryAudioControls from './player/StoryAudioControls';
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardMedia,
  Chip,
  Alert,
  Avatar,
  Grid,
  Skeleton,
  useTheme,
  useMediaQuery,
  IconButton,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  margin: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
}));

const PublicStoryViewer = () => {
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [thumbnailError, setThumbnailError] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(true);
  const { id, shareLink } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  const handleFavoriteToggle = async () => {
    try {
      if (story.isFavorited) {
        await api.removeFromFavorites(story._id);
        dispatch(updateSystemPlaylist({
          type: 'publicFavorites',
          story,
          actionType: 'remove'
        }));
      } else {
        await api.addToFavorites(story._id);
        dispatch(updateSystemPlaylist({
          type: 'publicFavorites',
          story,
          actionType: 'add'
        }));
      }
      setStory(prev => ({
        ...prev,
        isFavorited: !prev.isFavorited,
        favoriteCount: prev.favoriteCount + (prev.isFavorited ? -1 : 1)
      }));
    } catch (err) {
      setError('Failed to update favorites. Please try again.');
      console.error('Error updating favorites:', err);
    }
  };

  const fetchStory = useCallback(async () => {
    try {
      let response;
      if (shareLink) {
        console.log('Fetching shared story with link:', shareLink);
        response = await api.getSharedStory(shareLink);
      } else {
        console.log('Fetching public story with ID:', id);
        // Use api.getStory with isPublic=true instead of api.getPublicStory
        response = await api.getStory(id, true);
      }
      console.log('Received story data:', response.data);
      if (!response.data || !response.data.content) {
        throw new Error('Invalid story data received');
      }
      // Add isPublic flag to story data
      setStory({ ...response.data, isPublic: true });
      setLoading(false);
    } catch (err) {
      console.error('Error fetching story:', err.response ? err.response.data : err.message);
      setError('Failed to fetch story. Please try again.');
      setLoading(false);
    }
  }, [id, shareLink]);

  useEffect(() => {
    fetchStory();
  }, [fetchStory]);

  useEffect(() => {
    if (story && story.thumbnailUrl) {
      const img = new Image();
      img.src = story.absoluteThumbnailUrl || getThumbnailUrl(story.thumbnailUrl);
      img.onload = () => setThumbnailLoading(false);
      img.onerror = () => {
        console.error('Error loading thumbnail:', img.src);
        setThumbnailError(true);
        setThumbnailLoading(false);
      };
    }
  }, [story]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (loading) return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    </Container>
  );

  if (error) return (
    <Container maxWidth="md">
      <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
    </Container>
  );

  if (!story) return (
    <Container maxWidth="md">
      <Typography variant="h6">Story not found.</Typography>
    </Container>
  );

  console.log('Rendering story:', story);

  if (!story.content) {
    console.error('Story content is missing');
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 2 }}>Story content is missing. Please try refreshing the page.</Alert>
      </Container>
    );
  }

  const thumbnailUrl = story.absoluteThumbnailUrl || (story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg');

  return (
    <>
      <Helmet>
        <title>{`${story.title} | StoryCraft`}</title>
        <meta name="description" content={story.summary} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`${story.title} | StoryCraft`} />
        <meta property="og:description" content={story.summary} />
        <meta property="og:image" content={thumbnailUrl} />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={`${story.title} | StoryCraft`} />
        <meta name="twitter:description" content={story.summary} />
        <meta name="twitter:image" content={thumbnailUrl} />

        {/* WhatsApp */}
        <meta property="og:site_name" content="StoryCraft" />
        <meta property="og:image:alt" content={`Thumbnail for ${story.title}`} />

        {/* Additional meta tags for better compatibility */}
        <meta name="author" content={story.user ? story.user.name : 'StoryCraft User'} />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/logo192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <StyledPaper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box display="flex" alignItems="center" width="100%" mb={2}>
            <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
              {story.title}
            </Typography>
            {currentUser && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <IconButton
                  onClick={handleFavoriteToggle}
                  color="primary"
                  size="small"
                >
                  {story.isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
                <Typography variant="caption" color="text.secondary">
                  {story.favoriteCount || 0}
                </Typography>
              </Stack>
            )}
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} width="100%">
            <Chip label={`Length: ${capitalizeFirstLetter(story.length)}`} color="primary" />
            <Chip label={`Genre: ${capitalizeFirstLetter(story.genre)}`} color="secondary" />
          </Box>
          <Box mb={3} width="100%">
            <Card sx={{ maxWidth: '100%' }}>
              {thumbnailLoading ? (
                <Skeleton variant="rectangular" width="100%" height={0} style={{ paddingTop: '100%' }} />
              ) : (
                <CardMedia
                  component="img"
                  sx={{
                    aspectRatio: '1 / 1',
                    objectFit: 'cover',
                  }}
                  image={thumbnailError ? '/placeholder-image.jpg' : thumbnailUrl}
                  alt={story.title}
                />
              )}
            </Card>
          </Box>

          {/* Characters section */}
          {story.characters && story.characters.length > 0 && (
            <Box mb={3} width="100%">
              <Typography variant="h6" gutterBottom>Characters:</Typography>
              <Grid container spacing={2}>
                {story.characters.map((character) => (
                  <Grid item key={character._id} xs={4} sm={3} md={2}>
                    <Box
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        alt={character.name}
                        src={getAvatarUrl(character.avatarUrl)}
                        sx={{ width: 48, height: 48, mb: 1 }}
                        imgProps={{
                          loading: "lazy",
                          onError: (e) => {
                            e.target.onerror = null;
                            e.target.src = '/placeholder-avatar.jpg';
                          },
                        }}
                      />
                      <Typography variant="body2" align="center" sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                        {character.name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {/* Narration section */}
          {story.narrationUrl && (
            <StyledPaper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
              <Box 
                display="flex" 
                justifyContent="center"
                alignItems="center"
              >
                {currentUser ? (
                  <StoryAudioControls story={story} isPublic={true} />
                ) : (
                  <audio controls style={{ width: '100%' }}>
                    <source src={getNarrationUrl(story.narrationUrl)} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                )}
              </Box>
            </StyledPaper>
          )}

          {/* Summary section */}
          <StyledPaper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
            <Typography variant="h6" gutterBottom>Summary:</Typography>
            <Typography variant="body1">{story.summary}</Typography>
          </StyledPaper>

          {/* Story content section */}
          <StyledPaper elevation={3} sx={{ p: 3, mb: 3, width: '100%' }}>
            <Typography variant="h6" gutterBottom>Story:</Typography>
            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
              {story.content}
            </Typography>
          </StyledPaper>

          <Box mt={2} width="100%">
            {currentUser ? (
              <StyledButton
                component={RouterLink}
                to="/discover"
                variant="outlined"
                color="primary"
                fullWidth
              >
                Back to Discover
              </StyledButton>
            ) : (
              <StyledButton
                component={RouterLink}
                to="/login"
                variant="contained"
                color="primary"
                fullWidth
              >
                Log in for More Features
              </StyledButton>
            )}
          </Box>
        </StyledPaper>
      </Container>
    </>
  );
};

export default PublicStoryViewer;
