import React, { useState } from 'react';
import { Typography, Box, Grid, Container, Paper, Card, CardContent, ToggleButtonGroup, ToggleButton, Badge, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Person, EmojiEvents as Trophy, Star, CurrencyPound } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, #1e3c72 0%, #1e3c72 1%, #2a5298 100%)'
    : 'linear-gradient(135deg, #2980b9 0%, #2980b9 1%, #6dd5fa 100%)',
  color: theme.palette.common.white,
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
}));

const StyledButton = styled(RouterLink)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textDecoration: 'none',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  backgroundColor: 'transparent',
  color: 'white',
  border: '2px solid white',
  cursor: 'pointer',
  fontSize: '1rem',
  marginTop: theme.spacing(4),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '25px',
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: '25px',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '25px',
    },
    '&:first-of-type': {
      borderRadius: '25px',
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: '#2980b9',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3498db',
    },
  },
}));

const PlanCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.2)',
  },
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
  borderRadius: '15px',
}));

const PlanCardContent = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const PlanName = styled(Typography)(({ theme }) => ({
  height: '2.5em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const PlanDetails = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -14,
    top: -5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const PricingPage = () => {
  const theme = useTheme();
  const [billingCycle, setBillingCycle] = useState('monthly');

  const plans = [
    {
      name: 'Free',
      icon: 'Person',
      price: { monthly: 0, yearly: 0 },
      storyLimit: 5,
    },
    {
      name: 'Fun',
      icon: 'EmojiEvents',
      price: { monthly: 9.99, yearly: 99.99 },
      storyLimit: 15,
      popular: true,
    },
    {
      name: 'Double the Fun',
      icon: 'Star',
      price: { monthly: 19.99, yearly: 199.99 },
      storyLimit: 30,
    },
  ];

  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3} sx={{ mt: 4, p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CurrencyPound sx={{ fontSize: 60, mb: 2 }} />
        <Typography 
          component="h1" 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold', 
            color: theme.palette.common.white,
            mb: 1,
            textAlign: 'center',
          }}
        >
          Pricing Plans
        </Typography>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            textAlign: 'center', 
            mb: 4, 
            color: theme.palette.common.white
          }}
        >
          Choose the plan that fits your storytelling needs
        </Typography>
        
        <Box display="flex" justifyContent="center" mb={3}>
          <StyledToggleButtonGroup
            value={billingCycle}
            exclusive
            onChange={(event, newBillingCycle) => newBillingCycle && setBillingCycle(newBillingCycle)}
            aria-label="billing cycle"
          >
            <StyledToggleButton value="monthly" aria-label="monthly">
              Monthly
            </StyledToggleButton>
            <StyledToggleButton value="yearly" aria-label="yearly">
              <StyledBadge badgeContent="-20%" color="error">
                Yearly
              </StyledBadge>
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box>

        <Grid container spacing={3} sx={{ mt: 2 }}>
          {plans.map((plan) => (
            <Grid item xs={12} sm={4} key={plan.name}>
              <PlanCard>
                <PlanCardContent>
                  <PlanName variant="h6" component="div">
                    {plan.icon === 'Person' ? <Person sx={{ mr: 1 }} /> : plan.icon === 'EmojiEvents' ? <Trophy sx={{ mr: 1 }} /> : <Star sx={{ mr: 1 }} />}
                    {plan.name}
                    {plan.popular && (
                      <Typography variant="caption" sx={{ ml: 1, color: theme.palette.secondary.main }}>
                        (Most popular)
                      </Typography>
                    )}
                  </PlanName>
                  <PlanDetails>
                    <Typography variant="h4" component="div" textAlign="center" gutterBottom>
                      £{billingCycle === 'monthly' ? plan.price.monthly : plan.price.yearly}
                      <Typography variant="caption" display="block">
                        per {billingCycle === 'monthly' ? 'month' : 'year'}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" textAlign="center">
                      Create up to {plan.storyLimit} stories per month
                    </Typography>
                  </PlanDetails>
                </PlanCardContent>
              </PlanCard>
            </Grid>
          ))}
        </Grid>

        <Typography variant="subtitle1" sx={{ mt: 4, color: theme.palette.common.white, textAlign: 'center' }}>
          Save 20% with our yearly subscriptions!
        </Typography>

        <StyledButton to="/login">
          Create Your Free Story Now
        </StyledButton>
      </StyledPaper>
    </Container>
  );
};

export default PricingPage;
