import React from 'react';
import { Box, CircularProgress, Alert } from '@mui/material';
import { StyledTextField, StyledButton } from './styles';

const CharacterForm = ({
  name,
  setName,
  age,
  setAge,
  appearance,
  setAppearance,
  personality,
  setPersonality,
  background,
  setBackground,
  loading,
  error,
  isEmailVerified,
  onSubmit
}) => {
  return (
    <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
      {error && (
        <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
          {error}
        </Alert>
      )}

      <StyledTextField
        fullWidth
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        margin="normal"
        required
        disabled={!isEmailVerified}
      />

      <StyledTextField
        fullWidth
        label="Age"
        value={age}
        onChange={(e) => setAge(e.target.value)}
        margin="normal"
        required
        disabled={!isEmailVerified}
      />

      <StyledTextField
        fullWidth
        label="Appearance"
        value={appearance}
        onChange={(e) => setAppearance(e.target.value)}
        margin="normal"
        multiline
        rows={3}
        disabled={!isEmailVerified}
      />

      <StyledTextField
        fullWidth
        label="Personality"
        value={personality}
        onChange={(e) => setPersonality(e.target.value)}
        margin="normal"
        multiline
        rows={3}
        disabled={!isEmailVerified}
      />

      <StyledTextField
        fullWidth
        label="Background"
        value={background}
        onChange={(e) => setBackground(e.target.value)}
        margin="normal"
        multiline
        rows={3}
        disabled={!isEmailVerified}
      />

      <Box mt={3}>
        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !isEmailVerified}
          fullWidth
        >
          {loading ? <CircularProgress size={24} /> : 'Create Character'}
        </StyledButton>
      </Box>
    </Box>
  );
};

export default CharacterForm;
