import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api, { getServerBaseUrl } from '../services/api';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
  Box,
  Alert,
  Skeleton,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CardActionArea,
  useTheme,
  useMediaQuery,
  Fab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CharacterCreator from './character-creator/CharacterCreator';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
}));

const CharacterLibrary = () => {
  const [characters, setCharacters] = useState([]);
  const [filteredCharacters, setFilteredCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [characterToDelete, setCharacterToDelete] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchCharacters();
  }, []);

  useEffect(() => {
    const filtered = characters.filter(character =>
      character.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCharacters(filtered);
  }, [searchTerm, characters]);

  const fetchCharacters = async () => {
    try {
      const response = await api.getCharacters();
      setCharacters(response.data);
      setFilteredCharacters(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch characters. Please try again.');
      setLoading(false);
      console.error('Error fetching characters:', err);
    }
  };

  const handleDeleteClick = (character, event) => {
    event.stopPropagation();
    setCharacterToDelete(character);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.deleteCharacter(characterToDelete._id);
      setCharacters(characters.filter(char => char._id !== characterToDelete._id));
      setFilteredCharacters(filteredCharacters.filter(char => char._id !== characterToDelete._id));
      setDeleteDialogOpen(false);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to delete character. Please try again.');
      console.error('Error deleting character:', err);
    }
  };

  const handleCreateCharacter = (newCharacter) => {
    setCharacters([...characters, newCharacter]);
    setFilteredCharacters([...filteredCharacters, newCharacter]);
    setCreateDialogOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Characters
          </Typography>
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="circular" width={150} height={150} style={{ margin: '0 auto' }} />
                <Skeleton variant="text" width="80%" style={{ margin: '10px auto' }} />
                <Skeleton variant="text" width="60%" style={{ margin: '0 auto' }} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Your Characters
        </Typography>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            label="Search Characters"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
        </Box>
        {filteredCharacters.length === 0 ? (
          <Typography variant="body1">
            No characters found. {characters.length === 0 ? "Click 'Create New Character' to get started!" : ""}
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {filteredCharacters.map((character) => (
              <Grid item xs={12} sm={6} md={4} key={character._id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardActionArea component={RouterLink} to={`/characters/${character._id}`} sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                      <Avatar
                        src={`${getServerBaseUrl()}${character.avatarUrl}`}
                        alt={character.name}
                        sx={{ width: 150, height: 150 }}
                      />
                    </Box>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div" align="center">
                        {character.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        <strong>Age:</strong> {character.age}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        <strong>Personality:</strong> {character.personality || 'Not specified'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        <strong>Background:</strong> {character.background || 'Not specified'}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Box sx={{ p: 1 }}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <IconButton onClick={(event) => handleDeleteClick(character, event)} color="error" size="small">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        <Box mt={4} display="flex" justifyContent="center">
          {isMobile ? (
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => setCreateDialogOpen(true)}
              sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
              <AddIcon />
            </Fab>
          ) : (
            <StyledButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setCreateDialogOpen(true)}
            >
              Create New Character
            </StyledButton>
          )}
        </Box>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        fullScreen={isMobile}
      >
        <DialogTitle>Delete Character</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{characterToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogContent>
          <CharacterCreator onCharacterCreated={handleCreateCharacter} />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default CharacterLibrary;
