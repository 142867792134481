import React from 'react';
import { Card, CardMedia, Skeleton } from '@mui/material';
import { StyledButton } from './styles';

const ThumbnailSection = ({
  thumbnailUrl,
  title,
  thumbnailLoading,
  setThumbnailLoading,
  thumbnailError,
  setThumbnailError,
  handleRegenerateThumbnail,
  loading
}) => {
  return (
    <>
      <Card sx={{ maxWidth: '100%', mb: 2, width: '100%' }}>
        {thumbnailLoading && (
          <Skeleton variant="rectangular" width="100%" height={0} style={{ paddingTop: '100%' }} />
        )}
        <CardMedia
          component="img"
          sx={{
            aspectRatio: '1 / 1',
            objectFit: 'cover',
            display: thumbnailLoading ? 'none' : 'block',
          }}
          image={thumbnailError ? '/placeholder-image.jpg' : thumbnailUrl}
          alt={title}
          onError={(e) => {
            console.error('Error loading thumbnail:', e);
            console.error('Failed URL:', e.target.src);
            setThumbnailError(true);
            e.target.src = '/placeholder-image.jpg';
          }}
          onLoad={() => setThumbnailLoading(false)}
          loading="lazy"
        />
      </Card>
      <StyledButton
        onClick={handleRegenerateThumbnail}
        variant="contained"
        color="secondary"
        disabled={loading}
        fullWidth
        sx={{ mb: 3 }}
      >
        Regenerate Thumbnail
      </StyledButton>
    </>
  );
};

export default ThumbnailSection;
