import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import api, { getAvatarUrl, getThumbnailUrl } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import SharePopup from './SharePopup';
import EmailVerificationAlert from './EmailVerificationAlert';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  Alert,
  Skeleton,
  Avatar,
  AvatarGroup,
  Tooltip,
  CardActionArea,
  useTheme,
  useMediaQuery,
  Fab,
  Chip,
  Snackbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import { useSwipeable } from 'react-swipeable';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
}));

const StoryLibrary = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [storyToShare, setStoryToShare] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const { currentUser, setCurrentUser, websocketService } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const handleEmailVerified = useCallback(({ userId }) => {
    if (currentUser && currentUser.id === userId) {
      setCurrentUser(prevUser => ({ ...prevUser, isEmailVerified: true }));
    }
  }, [currentUser, setCurrentUser]);

  useEffect(() => {
    fetchStories();
    if (location.state && location.state.message) {
      setSuccessMessage(location.state.message);
      window.history.replaceState({}, document.title);
    }

    // Set up WebSocket event listener
    if (websocketService) {
      websocketService.on('emailVerified', handleEmailVerified);

      return () => {
        websocketService.off('emailVerified', handleEmailVerified);
      };
    }
  }, [location, handleEmailVerified, websocketService]);

  const fetchStories = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await api.get(`/stories?timestamp=${Date.now()}`);
      setStories(response.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch stories. Please try again.');
      console.error('Error fetching stories:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleShareClick = (story, event) => {
    event.stopPropagation();
    setStoryToShare(story);
    setShareDialogOpen(true);
  };

  const handleVisibilityChange = async (newVisibility) => {
    try {
      const response = await api.toggleStoryVisibility(storyToShare._id);
      setStories(stories.map(story => 
        story._id === storyToShare._id ? { ...story, visibility: response.data.visibility } : story
      ));
      setStoryToShare({ ...storyToShare, visibility: response.data.visibility });
      setSuccessMessage('Story visibility updated successfully');
    } catch (err) {
      setError('Failed to update story visibility. Please try again.');
      console.error('Error updating story visibility:', err);
    }
  };

  const handleShareabilityChange = async (newShareability) => {
    try {
      const response = await api.toggleStoryShareability(storyToShare._id);
      setStories(stories.map(story => 
        story._id === storyToShare._id ? { ...story, isShareable: response.data.isShareable, shareLink: response.data.shareLink } : story
      ));
      setStoryToShare({ ...storyToShare, isShareable: response.data.isShareable, shareLink: response.data.shareLink });
      setSuccessMessage('Story link created successfully');
    } catch (err) {
      setError('Failed to update story shareability. Please try again.');
      console.error('Error updating story shareability:', err);
    }
  };

  const handlers = useSwipeable({
    onSwipedDown: () => fetchStories(),
    delta: 100,
    preventDefaultTouchmoveEvent: true,
  });

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Stories
          </Typography>
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" width="100%" height={0} style={{ paddingTop: '100%', marginBottom: '8px' }} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" {...handlers}>
      <Box my={4}>
        <EmailVerificationAlert />
        <Typography variant="h4" component="h1" gutterBottom>
          Your Stories
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {error}
          </Alert>
        )}
        {stories.length === 0 ? (
          <Typography variant="body1">
            You haven't created any stories yet. Click "Create New Story" to get started!
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {stories.map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story._id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardActionArea component={RouterLink} to={`/view/${story._id}`} sx={{ flexGrow: 1 }}>
                    <CardMedia
                      component="img"
                      sx={{
                        aspectRatio: '1 / 1',
                        objectFit: 'cover',
                      }}
                      image={story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg'}
                      alt={story.title}
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/placeholder-image.jpg';
                      }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div" sx={{ 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        lineHeight: '1.3em',
                        height: '2.6em'
                      }}>
                        {story.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                      }}>
                        {story.summary || 'No summary available.'}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Box sx={{ p: 1 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <AvatarGroup max={isMobile ? 3 : 4} sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
                        {story.characters.map((character) => (
                          <Tooltip title={character.name} key={character._id}>
                            <Avatar
                              alt={character.name}
                              src={getAvatarUrl(character.avatarUrl)}
                              imgProps={{
                                loading: "lazy",
                                onError: (e) => {
                                  e.target.onerror = null;
                                  e.target.src = '/placeholder-avatar.jpg';
                                },
                              }}
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                      <Chip
                        icon={story.visibility === 'public' ? <PublicIcon /> : <LockIcon />}
                        label={story.visibility === 'public' ? 'Public' : 'Private'}
                        color={story.visibility === 'public' ? 'primary' : 'default'}
                        size="small"
                        onClick={(event) => handleShareClick(story, event)}
                      />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        <Box mt={4} display="flex" justifyContent="center">
          {isMobile ? (
            <Fab
              color="primary"
              aria-label="add"
              component={RouterLink}
              to="/create"
              sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
              <AddIcon />
            </Fab>
          ) : (
            <StyledButton
              component={RouterLink}
              to="/create"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Create New Story
            </StyledButton>
          )}
        </Box>
      </Box>
      {storyToShare && (
        <SharePopup
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          story={storyToShare}
          onVisibilityChange={handleVisibilityChange}
          onShareabilityChange={handleShareabilityChange}
        />
      )}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
    </Container>
  );
};

export default StoryLibrary;
