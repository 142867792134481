import React from 'react';
import { Container, Typography, Paper, Box, Avatar, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import SubscriptionManager from './SubscriptionManager';
import { CreditCard } from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  margin: 'auto',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));

const SubscriptionPage = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3} sx={{ mt: 4, p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <StyledAvatar>
          <CreditCard fontSize="large" />
        </StyledAvatar>
        <Typography 
          component="h1" 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold', 
            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
            mb: 1,
            textAlign: 'center',
            width: '100%'
          }}
        >
          Subscription Management
        </Typography>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            textAlign: 'center', 
            mb: 2, 
            color: theme.palette.text.secondary 
          }}
        >
          Manage your subscription and unlock more stories!
        </Typography>
        <Box sx={{ width: '100%', mt: 1 }}>
          <SubscriptionManager />
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default SubscriptionPage;
