import { useEffect } from 'react';
import websocketService from '../services/websocketService';

const useStoryCloneWebSocket = ({ onProgress, onComplete, onError }) => {
  useEffect(() => {
    // Subscribe to websocket events
    websocketService.on('storyProgress', onProgress);
    websocketService.on('storyComplete', onComplete);
    websocketService.on('storyError', onError);

    // Cleanup subscriptions
    return () => {
      websocketService.off('storyProgress', onProgress);
      websocketService.off('storyComplete', onComplete);
      websocketService.off('storyError', onError);
    };
  }, [onProgress, onComplete, onError]);

  const cloneStory = async (storyData) => {
    return await websocketService.createStory(storyData);
  };

  return { cloneStory };
};

export default useStoryCloneWebSocket;
