import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { Button, CircularProgress, Alert, Typography, Box, Grid, LinearProgress, Paper, Card, CardContent, CardActions, ToggleButtonGroup, ToggleButton, Badge, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js';
import { CreditCard, Star, AutoStories, DateRange, EmojiEvents, Person, EmojiEvents as Trophy } from '@mui/icons-material';
import SubscriptionButtonController from '../controllers/SubscriptionButtonController';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
    : 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: theme.palette.common.white,
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  margin: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  backgroundColor: '#FE6B8B',
  color: 'white',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
    backgroundColor: '#FF8E53',
  },
}));

const ManageSubscriptionButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 4),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  backgroundColor: 'white',
  color: '#FE6B8B',
  border: '2px solid #FE6B8B',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
    backgroundColor: 'white',
    color: '#FF8E53',
    border: '2px solid #FF8E53',
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '25px',
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: '25px',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '25px',
    },
    '&:first-of-type': {
      borderRadius: '25px',
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: '#FE6B8B',
    color: 'white',
    '&:hover': {
      backgroundColor: '#FF8E53',
    },
  },
}));

const PlanCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.2)',
  },
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
  borderRadius: '15px',
}));

const PlanCardContent = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const PlanName = styled(Typography)(({ theme }) => ({
  height: '2.5em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const PlanDetails = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const SavingsMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.mode === 'dark' ? theme.palette.success.light : theme.palette.success.main,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -14,
    top: -5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 6,
  borderRadius: 3,
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '100%',
  borderRadius: 3,
  backgroundColor: 'transparent',
  '& .MuiLinearProgress-bar': {
    borderRadius: 3,
    backgroundColor: theme.palette.common.white,
  },
}));

const SubscriptionManager = () => {
  const { fetchUserProfile } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isManaging, setIsManaging] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [storyLimit, setStoryLimit] = useState(0);
  const [storiesCreated, setStoriesCreated] = useState(0);
  const [tierInfo, setTierInfo] = useState('');
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [plans, setPlans] = useState([]);
  const [buttonController, setButtonController] = useState(null);
  const [priceIds, setPriceIds] = useState({});

  useEffect(() => {
    fetchUserProfile();
    fetchSubscriptionDetails();
    fetchStoryLimitInfo();
    fetchSubscriptionPlans();
    fetchPriceIds();
  }, [fetchUserProfile]);

  useEffect(() => {
    if (subscriptionDetails && priceIds) {
      const currentPriceId = subscriptionDetails.priceId;
      let currentPlan, currentBillingCycle;

      if (currentPriceId === priceIds.doubleFunMonthly || currentPriceId === priceIds.doubleFunYearly) {
        currentPlan = 'Double the Fun';
        currentBillingCycle = currentPriceId === priceIds.doubleFunMonthly ? 'monthly' : 'yearly';
      } else if (currentPriceId === priceIds.funMonthly || currentPriceId === priceIds.funYearly) {
        currentPlan = 'Fun';
        currentBillingCycle = currentPriceId === priceIds.funMonthly ? 'monthly' : 'yearly';
      } else {
        currentPlan = 'Free';
        currentBillingCycle = 'monthly';
      }

      console.log('Initializing SubscriptionButtonController:', { currentPlan, currentBillingCycle, priceId: currentPriceId });
      setButtonController(new SubscriptionButtonController(currentPlan, currentBillingCycle));
      setBillingCycle(currentBillingCycle);
    } else {
      console.log('Initializing SubscriptionButtonController for Free plan');
      setButtonController(new SubscriptionButtonController('Free', 'monthly'));
    }
  }, [subscriptionDetails, priceIds]);

  const fetchPriceIds = async () => {
    try {
      const response = await api.getPriceIds();
      setPriceIds(response.data);
    } catch (err) {
      console.error('Error fetching price IDs:', err);
      setError('Failed to fetch price IDs. Please try again.');
    }
  };

  const fetchSubscriptionDetails = async () => {
    try {
      const response = await api.getUserSubscriptionDetails();
      setSubscriptionDetails(response.data.subscription);
    } catch (err) {
      console.error('Error fetching subscription details:', err);
      setError('Failed to fetch subscription details. Please try again.');
    }
  };

  const fetchSubscriptionPlans = async () => {
    try {
      const response = await api.getSubscriptionPlans();
      console.log('Subscription plans received:', response.data);
      setPlans(response.data);
      response.data.forEach(plan => {
        console.log(`Plan: ${plan.name}, Story Limit: ${plan.storyLimit}`);
      });
    } catch (err) {
      console.error('Error fetching subscription plans:', err);
      setError('Failed to fetch subscription plans. Please try again.');
    }
  };

  const fetchStoryLimitInfo = async () => {
    try {
      const response = await api.getStoryLimitInfo();
      setStoryLimit(response.limit);
      setStoriesCreated(response.created);
      setTierInfo(response.tierInfo);
    } catch (err) {
      console.error('Error fetching story limit info:', err);
      setError('Failed to fetch story limit information.');
    } finally {
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsManaging(true);
      setError('');
      setSuccess('');
      const response = await api.createCustomerPortalSession();
      window.location.href = response.data.url;
    } catch (err) {
      console.error('Error creating customer portal session:', err);
      setError('An error occurred while managing your subscription. Please try again.');
    } finally {
      setIsManaging(false);
    }
  };

  const handleSubscribe = async (priceId) => {
    if (!priceId) {
      console.error('Invalid priceId');
      setError('Invalid subscription plan selected.');
      return;
    }

    try {
      setIsSubscribing(true);
      setError('');
      setSuccess('');
      console.log('Creating checkout session for priceId:', priceId);
      const response = await api.createCheckoutSession(priceId);
      console.log('Checkout session response:', response);
      const { sessionId } = response.data;
      console.log('SessionId:', sessionId);
      const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
      console.log('Loading Stripe with publishable key:', stripePublishableKey);
      if (!stripePublishableKey) {
        throw new Error('Stripe Publishable Key is not set');
      }
      const stripe = await loadStripe(stripePublishableKey);
      console.log('Stripe loaded:', !!stripe);
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }
      console.log('Redirecting to Stripe checkout...');
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect error:', error);
        setError(error.message);
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      setError(`An error occurred while processing your subscription: ${err.message}`);
    } finally {
      setIsSubscribing(false);
    }
  };

  const getProgress = () => {
    if (!subscriptionDetails) return 0;
    const start = new Date(subscriptionDetails.startDate).getTime();
    const end = new Date(subscriptionDetails.currentPeriodEnd).getTime();
    const now = new Date().getTime();
    return ((now - start) / (end - start)) * 100;
  };

  const getStoryProgress = () => {
    return (storiesCreated / storyLimit) * 100;
  };

  const getTierIcon = () => {
    if (tierInfo.includes('Double Fun')) return <Star />;
    if (tierInfo.includes('Fun')) return <EmojiEvents />;
    return <Person />;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const handleButtonClick = async (plan, billingCycle) => {
    if (!buttonController) {
      console.error('ButtonController is not initialized');
      return;
    }

    const buttonProps = buttonController.getButtonProps(plan, billingCycle);
    console.log('Button props:', { plan: plan.name, billingCycle, buttonProps });
    if (buttonProps.action === 'checkout') {
      const priceId = billingCycle === 'monthly' ? 
        (plan.name === 'Fun' ? priceIds.funMonthly : priceIds.doubleFunMonthly) :
        (plan.name === 'Fun' ? priceIds.funYearly : priceIds.doubleFunYearly);
      await handleSubscribe(priceId);
    } else if (buttonProps.action === 'manage') {
      await handleManageSubscription();
    }
  };

  if (loading) {
    return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
  }

  console.log('Rendering SubscriptionManager', { tierInfo, subscriptionDetails, buttonController });

  return (
    <Container maxWidth="md" sx={{ mt: 2, px: '2px' }}>
      <StyledPaper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
        {error && <Alert severity="error" sx={{ mb: 2, width: '100%' }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2, width: '100%' }}>{success}</Alert>}
        
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center">
              {getTierIcon()}
              <Typography variant="body1" sx={{ ml: 1 }}>
                {tierInfo}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <AutoStories fontSize="small" />
              <Typography variant="body2" sx={{ ml: 1 }}>Stories</Typography>
            </Box>
            <ProgressWrapper sx={{ width: '100%' }}>
              <StyledLinearProgress variant="determinate" value={getStoryProgress()} />
            </ProgressWrapper>
            <Typography variant="body2">
              Stories created: {storiesCreated} / {storyLimit}
            </Typography>
          </Grid>
          {subscriptionDetails && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <DateRange fontSize="small" />
                <Typography variant="body2" sx={{ ml: 1 }}>Subscription Period</Typography>
              </Box>
              <ProgressWrapper sx={{ width: '100%' }}>
                <StyledLinearProgress variant="determinate" value={getProgress()} />
              </ProgressWrapper>
              <Typography variant="body2">
                {formatDate(subscriptionDetails.startDate)} - {formatDate(subscriptionDetails.currentPeriodEnd)}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            {subscriptionDetails ? (
              <Box display="flex" justifyContent="center" width="100%">
                <ManageSubscriptionButton
                  variant="outlined"
                  onClick={handleManageSubscription}
                  disabled={isManaging}
                  startIcon={isManaging ? <CircularProgress size={20} /> : <CreditCard />}
                >
                  {isManaging ? 'Processing...' : 'Manage Subscription'}
                </ManageSubscriptionButton>
              </Box>
            ) : (
              <Typography variant="body2" textAlign="center">
                Choose a subscription plan below to get started.
              </Typography>
            )}
          </Grid>
        </Grid>
      </StyledPaper>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mt: 4 }}>
        Subscription Plans
      </Typography>
      <Box display="flex" justifyContent="center" mb={3}>
        <StyledToggleButtonGroup
          value={billingCycle}
          exclusive
          onChange={(event, newBillingCycle) => newBillingCycle && setBillingCycle(newBillingCycle)}
          aria-label="billing cycle"
        >
          <StyledToggleButton value="monthly" aria-label="monthly">
            Monthly
          </StyledToggleButton>
          <StyledToggleButton value="yearly" aria-label="yearly">
            <StyledBadge badgeContent="-20%" color="error">
              Yearly
            </StyledBadge>
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </Box>
      <SavingsMessage variant="subtitle1">
        Save 20% with our yearly subscriptions!
      </SavingsMessage>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {plans.map((plan) => {
          const buttonProps = buttonController ? buttonController.getButtonProps(plan, billingCycle) : null;
          return (
            <Grid item xs={12} sm={4} key={plan.name}>
              <PlanCard>
                <PlanCardContent>
                  <PlanName variant="h6" component="div">
                    {plan.icon === 'Person' ? <Person /> : plan.icon === 'EmojiEvents' ? <Trophy /> : <Star />}
                    <Box ml={1}>{plan.name}</Box>
                  </PlanName>
                  <PlanDetails>
                    <Typography variant="h4" component="div" textAlign="center" gutterBottom>
                      {plan.currencySymbol}{plan.price[billingCycle]}
                      <Typography variant="caption" display="block">
                        per {billingCycle === 'monthly' ? 'month' : 'year'}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" textAlign="center">
                      Create up to {plan.storyLimit} stories per month
                    </Typography>
                  </PlanDetails>
                </PlanCardContent>
                <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                  {buttonController && buttonProps ? (
                    <StyledButton
                      variant="contained"
                      onClick={() => handleButtonClick(plan, billingCycle)}
                      disabled={buttonProps.disabled || isSubscribing || isManaging}
                    >
                      {isSubscribing || isManaging ? 'Processing...' : buttonProps.text}
                    </StyledButton>
                  ) : (
                    <CircularProgress size={24} />
                  )}
                </CardActions>
              </PlanCard>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default SubscriptionManager;
