import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Slider,
  Typography,
  Paper,
  Avatar,
  Grid,
  useTheme,
  styled,
  useMediaQuery
} from '@mui/material';
import {
  PlayArrow,
  Pause,
  SkipPrevious,
  SkipNext,
  VolumeUp,
  VolumeOff,
  Shuffle,
  Repeat,
  QueueMusic,
  LibraryMusic
} from '@mui/icons-material';
import { getThumbnailUrl } from '../../services/api';
import { useAudioPlayer } from '../../contexts/AudioPlayerContext';
import QueueDrawer from './QueueDrawer';
import PlaylistManager from './PlaylistManager';

const PlayerPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(1),
  background: theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderTop: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.appBar,
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  }
}));

const ProgressSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 4,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    width: 12,
    height: 12,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main}30`,
    },
    '&.Mui-active': {
      width: 16,
      height: 16,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
  },
}));

const VolumeSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 100,
  '& .MuiSlider-thumb': {
    width: 12,
    height: 12,
  },
}));

const AudioPlayer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    currentStory,
    isPlaying,
    currentTime,
    duration,
    volume,
    isMuted,
    shuffle,
    repeat,
    togglePlay,
    seek,
    setVolume,
    toggleMute,
    playNext,
    playPrevious,
    toggleShuffle,
    toggleRepeat,
    cleanup // Add cleanup from context
  } = useAudioPlayer();

  const [queueOpen, setQueueOpen] = useState(false);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);

  // Add event listeners for drawer controls
  useEffect(() => {
    const handleOpenQueueDrawer = () => setQueueOpen(true);
    const handleOpenPlaylistManager = (event) => {
      if (event.detail?.playlistId) {
        setSelectedPlaylistId(event.detail.playlistId);
      }
      setPlaylistOpen(true);
    };
    const handleCleanup = () => cleanup(); // Add cleanup handler

    const playerElement = document.querySelector('#audio-player');
    if (playerElement) {
      playerElement.addEventListener('openQueueDrawer', handleOpenQueueDrawer);
      playerElement.addEventListener('openPlaylistManager', handleOpenPlaylistManager);
      playerElement.addEventListener('cleanup', handleCleanup); // Add cleanup listener
    }

    return () => {
      if (playerElement) {
        playerElement.removeEventListener('openQueueDrawer', handleOpenQueueDrawer);
        playerElement.removeEventListener('openPlaylistManager', handleOpenPlaylistManager);
        playerElement.removeEventListener('cleanup', handleCleanup); // Remove cleanup listener
      }
    };
  }, [cleanup]); // Add cleanup to dependencies

  const handlePlaylistClose = () => {
    setPlaylistOpen(false);
    setSelectedPlaylistId(null);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleProgressChange = (event, newValue) => {
    seek((newValue / 100) * duration);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue / 100);
  };

  return (
    <>
      <PlayerPaper elevation={3} id="audio-player">
        {isMobile ? (
          <Box>
            {/* Story Info Row */}
            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <Avatar
                variant="rounded"
                src={currentStory ? getThumbnailUrl(currentStory.thumbnailUrl) : ''}
                alt={currentStory?.title || 'No story playing'}
                sx={{ width: 40, height: 40 }}
              />
              <Box sx={{ minWidth: 0, flex: 1 }}>
                <Typography variant="subtitle2" noWrap>
                  {currentStory?.title || 'No story playing'}
                </Typography>
                <Typography variant="caption" color="text.secondary" noWrap>
                  By: {currentStory?.user?.name || 'Unknown'}
                </Typography>
              </Box>
            </Box>

            {/* Progress Bar */}
            <Box sx={{ px: 1, mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="caption" color="text.secondary">
                  {formatTime(currentTime)}
                </Typography>
                <ProgressSlider
                  value={(currentTime / duration) * 100 || 0}
                  onChange={handleProgressChange}
                />
                <Typography variant="caption" color="text.secondary">
                  {formatTime(duration)}
                </Typography>
              </Box>
            </Box>

            {/* Controls Row */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {/* Left Controls */}
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => setPlaylistOpen(true)} size="small">
                  <LibraryMusic />
                </IconButton>
                <IconButton 
                  color={shuffle ? 'primary' : 'default'}
                  onClick={toggleShuffle}
                  size="small"
                >
                  <Shuffle />
                </IconButton>
              </Box>

              {/* Center Controls */}
              <Box display="flex" alignItems="center" gap={1}>
                <IconButton onClick={playPrevious} size="large">
                  <SkipPrevious />
                </IconButton>
                <IconButton
                  onClick={togglePlay}
                  size="large"
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    '&:hover': { bgcolor: 'primary.dark' },
                  }}
                >
                  {isPlaying ? <Pause /> : <PlayArrow />}
                </IconButton>
                <IconButton onClick={playNext} size="large">
                  <SkipNext />
                </IconButton>
              </Box>

              {/* Right Controls */}
              <Box display="flex" alignItems="center">
                <IconButton 
                  color={repeat ? 'primary' : 'default'}
                  onClick={toggleRepeat}
                  size="small"
                >
                  <Repeat />
                </IconButton>
                <IconButton onClick={() => setQueueOpen(true)} size="small">
                  <QueueMusic />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ) : (
          // Desktop Layout
          <Grid container spacing={2} alignItems="center">
            {/* Left section - Story info */}
            <Grid item xs={3}>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar
                  variant="rounded"
                  src={currentStory ? getThumbnailUrl(currentStory.thumbnailUrl) : ''}
                  alt={currentStory?.title || 'No story playing'}
                  sx={{ width: 56, height: 56 }}
                />
                <Box>
                  <Typography variant="subtitle1" noWrap>
                    {currentStory?.title || 'No story playing'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    By: {currentStory?.user?.name || 'Unknown'}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Center section - Controls */}
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <IconButton onClick={() => setPlaylistOpen(true)}>
                    <LibraryMusic />
                  </IconButton>
                  <IconButton 
                    color={shuffle ? 'primary' : 'default'}
                    onClick={toggleShuffle}
                  >
                    <Shuffle />
                  </IconButton>
                  <IconButton onClick={playPrevious}>
                    <SkipPrevious />
                  </IconButton>
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      '&:hover': { bgcolor: 'primary.dark' },
                    }}
                    onClick={togglePlay}
                  >
                    {isPlaying ? <Pause /> : <PlayArrow />}
                  </IconButton>
                  <IconButton onClick={playNext}>
                    <SkipNext />
                  </IconButton>
                  <IconButton 
                    color={repeat ? 'primary' : 'default'}
                    onClick={toggleRepeat}
                  >
                    <Repeat />
                  </IconButton>
                  <IconButton onClick={() => setQueueOpen(true)}>
                    <QueueMusic />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                  <Typography variant="caption" color="text.secondary">
                    {formatTime(currentTime)}
                  </Typography>
                  <ProgressSlider
                    value={(currentTime / duration) * 100 || 0}
                    onChange={handleProgressChange}
                  />
                  <Typography variant="caption" color="text.secondary">
                    {formatTime(duration)}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Right section - Volume */}
            <Grid item xs={3}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                <IconButton onClick={toggleMute}>
                  {isMuted || volume === 0 ? <VolumeOff /> : <VolumeUp />}
                </IconButton>
                <VolumeSlider
                  value={isMuted ? 0 : volume * 100}
                  onChange={handleVolumeChange}
                  aria-label="Volume"
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </PlayerPaper>

      <QueueDrawer
        open={queueOpen}
        onClose={() => setQueueOpen(false)}
      />

      <PlaylistManager
        open={playlistOpen}
        onClose={handlePlaylistClose}
        initialPlaylistId={selectedPlaylistId}
      />
    </>
  );
};

export default AudioPlayer;
