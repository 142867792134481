import React from 'react';
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  Button,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ShareComponent = ({ story, onVisibilityChange, onShareabilityChange }) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/shared/${story.shareLink}`);
  };

  return (
    <Box>
      <Typography variant="h6">Share Settings</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={story.visibility === 'public'}
            onChange={(e) => onVisibilityChange(e.target.checked ? 'public' : 'private')}
            color="primary"
          />
        }
        label="Make story public"
      />
      <FormControlLabel
        control={
          <Switch
            checked={story.isShareable}
            onChange={(e) => onShareabilityChange(e.target.checked)}
            color="primary"
          />
        }
        label="Allow sharing via link"
      />
      {story.isShareable && story.shareLink && (
        <Box mt={2}>
          <TextField
            fullWidth
            label="Share Link"
            value={`${window.location.origin}/shared/${story.shareLink}`}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  onClick={handleCopyLink}
                  startIcon={<ContentCopyIcon />}
                >
                  Copy
                </Button>
              ),
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ShareComponent;
