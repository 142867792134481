import React from 'react';
import { FormControl, InputLabel, MenuItem, Box, Chip, Avatar } from '@mui/material';
import { StyledSelect } from './styles';
import { getAvatarUrl } from '../../services/api';

const CharacterSelect = ({
  characters,
  selectedCharacters,
  handleCharacterSelect,
  loading
}) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>Characters</InputLabel>
      <StyledSelect
        multiple
        value={selectedCharacters.map(char => char._id)}
        onChange={handleCharacterSelect}
        label="Characters"
        disabled={loading}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedCharacters.map((char) => (
              <Chip
                key={char._id}
                label={char.name}
                avatar={<Avatar alt={char.name} src={getAvatarUrl(char.avatarUrl)} />}
              />
            ))}
          </Box>
        )}
      >
        {characters.map((char) => (
          <MenuItem key={char._id} value={char._id}>
            {char.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default CharacterSelect;
