import React from 'react';
import { Container, Typography, Box, Paper, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  margin: 0,
}));

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <StyledPaper elevation={3}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          StoriesNow Privacy Policy
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph align="center">
          Last Updated: {new Date().toLocaleDateString()}
        </Typography>
        
        <Section title="Introduction">
          <Typography variant="body1" paragraph>
            This Privacy Policy explains how StoriesNow ("we," "us," or "our") collects, uses, and shares information about you when you use the StoriesNow website ("Website") or any related services. By using the Website, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy.
          </Typography>
        </Section>

        <Section title="Information We Collect">
          <Typography variant="body1" paragraph>
            We collect the following types of information when you use our Website:
          </Typography>
          <SubSection title="Account Information">
            <Typography variant="body1" paragraph>
              Personal Data: When you register for an account, participate in interactive features, fill out a form or survey, communicate with our customer support, or otherwise interact with us, you may provide personal information such as your name, email address, password, and any other information you choose to provide.
            </Typography>
          </SubSection>
          <SubSection title="Usage Information">
            <Typography variant="body1" paragraph>
              Activity Data: We collect information about how you use our Website, which may include the stories you create, your reading preferences, interactions with the Website, session durations, accessed features, and other similar usage information.
            </Typography>
          </SubSection>
        </Section>

        <Section title="How We Use Your Information">
          <Typography variant="body1" paragraph>
            We use the information we collect for various purposes, including:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Service Delivery: To provide, maintain, and improve our Website and services." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Communication: To send transactional or relationship communications, such as account notifications, customer service responses, and other administrative messages." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Personalization: To personalize your experience, tailor content according to your interests, and customize the advertisements you encounter on other platforms in line with your preferences and browsing behavior." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Updates and Promotions: To communicate with you about updates, promotions, and other relevant news regarding StoriesNow." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Analytics: To assess usage patterns and trends to refine and improve our Website's functionality." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Security: To identify, prevent, and rectify technical and security issues." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Fraud Prevention: To detect, investigate, and prevent fraudulent transactions and other illicit activities, protecting the rights and property of StoriesNow and other users." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Legal Compliance: To process transactional records for tax filings and other compliance activities." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Enforcement: To enforce our Terms of Use and fulfill our legal obligations." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Contests and Promotions: To organize contests, sweepstakes, and promotions, and process and deliver entries and rewards." />
            </ListItem>
          </List>
        </Section>

        <Section title="Data Retention">
          <Typography variant="body1" paragraph>
            We retain your information for as long as necessary to provide our services and fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
          </Typography>
        </Section>

        <Section title="Data Security">
          <Typography variant="body1" paragraph>
            We take reasonable measures, including administrative, technical, and physical safeguards, to protect your information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. While we strive to protect your personal information, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee its absolute security.
          </Typography>
        </Section>

        <Section title="Children's Privacy">
          <Typography variant="body1" paragraph>
            The Website is intended for users who are at least 13 years old. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it as soon as possible. As part of the Website's functionality, you may be asked to create a profile that includes a first name, age, default language, and default narrator voice. This information is used to generate stories at an appropriate reading level and enhance your personalized experience. This information is kept confidential and is not shared with third parties, except as described in this Privacy Policy.
          </Typography>
        </Section>

        <Section title="Third-Party Services">
          <Typography variant="body1" paragraph>
            Our Website uses third-party service providers to enable certain features and improve functionality.
          </Typography>
          <SubSection title="AI Service Providers">
            <Typography variant="body1" paragraph>
              We utilize AI-driven story generation services. When you create a story using our Website, we send the information you input into the story generator to these services, which process this data and return AI-generated text and images to be incorporated into your story. We do not share any personally identifiable information, such as your name or email address, with these service providers when creating stories.
            </Typography>
          </SubSection>
          <SubSection title="Payment Processing">
            <Typography variant="body1" paragraph>
              We use Stripe for subscription payments. We do not have access to your full payment information, as transactions are processed directly by Stripe. We only receive information related to the transaction necessary to fulfill our obligations under our Terms of Use and this Privacy Policy, such as details of the purchase and subscription status.
            </Typography>
          </SubSection>
          <Typography variant="body1" paragraph>
            Please note that we do not control and are not responsible for the privacy practices of these third-party services. We recommend you review their respective privacy policies to understand how they collect, use, and share your data.
          </Typography>
        </Section>

        <Section title="Information for California Residents">
          <Typography variant="body1" paragraph>
            This section provides additional disclosures required by the California Consumer Privacy Act (CCPA).
          </Typography>
          <SubSection title="Categories of Personal Information We Collect">
            <List>
              <ListItem>
                <ListItemText primary="Identifiers: Such as your name, email address, and unique identifiers tied to your browser or device." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Characteristics of Protected Classifications: Such as age and gender." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Commercial Information: Such as your payment information and StoriesNow product or service purchases." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Internet or Other Electronic Network Activity: Such as browsing behavior and information about your usage and interactions with our Website." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Other Personal Information: Including preferences and information contained in surveys or communications." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Inferences: Drawn from the above, such as product interests and usage insights." />
              </ListItem>
            </List>
          </SubSection>
          <SubSection title="Business or Commercial Purposes for Use">
            <List>
              <ListItem>
                <ListItemText primary="Service Provision: Maintaining accounts, providing customer service, processing orders and transactions, and verifying customer information." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Improvement and Maintenance: Enhancing our Website and developing new products and services." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Debugging: Identifying and repairing errors and functionality issues." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Communication: Marketing and transactional messages." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Analytics: Monitoring trends and activities in connection with our Website." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Personalization: Tailoring content you see on our Website based on your preferences." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Legal Reasons: Detecting and protecting against security incidents or illegal activity." />
              </ListItem>
            </List>
          </SubSection>
          <SubSection title="Parties with Whom Information May Be Shared">
            <List>
              <ListItem>
                <ListItemText primary="Service Providers: Companies that assist with customer support, data analytics, fraud prevention, cloud storage, and payment processing." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Third Parties with Consent: Such as social media services or academic researchers." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Advertising and Marketing Partners: Partners that help determine content popularity and deliver targeted advertising." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Legal and Regulatory Authorities: Government entities or other third parties for legal reasons." />
              </ListItem>
            </List>
          </SubSection>
          <Typography variant="body1" paragraph>
            We do not "sell" the personal information we collect (and will not sell it in the future without providing a right to opt-out). California consumers may make a rights request by emailing us at termsandprivacy@storiesnow.app. We will verify your request by asking you to provide information that matches our records. Authorized agents can also submit requests on your behalf.
          </Typography>
        </Section>

        <Section title="Information for European Union Users">
          <Typography variant="body1" paragraph>
            If you are a user from the European Union, you have certain rights and protections under the General Data Protection Regulation (GDPR).
          </Typography>
          <SubSection title="Rights of EU Users">
            <List>
              <ListItem>
                <ListItemText primary="Right to Access: Request access to your personal data that we process." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Right to Rectification: Request correction of inaccurate or incomplete personal data." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Right to Erasure: Request deletion of your personal data." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Right to Restrict Processing: Request restriction of processing under certain circumstances." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Right to Data Portability: Receive your personal data in a structured, commonly used, machine-readable format." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Right to Object: Object to the processing of your personal data for reasons related to your particular situation." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Right Not to Be Subject to Automated Decision-Making: Not to be subject to decisions based solely on automated processing that produce legal effects concerning you." />
              </ListItem>
            </List>
          </SubSection>
          <Typography variant="body1" paragraph>
            To exercise these rights, please contact us at termsandprivacy@storiesnow.app. We may ask you to verify your identity for security purposes.
          </Typography>
          <SubSection title="Data Transfers">
            <Typography variant="body1" paragraph>
              Your personal data may be transferred to, stored, and processed in countries outside the EEA, including the United States. We ensure that such data transfers comply with applicable laws by relying on legal data transfer mechanisms such as Standard Contractual Clauses.
            </Typography>
          </SubSection>
          <SubSection title="Data Protection Officer">
            <Typography variant="body1" paragraph>
              If you have any questions or concerns about our use of your personal data, you can contact our Data Protection Officer at termsandprivacy@storiesnow.app. You also have the right to lodge a complaint with your local data protection authority.
            </Typography>
          </SubSection>
        </Section>

        <Section title="International Data Transfers">
          <Typography variant="body1" paragraph>
            If you are located outside of the United States, your information may be transferred to, stored, and processed in countries where our servers are located and our service providers operate. By using the Website, you consent to the transfer of your information in accordance with this Privacy Policy and applicable laws.
          </Typography>
        </Section>

        <Section title="Changes to This Privacy Policy">
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the "Last Updated" date at the top of the policy. In some cases, we may provide additional notice (such as adding a statement to our Website or sending you a notification). Your continued use of our Website after such changes become effective constitutes your acceptance of the updated Privacy Policy.
          </Typography>
        </Section>

        <Section title="Your Rights and Choices">
          <Typography variant="body1" paragraph>
            Depending on your jurisdiction, you may have certain rights regarding your personal information:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Access: Request access to the personal information we hold about you." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Rectification: Request correction of inaccurate or incomplete personal information." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Deletion: Request deletion of your personal information." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Restrict Processing: Request restriction of processing under certain conditions." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Data Portability: Request your personal information in a structured, machine-readable format." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Withdrawal of Consent: Withdraw consent to processing your personal information at any time." />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            To exercise these rights, please contact us at termsandprivacy@storiesnow.app. We will respond in accordance with applicable laws and may require verification of your identity.
          </Typography>
        </Section>

        <Section title="Cookies, Analytics, and Similar Technologies">
          <Typography variant="body1" paragraph>
            StoriesNow uses cookies and similar technologies to personalize and enhance your experience on our Website.
          </Typography>
          <SubSection title="What Are Cookies?">
            <Typography variant="body1" paragraph>
              Cookies are small text files stored on your device when you visit our Website. They help us remember your preferences, understand how you use our Website, and customize content and advertisements.
            </Typography>
          </SubSection>
          <SubSection title="How We Use Cookies">
            <List>
              <ListItem>
                <ListItemText primary="Essential Cookies: Necessary for the operation of our Website." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Performance Cookies: Collect information about how you use our Website to improve functionality." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Functionality Cookies: Remember choices you make to enhance your experience." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Targeting Cookies: Deliver content relevant to your interests." />
              </ListItem>
            </List>
          </SubSection>
          <SubSection title="Your Choices">
            <Typography variant="body1" paragraph>
              You can control cookies through your browser settings. If you choose to decline cookies, some parts of our Website may not function properly.
            </Typography>
          </SubSection>
          <Typography variant="body1" paragraph>
            For more information about our use of cookies or other technologies, please email us at termsandprivacy@storiesnow.app.
          </Typography>
        </Section>

        <Section title="Contact Us">
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            Email: termsandprivacy@storiesnow.app
          </Typography>
          <Typography variant="body1" paragraph>
            By using StoriesNow, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
          </Typography>
        </Section>
      </StyledPaper>
    </Container>
  );
};

const Section = ({ title, children }) => (
  <Box mb={3}>
    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>
    {children}
  </Box>
);

const SubSection = ({ title, children }) => (
  <Box ml={2} mb={2}>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    {children}
  </Box>
);

export default PrivacyPolicy;
