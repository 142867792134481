import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';

// Custom styled Alert component for dark mode
const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1e3a5f' : undefined,
  color: theme.palette.mode === 'dark' ? '#ffffff' : undefined,
}));

// Custom styled Link component
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [serverStatus, setServerStatus] = useState(null);
  const [isEmailUnverified, setIsEmailUnverified] = useState(false);
  const { login, loading: authLoading, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      navigate('/library');
    }
    if (location.state && location.state.message) {
      setSuccessMessage(location.state.message);
      window.history.replaceState({}, document.title);
    }
  }, [currentUser, navigate, location]);

  useEffect(() => {
    checkServerStatus();
  }, []);

  const checkServerStatus = async () => {
    try {
      const response = await api.checkStatus();
      setServerStatus(response.data.message);
    } catch (error) {
      console.error('Server status check failed:', error);
      setServerStatus('Server is unreachable');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsLoading(true);
    setIsEmailUnverified(false);
    try {
      console.log('Attempting login with:', email);
      await login(email, password);
      console.log('Login successful');
      navigate('/library');
    } catch (error) {
      console.error('Login error:', error);
      console.error('Error name:', error.name);
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      }
      
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
        if (error.response.status === 403 && error.response.data.isEmailVerified === false) {
          setIsEmailUnverified(true);
        }
      } else if (error.message) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerificationEmail = async () => {
    setIsLoading(true);
    try {
      await api.resendVerificationEmail(email);
      setSuccessMessage('Verification email has been resent. Please check your inbox.');
      setIsEmailUnverified(false);
    } catch (error) {
      console.error('Error resending verification email:', error);
      setError('Failed to resend verification email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (authLoading) {
    return (
      <Container component="main" maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login to StoriesNow
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
          Welcome back to StoriesNow, where your imagination comes to life!
        </Typography>
        {serverStatus === 'Server is unreachable' && (
          <StyledAlert severity="error" sx={{ mt: 2, width: '100%' }}>
            Server is currently unreachable. Please try again later.
          </StyledAlert>
        )}
        {successMessage && (
          <StyledAlert severity="success" sx={{ mt: 2, width: '100%' }}>
            {successMessage}
          </StyledAlert>
        )}
        {error && (
          <StyledAlert severity="error" sx={{ mt: 2, width: '100%' }}>
            {error}
          </StyledAlert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <Typography variant="body2" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
            <StyledLink component={RouterLink} to="/signup">
              Don't have an account? Sign Up
            </StyledLink>
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading || serverStatus === 'Server is unreachable'}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
          {isEmailUnverified && (
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 2 }}
              onClick={handleResendVerificationEmail}
              disabled={isLoading}
            >
              Resend Verification Email
            </Button>
          )}
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Typography variant="body2">
                  <StyledLink component={RouterLink} to="/">
                    Return to Home
                  </StyledLink>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <StyledLink component={RouterLink} to="/forgot-password">
                    Forgot password?
                  </StyledLink>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
