import React, { useState } from 'react';
import { Container, Box, Tabs, Tab, Typography } from '@mui/material';
import StoryLibrary from './StoryLibrary';
import PublicFavourites from './PublicFavourites';
import EmailVerificationAlert from './EmailVerificationAlert';

const MyLibrary = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <EmailVerificationAlert />
        <Typography variant="h4" component="h1" gutterBottom>
          My Library
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="My Stories" />
            <Tab label="Public Favourites" />
          </Tabs>
        </Box>
        {activeTab === 0 ? <StoryLibrary /> : <PublicFavourites />}
      </Box>
    </Container>
  );
};

export default MyLibrary;
