import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Checkbox, FormControlLabel, Link, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReCAPTCHA from "react-google-recaptcha";
import { Link as RouterLink } from 'react-router-dom';
import api from '../services/api';

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  margin: '0 auto',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [agreeToComm, setAgreeToComm] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setSubmitStatus({ type: 'error', message: 'Please complete the reCAPTCHA.' });
      return;
    }
    if (!agreeToComm) {
      setSubmitStatus({ type: 'error', message: 'Please agree to receive communications.' });
      return;
    }
    try {
      await api.post('/contact', { name, email, message, captchaValue });
      setSubmitStatus({ type: 'success', message: 'Your message has been sent successfully!' });
      setName('');
      setEmail('');
      setMessage('');
      setAgreeToComm(false);
      setCaptchaValue(null);
    } catch (error) {
      setSubmitStatus({ type: 'error', message: 'Failed to send message. Please try again.' });
    }
  };

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  // Log the reCAPTCHA site key for debugging
  console.log('reCAPTCHA site key:', process.env.REACT_APP_RECAPTCHA_SITE_KEY);

  return (
    <Container maxWidth="md">
      <StyledPaper>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToComm}
                onChange={(e) => setAgreeToComm(e.target.checked)}
                name="agreeToComm"
              />
            }
            label={
              <Typography variant="body2">
                I agree to receive communications from StoriesNow*
              </Typography>
            }
          />
          <Typography variant="body2" color="textSecondary" paragraph>
            Information shared with StoriesNow will be handled in accordance with our{' '}
            <Link component={RouterLink} to="/privacy">Privacy Policy</Link>.
            You can unsubscribe from these communications at any time.
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            By clicking submit below, you consent to allow StoriesNow to store and process the personal information submitted above to provide you the content requested.
          </Typography>
          <Box my={2}>
            {process.env.REACT_APP_RECAPTCHA_SITE_KEY ? (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleRecaptchaChange}
              />
            ) : (
              <Typography color="error">reCAPTCHA site key is missing</Typography>
            )}
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
        {submitStatus && (
          <Alert severity={submitStatus.type} sx={{ mt: 2 }}>
            {submitStatus.message}
          </Alert>
        )}
      </StyledPaper>
    </Container>
  );
};

export default ContactUs;
