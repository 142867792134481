import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import api, { getThumbnailUrl, getNarrationUrl, getAvatarUrl } from '../services/api';
import SharePopup from './SharePopup';
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardMedia,
  Chip,
  Alert,
  Avatar,
  Grid,
  Link,
  Skeleton,
  useTheme,
  useMediaQuery,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '400px',
  },
}));

const AdminStoryView = () => {
  const [story, setStory] = useState(null);
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogStep, setDialogStep] = useState('confirm'); // 'confirm' or 'success'
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchStoryAndAuthor = async () => {
      try {
        setLoading(true);
        const storyResponse = await api.get(`/admin/stories/${id}`);
        console.log('Story data:', storyResponse.data);
        setStory(storyResponse.data);
        setIsFeatured(storyResponse.data.featured);
        
        if (storyResponse.data.user && typeof storyResponse.data.user === 'string') {
          const authorResponse = await api.getUserById(storyResponse.data.user);
          console.log('Author data:', authorResponse.data);
          setAuthor(authorResponse.data);
        } else if (storyResponse.data.user && typeof storyResponse.data.user === 'object') {
          console.log('Author data (from story):', storyResponse.data.user);
          setAuthor(storyResponse.data.user);
        }
        
        setError(null);
      } catch (error) {
        console.error('Error fetching story or author:', error);
        setError('Failed to load the story or author information. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchStoryAndAuthor();
  }, [id]);

  useEffect(() => {
    if (story && story.thumbnailUrl) {
      const img = new Image();
      img.src = getThumbnailUrl(story.thumbnailUrl);
      img.onload = () => setThumbnailLoading(false);
      img.onerror = () => {
        console.error('Error loading thumbnail:', img.src);
        setThumbnailError(true);
        setThumbnailLoading(false);
      };
    }
  }, [story]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogStep('confirm');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogStep('confirm');
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.adminDeleteStory(id);
      setDialogStep('success');
    } catch (error) {
      console.error('Error deleting story:', error);
      setError('Failed to delete the story. Please try again.');
      handleCloseDialog();
    }
  };

  const handleSuccessClose = () => {
    handleCloseDialog();
    navigate(`/admin/users/${author._id}`);
  };

  const handleShareDialogOpen = () => {
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  const handleVisibilityChange = async (newVisibility) => {
    try {
      const response = await api.toggleStoryVisibility(id);
      setStory({ ...story, visibility: response.data.visibility });
    } catch (error) {
      console.error('Error changing story visibility:', error);
      setError('Failed to change story visibility. Please try again.');
    }
  };

  const handleShareabilityChange = async (newShareability) => {
    try {
      const response = await api.toggleStoryShareability(id);
      setStory({ ...story, isShareable: response.data.isShareable, shareLink: response.data.shareLink });
    } catch (error) {
      console.error('Error changing story shareability:', error);
      setError('Failed to change story shareability. Please try again.');
    }
  };

  const handleToggleFeatured = async () => {
    try {
      const response = await api.post(`/stories/${id}/set-featured`);
      setIsFeatured(response.data.featured);
      setStory({ ...story, featured: response.data.featured });
    } catch (error) {
      console.error('Error toggling featured status:', error);
      setError('Failed to change featured status. Please try again.');
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string && typeof string === 'string' ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  };

  const parsePrompt = (prompt) => {
    if (!prompt) return { characters: '', summaries: '', storyPrompt: '' };

    const characterMatch = prompt.match(/Characters:([\s\S]*?)(?=Previous Story Summaries:)/);
    const summariesMatch = prompt.match(/Previous Story Summaries:([\s\S]*?)(?=Story Prompt:)/);
    const storyPromptMatch = prompt.match(/Story Prompt:([\s\S]*?)(?=Generate a)/);

    const characters = characterMatch ? characterMatch[1].trim() : '';
    const summaries = summariesMatch ? summariesMatch[1].trim() : '';
    const storyPrompt = storyPromptMatch ? storyPromptMatch[1].trim() : '';

    return { characters, summaries, storyPrompt };
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
      </Container>
    );
  }

  if (!story) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6">Story not found</Typography>
      </Container>
    );
  }

  const thumbnailUrl = story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg';
  const { characters, summaries, storyPrompt } = parsePrompt(story.prompt);

  return (
    <Container maxWidth="md">
      <Box my={4}>
      <Typography variant="h4" component="h1" gutterBottom>
          {story.title || 'Untitled Story'}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Chip label={`Length: ${capitalizeFirstLetter(story.length)}`} color="primary" />
          <Chip label={`Genre: ${capitalizeFirstLetter(story.genre)}`} color="secondary" />
        </Box>
        <Card sx={{ maxWidth: '100%', mb: 3 }}>
          {thumbnailLoading ? (
            <Skeleton variant="rectangular" width="100%" height={0} style={{ paddingTop: '100%' }} />
          ) : (
            <CardMedia
              component="img"
              sx={{
                aspectRatio: '1 / 1',
                objectFit: 'cover',
              }}
              image={thumbnailError ? '/placeholder-image.jpg' : thumbnailUrl}
              alt={story.title || 'Story thumbnail'}
            />
          )}
        </Card>

        {/* Author, Featured, and Visibility Chips */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          {author && (
            <Chip
              label={`Author: ${author.name || 'Unknown'}`}
              component={RouterLink}
              to={`/admin/users/${author._id}`}
              clickable
              color="primary"
            />
          )}
          <Chip
            icon={isFeatured ? <StarIcon /> : <StarBorderIcon />}
            label={isFeatured ? "Featured" : "Set as Featured"}
            color={isFeatured ? "primary" : "default"}
            onClick={handleToggleFeatured}
            sx={{ mx: 1 }}
          />
          <Chip
            icon={story.visibility === 'public' ? <PublicIcon /> : <LockIcon />}
            label={story.visibility === 'public' ? 'Public' : 'Private'}
            color={story.visibility === 'public' ? 'primary' : 'default'}
            onClick={handleShareDialogOpen}
            clickable
          />
        </Box>

        {/* Characters section */}
        {story.characters && story.characters.length > 0 && (
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>Characters:</Typography>
            <Grid container spacing={2}>
              {story.characters.map((character) => (
                <Grid item key={character._id} xs={4} sm={3} md={2}>
                  <Link
                    component={RouterLink}
                    to={`/admin/characters/${character._id}`}
                    underline="none"
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center',
                      '&:hover': {
                        '& .MuiAvatar-root': {
                          border: '2px solid #1976d2',
                        },
                        '& .MuiTypography-root': {
                          color: '#1976d2',
                        },
                      },
                    }}
                  >
                    <Avatar
                      alt={character.name || 'Character'}
                      src={character.avatarUrl ? getAvatarUrl(character.avatarUrl) : '/placeholder-avatar.jpg'}
                      sx={{ width: 48, height: 48, mb: 1 }}
                      imgProps={{
                        loading: "lazy",
                        onError: (e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder-avatar.jpg';
                        },
                      }}
                    />
                    <Typography variant="body2" align="center" sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
                      {character.name || 'Unnamed Character'}
                    </Typography>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Narration section */}
        {story.narrationUrl && (
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>Narration:</Typography>
            <audio controls style={{ width: '100%' }}>
              <source src={getNarrationUrl(story.narrationUrl)} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}

        {/* Summary section */}
        <Paper elevation={3}>
          <Box p={3} mb={3}>
            <Typography variant="h6" gutterBottom>Summary:</Typography>
            <Typography variant="body1">{story.summary || 'No summary available.'}</Typography>
          </Box>
        </Paper>

        {/* Story content section */}
        <Paper elevation={3}>
          <Box p={3} mb={3}>
            <Typography variant="h6" gutterBottom>Story:</Typography>
            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
              {story.content || 'No content available.'}
            </Typography>
          </Box>
        </Paper>

        {/* Prompt section */}
        <Paper elevation={3}>
          <Box p={3} mb={3}>
            <Typography variant="h6" gutterBottom>Prompt:</Typography>
            {characters && (
              <Box mb={2}>
                <Typography variant="subtitle1" fontWeight="bold">Characters:</Typography>
                <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>{characters}</Typography>
              </Box>
            )}
            {summaries && (
              <>
                <Divider sx={{ my: 2 }} />
                <Box mb={2}>
                  <Typography variant="subtitle1" fontWeight="bold">Previous Story Summaries:</Typography>
                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>{summaries}</Typography>
                </Box>
              </>
            )}
            {storyPrompt && (
              <>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">Story Prompt:</Typography>
                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>{storyPrompt}</Typography>
                </Box>
              </>
            )}
          </Box>
        </Paper>

        {/* Featured toggle button */}
        <Box mt={2} mb={3}>
          <Button
            onClick={handleToggleFeatured}
            variant="contained"
            color={isFeatured ? "secondary" : "primary"}
            startIcon={isFeatured ? <StarIcon /> : <StarBorderIcon />}
            fullWidth={isMobile}
          >
            {isFeatured ? "Unset Featured" : "Set as Featured"}
          </Button>
        </Box>

       {/* Delete button */}
       <Box mt={2} mb={3}>
          <Button
            onClick={handleOpenDialog}
            variant="contained"
            color="error"
            fullWidth={isMobile}
          >
            Delete Story
          </Button>
        </Box>

        <Box mt={2}>
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            color="primary"
            fullWidth={isMobile}
          >
            Back
          </Button>
        </Box>
      </Box>

      {/* Delete Confirmation Dialog */}
      <StyledDialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogStep === 'confirm' ? 'Confirm Story Deletion' : 'Story Deleted Successfully'}
        </DialogTitle>
        <DialogContent>
          {dialogStep === 'confirm' && (
            <>
              <Alert severity="warning" sx={{ mb: 2 }}>
                Be careful with this function. Deleting a story cannot be undone.
              </Alert>
              <DialogContentText>
                Are you sure you want to delete this story?
              </DialogContentText>
            </>
          )}
          {dialogStep === 'success' && (
            <DialogContentText>
              The story has been successfully deleted.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {dialogStep === 'confirm' && (
            <>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
            </>
          )}
          {dialogStep === 'success' && (
            <Button onClick={handleSuccessClose} color="primary">OK</Button>
          )}
        </DialogActions>
      </StyledDialog>

      {/* Share Dialog */}
      <SharePopup
        open={shareDialogOpen}
        onClose={handleShareDialogClose}
        story={story}
        onVisibilityChange={handleVisibilityChange}
        onShareabilityChange={handleShareabilityChange}
      />
    </Container>
  );
};

export default AdminStoryView;
