import axios from 'axios';
import websocketService from './websocketService';

// Determine the API_BASE_URL based on the current hostname or IP
const getApiBaseUrl = () => {
  const { protocol, hostname, port } = window.location;
  console.log('Current protocol:', protocol);
  console.log('Current hostname:', hostname);
  console.log('Current port:', port);
  console.log('NODE_ENV:', process.env.NODE_ENV);
  console.log('REACT_APP_API_BASE_URL_DEVELOPMENT:', process.env.REACT_APP_API_BASE_URL_DEVELOPMENT);
  console.log('REACT_APP_API_BASE_URL_LOCAL_NETWORK:', process.env.REACT_APP_API_BASE_URL_LOCAL_NETWORK);
  
  if (hostname === 'localhost' || (hostname === '127.0.0.1' && port === '3000')) {
    console.log('Using development API URL');
    return process.env.REACT_APP_API_BASE_URL_DEVELOPMENT;
  }
  if (hostname === '192.168.1.161' && port === '3000') {
    console.log('Using local network API URL');
    return process.env.REACT_APP_API_BASE_URL_LOCAL_NETWORK;
  }
  if (hostname === 'storycraft.geekpoise.com' || 
      hostname === 'storiesnow.co' || 
      hostname === 'www.storiesnow.co' || 
      hostname === 'storiesnow.app' || 
      hostname === 'www.storiesnow.app') {
    console.log('Using production API URL');
    return `${protocol}//${hostname}/api`;
  }
  
  // For all other hostnames, use the current origin
  console.log('Using current origin as API URL');
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/api`;
};

const API_BASE_URL = getApiBaseUrl();
export const SERVER_BASE_URL = API_BASE_URL.replace('/api', '');

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const publicRoutes = [
      '/stories/featured',
      '/stories/shared',
      '/public/stories'
    ];
    
    if (token && !publicRoutes.some(route => config.url.includes(route))) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('API Request:', config.method.toUpperCase(), config.url);
    console.log('Full URL:', `${config.baseURL}${config.url}`);
    console.log('Request headers:', config.headers);
    return config;
  },
  (error) => {
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    console.log('API Response:', response.status, response.config.url);
    return response;
  },
  (error) => {
    console.error('API Response Error:', error.response ? error.response.status : 'Unknown', error.config.url);
    console.error('Error details:', error.response ? error.response.data : error.message);
    return Promise.reject(error);
  }
);

// WebSocket integration
api.connectWebSocket = () => websocketService.connect();
api.disconnectWebSocket = () => websocketService.disconnect();

// Delegate WebSocket event handling to websocketService
api.on = (event, handler) => websocketService.on(event, handler);
api.off = (event, handler) => websocketService.off(event, handler);
api.emit = (event, data) => websocketService.emit(event, data);

// Story creation with websocketService
api.createStoryWebSocket = (storyData) => websocketService.createStory(storyData);

// Add this new method to fetch subscription plans
api.getSubscriptionPlans = () => api.get('/subscriptions/plans');

// Add this new method for creating a checkout session
api.createCheckoutSession = (priceId) => api.post('/subscriptions/create-checkout-session', { priceId });

// Add a new method to handle successful checkout
api.handleSuccessfulCheckout = (sessionId) => api.get(`/subscriptions/checkout-success?session_id=${sessionId}`);

// Add this new method for creating a customer portal session
api.createCustomerPortalSession = () => api.post('/subscriptions/create-customer-portal-session');

// Add this new method for canceling a subscription
api.cancelSubscription = () => api.post('/subscriptions/cancel');

// Update other subscription-related methods to ensure consistency
api.getUserSubscriptionDetails = () => api.get('/subscriptions/details');
api.getStoryLimitInfo = () => api.get('/subscriptions/story-limit');
api.getSubscriptionStatus = () => api.get('/subscriptions/status');
api.createSubscription = (paymentMethodId, priceId) => api.post('/subscriptions/create', { paymentMethodId, priceId });

// Story-related methods
api.getStory = (storyId, isPublic = false) => {
  // If isPublic is true, use the public endpoint
  return isPublic ? api.getPublicStory(storyId) : api.get(`/stories/${storyId}`);
};

api.getStories = (characterId) => api.get('/stories', { params: characterId ? { character: characterId } : {} });
api.updateStory = (storyId, storyData) => api.put(`/stories/${storyId}`, storyData);
api.deleteStory = (storyId) => api.delete(`/stories/${storyId}`);
api.regenerateThumbnail = (storyId) => api.post(`/stories/${storyId}/regenerate-thumbnail`);
api.regenerateNarration = (storyId) => api.post(`/stories/${storyId}/regenerate-narration`);
api.regenerateSummary = (storyId) => api.post(`/stories/${storyId}/regenerate-summary`);
api.rewriteID3Tags = (storyId) => api.post(`/stories/${storyId}/rewrite-id3-tags`);
api.cloneStory = (storyData) => api.createStoryWebSocket(storyData);
api.toggleStoryVisibility = (storyId) => api.post(`/stories/${storyId}/toggle-visibility`);
api.toggleStoryShareability = (storyId) => api.post(`/stories/${storyId}/toggle-shareability`);
api.getSharedStory = (shareLink) => api.get(`/stories/shared/${shareLink}`);

// Public story-related methods
api.getPublicStories = (page = 1, limit = 10) => api.get('/public/stories', { params: { page, limit } });
api.getPublicStory = (storyId) => api.get(`/public/stories/${storyId}`);
api.getFavoriteStories = () => api.get('/public/stories/favorites');
api.addToFavorites = (storyId) => api.post(`/public/stories/${storyId}/favorite`);
api.removeFromFavorites = (storyId) => api.delete(`/public/stories/${storyId}/favorite`);

// Featured stories methods
api.getFeaturedStories = () => {
  console.log('Fetching featured stories');
  console.log('API_BASE_URL:', API_BASE_URL);
  return api.get('/stories/featured').then(response => {
    console.log('Featured stories response:', response);
    return response;
  }).catch(error => {
    console.error('Error fetching featured stories:', error);
    console.error('Error response:', error.response);
    throw error;
  });
};
api.setFeaturedStory = (storyId) => api.post(`/stories/${storyId}/set-featured`);

// Character-related methods
api.getCharacters = () => api.get('/characters');
api.getCharacter = (characterId) => api.get(`/characters/${characterId}`);
api.createCharacter = (characterData) => api.post('/characters', characterData);
api.updateCharacter = (characterId, characterData) => api.put(`/characters/${characterId}`, characterData);
api.deleteCharacter = (characterId) => api.delete(`/characters/${characterId}`);
api.regenerateAvatar = (characterId) => api.post(`/characters/${characterId}/regenerate-avatar`);
api.updateStorySummary = (characterId, storyId, storyContent) => 
  api.post(`/characters/${characterId}/update-story-summary`, { storyId, storyContent });

// Auth-related methods
api.checkStatus = () => api.get('/auth/status');
api.changePassword = (oldPassword, newPassword) => api.post('/auth/change-password', { oldPassword, newPassword });

// New auth-related methods
api.register = (name, email, password) => api.post('/auth/register', { name, email, password });
api.verifyEmail = (token) => api.get(`/auth/verify-email/${token}`);
api.forgotPassword = (email) => api.post('/auth/forgot-password', { email }, { params: { platform: 'web' } });
api.resetPassword = (token, newPassword) => api.post(`/auth/reset-password/${token}`, { password: newPassword });
api.resendVerificationEmail = (email) => api.post('/auth/resend-verification-email', { email });

// Helper function to get the full URL for static files
const getStaticFileUrl = (path, type) => {
  if (!path) return '/placeholder-avatar.jpg';
  const formattedPath = path.startsWith('/') ? path : `/${path}`;
  const fullUrl = formattedPath.includes(type) 
    ? `${SERVER_BASE_URL}${formattedPath}`
    : `${SERVER_BASE_URL}/${type}${formattedPath}`;
  return fullUrl;
};

// Helper function to get the full URL for narration files
export const getNarrationUrl = (narrationPath) => getStaticFileUrl(narrationPath, 'narrations');

// Helper function to get the full URL for avatar images
export const getAvatarUrl = (avatarPath) => getStaticFileUrl(avatarPath, 'avatars');

// Helper function to get the full URL for thumbnail images
export const getThumbnailUrl = (thumbnailPath) => getStaticFileUrl(thumbnailPath, 'thumbnails');

// Helper function to get the server's base URL (without '/api')
export const getServerBaseUrl = () => {
  return SERVER_BASE_URL;
};

// Function to check if a URL is accessible
export const checkUrlAccessibility = async (url) => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    console.error(`Error checking URL accessibility for ${url}:`, error);
    return false;
  }
};

// Admin-related API calls
api.getAllUsers = () => api.get('/admin/users');
api.getUserById = (userId) => api.get(`/admin/users/${userId}`);
api.updateUser = (userId, userData) => api.put(`/admin/users/${userId}`, userData);
api.deleteUser = (userId) => api.delete(`/admin/users/${userId}`);
api.createUser = (userData) => api.post('/admin/users', userData);
api.resetUserPassword = (userId) => api.post(`/admin/users/${userId}/reset-password`);
api.deactivateUser = (userId, reason) => api.post(`/admin/users/${userId}/deactivate`, { reason });
api.activateUser = (userId) => api.post(`/admin/users/${userId}/activate`);
api.changeUserRole = (userId, role) => api.post(`/admin/users/${userId}/change-role`, { role });
api.getUserContent = (userId) => api.get(`/admin/users/${userId}/content`);
api.adminGetAllCharacters = () => api.get('/admin/characters');
api.adminGetCharacterById = (characterId) => api.get(`/admin/characters/${characterId}`);
api.adminDeleteCharacter = (characterId) => api.delete(`/admin/characters/${characterId}`);
api.adminDeleteStory = (storyId) => api.delete(`/admin/stories/${storyId}`);
api.adminVerifyEmail = (userId) => api.post(`/auth/admin/verify-email/${userId}`);
api.adminUnverifyEmail = (userId) => api.post(`/auth/admin/unverify-email/${userId}`);

// Add the new method for fetching story limit information
api.getStoryLimitInfo = async () => {
  try {
    const response = await api.get('/stories/limit-info');
    return response.data;
  } catch (error) {
    console.error('Error fetching story limit info:', error);
    throw error;
  }
};

// Add this new method to fetch price IDs
api.getPriceIds = () => api.get('/subscriptions/price-ids');

// Public story and favorite-related methods
api.getPublicStories = (page = 1) => api.get('/public/stories', { params: { page } });
api.getPublicStory = (storyId) => api.get(`/public/stories/${storyId}`);
api.getFavoriteStories = () => api.get('/public/stories/favorites/list');
api.addToFavorites = (storyId) => api.post(`/public/stories/${storyId}/favorite`);
api.removeFromFavorites = (storyId) => api.delete(`/public/stories/${storyId}/favorite`);

// Playlist-related methods
api.getPlaylists = () => api.get('/playlists');
api.createPlaylist = (name) => api.post('/playlists', { name });
api.deletePlaylist = (playlistId) => api.delete(`/playlists/${playlistId}`);
api.addToPlaylist = (playlistId, storyId) => api.post(`/playlists/${playlistId}/stories`, { storyId });
api.removeFromPlaylist = (playlistId, storyId) => api.delete(`/playlists/${playlistId}/stories/${storyId}`);
api.reorderPlaylist = (playlistId, startIndex, endIndex, isSystem = false, type = null) => {
  // For system playlists, use the system endpoint
  if (isSystem && type) {
    return api.put(`/playlists/system/${type}/reorder`, { startIndex, endIndex });
  }
  return api.put(`/playlists/${playlistId}/reorder`, { startIndex, endIndex });
};

// Queue-related methods
api.getQueue = () => api.get('queue');
api.updateQueue = (queue) => api.post('queue', { queue });
api.getQueueHistory = () => api.get('queue/history');
api.updateQueueHistory = (history) => api.post('queue/history', { history });

export default api;
