import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6, mt: 'auto' }}>
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} StoriesNow. All rights reserved.
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
          <Link component={RouterLink} to="/terms" color="inherit" sx={{ mr: 2 }}>
            Terms of Use
          </Link>
          <Link component={RouterLink} to="/privacy" color="inherit" sx={{ mr: 2 }}>
            Privacy Policy
          </Link>
          <Link component={RouterLink} to="/contact" color="inherit">
            Contact Us
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
