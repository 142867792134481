import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Alert } from '@mui/material';
import api from '../../services/api';
import ProgressDialog from '../ProgressDialog';
import StoryCounter from '../StoryCounter';
import { StyledPaper } from './styles';
import ClonerForm from './ClonerForm';
import useStoryCloneWebSocket from '../../hooks/useStoryCloneWebSocket';

const StoryCloner = () => {
  const [title, setTitle] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [fullPrompt, setFullPrompt] = useState('');
  const [length, setLength] = useState('');
  const [genre, setGenre] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [characters, setCharacters] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  
  const { id } = useParams();
  const navigate = useNavigate();

  const handleProgress = (data) => {
    console.log('Story progress:', data.progress);
    setProgressMessage(data.progress);
  };

  const handleComplete = (data) => {
    console.log('Story complete:', data.story);
    setLoading(false);
    setProgressDialogOpen(false);
    navigate(`/view/${data.story._id}`);
  };

  const handleError = (data) => {
    console.error('Story creation error:', data.error);
    setError(`Failed to clone story: ${data.error}`);
    setLoading(false);
    setProgressDialogOpen(false);
  };

  const { cloneStory } = useStoryCloneWebSocket({
    onProgress: handleProgress,
    onComplete: handleComplete,
    onError: handleError
  });

  const fetchStoryAndCharacters = useCallback(async () => {
    try {
      const [storyResponse, charactersResponse] = await Promise.all([
        api.get(`/stories/${id}`),
        api.getCharacters()
      ]);
      const storyData = storyResponse.data;
      setTitle(`${storyData.title}`);
      setFullPrompt(storyData.prompt);
      
      const userPromptMatch = storyData.prompt.match(/Story Prompt: (.+)$/m);
      if (userPromptMatch && userPromptMatch[1]) {
        setUserPrompt(userPromptMatch[1]);
      }
      
      setLength(storyData.length);
      setGenre(storyData.genre);
      setSelectedCharacters(storyData.characters);
      setCharacters(charactersResponse.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch story and characters. Please try again.');
      setLoading(false);
      console.error('Error fetching story and characters:', err.response ? err.response.data : err.message);
    }
  }, [id]);

  useEffect(() => {
    fetchStoryAndCharacters();
  }, [fetchStoryAndCharacters]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setProgressDialogOpen(true);
    setProgressMessage('Initializing story cloning...');

    try {
      const updatedFullPrompt = fullPrompt.replace(/Story Prompt: .+$/m, `Story Prompt: ${userPrompt}`);
      await cloneStory({
        prompt: updatedFullPrompt,
        title,
        length,
        genre,
        characters: selectedCharacters.map(char => char._id)
      });
    } catch (err) {
      setLoading(false);
      setProgressDialogOpen(false);
      const errorMessage = err.message || 'An unknown error occurred';
      setError(`Failed to clone story: ${errorMessage}`);
      console.error('Error cloning story:', err);
    }
  };

  const handleCancel = () => {
    navigate(`/view/${id}`);
  };

  const handleCharacterSelect = (event) => {
    const selectedIds = event.target.value;
    const selectedChars = characters.filter(char => selectedIds.includes(char._id));
    setSelectedCharacters(selectedChars);
  };

  if (error && !loading) {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mb: 2 }}>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{error}</pre>
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Clone Story
        </Typography>

        <Box sx={{ mt: 4, width: '100%' }}>
          <StoryCounter />
        </Box>
        
        <ClonerForm
          title={title}
          setTitle={setTitle}
          userPrompt={userPrompt}
          setUserPrompt={setUserPrompt}
          length={length}
          setLength={setLength}
          genre={genre}
          setGenre={setGenre}
          characters={characters}
          selectedCharacters={selectedCharacters}
          handleCharacterSelect={handleCharacterSelect}
          handleCancel={handleCancel}
          loading={loading}
          error={error}
          onSubmit={handleSubmit}
        />
      </StyledPaper>

      <ProgressDialog 
        open={progressDialogOpen} 
        progress={progressMessage}
      />
    </Container>
  );
};

export default StoryCloner;
