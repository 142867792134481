import websocketService from '../../services/websocketService';
import {
  setCurrentStory,
  setQueue,
  setPlaylists,
  setPlaybackState,
  setSystemPlaylist,
  updateSystemPlaylist
} from '../slices/audioPlayerSlice';

export const websocketMiddleware = store => next => action => {
  const result = next(action);
  const state = store.getState().audioPlayer;

  // Handle outgoing sync based on actions
  switch (action.type) {
    case 'audioPlayer/setPlaybackState':
      websocketService.emit('playbackAction', {
        action: state.playback.isPlaying ? 'play' : 'pause',
        storyId: state.currentStory?._id,
        isPublic: state.currentStory?.isPublic,
        currentTime: state.playback.currentTime
      });
      break;

    case 'audioPlayer/setQueue':
    case 'audioPlayer/addToQueue':
    case 'audioPlayer/removeFromQueue':
      websocketService.emit('queueAction', {
        action: 'update',
        queue: state.queue.map(story => ({
          ...story,
          isPublic: story.fromSystemPlaylist ? true : story.isPublic
        }))
      });
      break;

    case 'audioPlayer/setPlaylists':
    case 'audioPlayer/addPlaylist':
    case 'audioPlayer/updatePlaylist':
    case 'audioPlayer/removePlaylist':
      websocketService.emit('playlistAction', {
        action: action.type.split('/')[1],
        playlist: {
          ...action.payload,
          stories: action.payload.stories?.map(story => ({
            ...story,
            isPublic: action.payload.isSystem && action.payload.type === 'publicFavorites' ? true : story.isPublic
          }))
        },
        isSystem: action.payload.isSystem,
        type: action.payload.type
      });
      break;

    case 'audioPlayer/updateSystemPlaylist':
      websocketService.emit('systemPlaylistAction', {
        type: action.payload.type,
        action: action.payload.actionType,
        storyId: action.payload.story._id,
        isPublic: true // System playlists only contain public stories
      });
      break;

    case 'audioPlayer/storyVisibilityChanged':
      websocketService.emit('storyVisibilityChanged', {
        storyId: action.payload.storyId,
        visibility: action.payload.visibility
      });
      break;

    default:
      break;
  }

  return result;
};

// Setup WebSocket listeners
export const setupWebSocketListeners = store => {
  websocketService.on('playbackStatus', ({ isPlaying, currentTime, storyId, isPublic }) => {
    store.dispatch(setPlaybackState({ isPlaying, currentTime }));
    if (storyId !== store.getState().audioPlayer.currentStory?._id) {
      store.dispatch(setCurrentStory({ _id: storyId, isPublic }));
    }
  });

  websocketService.on('queueUpdated', ({ queue }) => {
    // Preserve isPublic flag when updating queue
    const queueWithFlags = queue.map(story => ({
      ...story,
      isPublic: story.fromSystemPlaylist ? true : story.isPublic
    }));
    store.dispatch(setQueue(queueWithFlags));
  });

  websocketService.on('playlistUpdated', (update) => {
    // Preserve isPublic flag when updating playlists
    if (update.playlist) {
      update.playlist.stories = update.playlist.stories?.map(story => ({
        ...story,
        isPublic: update.playlist.isSystem && update.playlist.type === 'publicFavorites' ? true : story.isPublic
      }));
    }
    store.dispatch(setPlaylists(update.playlists));
  });

  websocketService.on('systemPlaylistUpdated', (update) => {
    // Force isPublic to true for system playlist stories
    if (update.playlist) {
      update.playlist.stories = update.playlist.stories?.map(story => ({
        ...story,
        isPublic: true
      }));
      
      // If this is a reorder action, update the playlist in the store
      if (update.action === 'reorder') {
        store.dispatch(setSystemPlaylist({
          type: update.type,
          playlist: update.playlist
        }));
      }
    }
  });

  websocketService.on('storyVisibilityChanged', ({ storyId, visibility }) => {
    const state = store.getState().audioPlayer;

    // Remove private stories from queue
    if (visibility === 'private') {
      const newQueue = state.queue.filter(story => story._id !== storyId);
      if (newQueue.length !== state.queue.length) {
        store.dispatch(setQueue(newQueue));
      }

      // Remove from system playlists
      if (state.systemPlaylists.publicFavorites?.stories.some(s => s._id === storyId)) {
        store.dispatch(updateSystemPlaylist({
          type: 'publicFavorites',
          actionType: 'remove',
          story: { _id: storyId }
        }));
      }
    }
  });
};
