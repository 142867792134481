import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Card,
  CardMedia,
  Box,
  Typography
} from '@mui/material';
import { getServerBaseUrl } from '../../services/api';

const StoryPreviewDialog = ({ open, onClose, story }) => {
  if (!story) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Story Created Successfully</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your story "{story.title}" has been created. Here's a preview of the generated thumbnail:
        </DialogContentText>
        <Card sx={{ maxWidth: '100%', mt: 2 }}>
          <CardMedia
            component="img"
            height="300"
            image={story.thumbnailUrl ? `${getServerBaseUrl()}${story.thumbnailUrl}` : ''}
            alt={story.title}
          />
        </Card>
        {story.narrationUrl && (
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>Narration Preview:</Typography>
            <audio controls style={{ width: '100%' }}>
              <source src={`${getServerBaseUrl()}${story.narrationUrl}`} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          View Full Story
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StoryPreviewDialog;
