import io from 'socket.io-client';
import EventEmitter from 'events';

/**
 * @typedef {Object} ConnectionState
 * @property {'connected'|'disconnected'|'error'|'failed'} state - Current connection state
 * @property {'excellent'|'good'|'fair'|'poor'|'unknown'} quality - Connection quality
 * @property {number} latency - Current latency in milliseconds
 */

/**
 * @typedef {Object} StoryProgress
 * @property {string} storyId - ID of the story being processed
 * @property {number} progress - Progress percentage (0-100)
 * @property {string} stage - Current processing stage
 */

/**
 * @typedef {Object} StoryComplete
 * @property {string} storyId - ID of the completed story
 * @property {Object} story - Complete story data
 */

/**
 * @typedef {Object} StoryError
 * @property {string} storyId - ID of the story with error
 * @property {string} error - Error message
 */

/**
 * @typedef {Object} EmailVerificationChange
 * @property {string} userId - ID of the user
 * @property {boolean} isVerified - New verification status
 */

class WebSocketService extends EventEmitter {
    constructor() {
        super();
        this.socket = null;
        this.connectionState = 'disconnected';
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 50;
        this.eventBuffer = [];
        this.lastLatency = 0;
        this.connectionQuality = 'unknown';
        this.latencyThreshold = 200;
        this.latencyCheckInterval = null;
        this.connectionMonitorInterval = null;
        this.eventHandlers = new Map();
    }

    getWebSocketUrl() {
        const { protocol, hostname, port } = window.location;
        const wsProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
        
        if (hostname === 'localhost' || hostname === '127.0.0.1') {
            return `${wsProtocol}//${hostname}:${process.env.REACT_APP_BACKEND_PORT || '8000'}`;
        }
        if (hostname === '192.168.1.161') {
            return `${wsProtocol}//${hostname}:${process.env.REACT_APP_BACKEND_PORT || '8000'}`;
        }
        if (['storycraft.geekpoise.com', 'storiesnow.co', 'www.storiesnow.co', 
             'storiesnow.app', 'www.storiesnow.app'].includes(hostname)) {
            return `${wsProtocol}//${hostname}`;
        }
        
        return `${wsProtocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    /**
     * Connects to the WebSocket server with authentication
     * @throws {Error} If no authentication token is available
     */
    connect() {
        if (this.socket?.connected) {
            console.warn('WebSocket is already connected');
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No authentication token available');
            return;
        }

        const wsUrl = this.getWebSocketUrl();
        console.log('Connecting to WebSocket URL:', wsUrl);

        this.socket = io(wsUrl, {
            path: '/socket.io',
            query: { token },
            reconnection: true,
            reconnectionAttempts: this.maxReconnectAttempts,
            reconnectionDelay: this.getReconnectionDelay(),
            reconnectionDelayMax: 10000,
            randomizationFactor: 0.5,
            timeout: 20000,
            autoConnect: false,
            transports: ['websocket'],
            upgrade: false
        });

        this.setupEventListeners();
        this.startConnectionMonitoring();
        this.socket.connect();
    }

    setupEventListeners() {
        if (!this.socket) return;

        // Connection events
        this.socket.on('connect', () => {
            console.log('WebSocket connected successfully');
            this.connectionState = 'connected';
            this.reconnectAttempts = 0;
            this.processEventBuffer();
            this.emit('connectionStateChanged', 'connected');
            this.startLatencyChecks();
        });

        this.socket.on('disconnect', (reason) => {
            console.log('WebSocket disconnected:', reason);
            this.connectionState = 'disconnected';
            this.stopLatencyChecks();
            this.emit('connectionStateChanged', 'disconnected');
        });

        this.socket.on('connect_error', (error) => {
            console.error('WebSocket connection error:', error);
            this.connectionState = 'error';
            this.emit('connectionStateChanged', 'error');
            this.handleConnectionError(error);
        });

        // Story-related events
        this.socket.on('storyProgress', (data) => this.emit('storyProgress', data));
        this.socket.on('storyComplete', (data) => this.emit('storyComplete', data));
        this.socket.on('storyError', (data) => this.emit('storyError', data));

        // Email verification events
        this.socket.on('emailVerificationChanged', (data) => this.emit('emailVerificationChanged', data));
        this.socket.on('emailVerified', (data) => this.emit('emailVerified', data));

        // Audio player events
        this.socket.on('playbackStatus', (data) => this.emit('playbackStatus', data));
        this.socket.on('queueUpdated', (data) => this.emit('queueUpdated', data));
        this.socket.on('playlistUpdated', (data) => this.emit('playlistUpdated', data));

        // Latency check response
        this.socket.on('pong', (latency) => {
            this.lastLatency = latency;
            this.updateConnectionQuality(latency);
        });
    }

    /**
     * Emits a playback action event
     * @param {Object} data - Playback action data
     */
    emitPlaybackAction(data) {
        if (!this.socket?.connected) {
            if (this.bufferEvent('playbackAction', data)) {
                console.warn('WebSocket is not connected. Playback action will be retried when connection is restored.');
            }
            return;
        }
        this.socket.emit('playbackAction', data);
    }

    /**
     * Emits a queue action event
     * @param {Object} data - Queue action data
     */
    emitQueueAction(data) {
        if (!this.socket?.connected) {
            if (this.bufferEvent('queueAction', data)) {
                console.warn('WebSocket is not connected. Queue action will be retried when connection is restored.');
            }
            return;
        }
        this.socket.emit('queueAction', data);
    }

    /**
     * Emits a playlist action event
     * @param {Object} data - Playlist action data
     */
    emitPlaylistAction(data) {
        if (!this.socket?.connected) {
            if (this.bufferEvent('playlistAction', data)) {
                console.warn('WebSocket is not connected. Playlist action will be retried when connection is restored.');
            }
            return;
        }
        this.socket.emit('playlistAction', data);
    }

    /**
     * Creates a new story using WebSocket
     * @param {Object} storyData - Story creation data
     * @returns {Promise<Object>} Created story data
     * @throws {Error} If story creation fails
     */
    createStory(storyData) {
        return new Promise((resolve, reject) => {
            if (!this.socket?.connected) {
                if (this.bufferEvent('createStory', storyData)) {
                    reject(new Error('WebSocket is not connected. Story creation will be retried when connection is restored.'));
                }
                return;
            }

            const timeout = setTimeout(() => {
                cleanup();
                reject(new Error('Story creation timeout'));
            }, 300000); // 5 minutes timeout

            const cleanup = () => {
                this.socket.off('storyComplete', handleComplete);
                this.socket.off('storyError', handleError);
                clearTimeout(timeout);
            };

            const handleComplete = (data) => {
                cleanup();
                resolve(data.story);
            };

            const handleError = (data) => {
                cleanup();
                reject(new Error(data.error));
            };

            this.socket.on('storyComplete', handleComplete);
            this.socket.on('storyError', handleError);
            this.socket.emit('createStory', storyData);
        });
    }

    /**
     * Emits an email verification change event
     * @param {string} userId - User ID
     * @param {boolean} isVerified - New verification status
     */
    emitEmailVerificationChange(userId, isVerified) {
        if (!this.socket?.connected) {
            if (this.bufferEvent('emailVerificationChanged', { userId, isVerified })) {
                console.warn('WebSocket is not connected. Email verification change will be retried when connection is restored.');
            }
            return;
        }
        this.socket.emit('emailVerificationChanged', { userId, isVerified });
    }

    /**
     * Subscribes to WebSocket events
     * @param {string} eventName - Name of the event to subscribe to
     * @param {Function} handler - Event handler function
     */
    on(eventName, handler) {
        if (!handler || typeof handler !== 'function') {
            console.error(`Invalid handler provided for event ${eventName}`);
            return;
        }

        if (!this.eventHandlers.has(eventName)) {
            this.eventHandlers.set(eventName, new Set());
        }
        this.eventHandlers.get(eventName).add(handler);

        super.on(eventName, handler);
    }

    /**
     * Unsubscribes from WebSocket events
     * @param {string} eventName - Name of the event to unsubscribe from
     * @param {Function} handler - Event handler function to remove
     */
    off(eventName, handler) {
        if (!handler || typeof handler !== 'function') {
            console.error(`Invalid handler provided for event ${eventName}`);
            return;
        }

        super.off(eventName, handler);

        if (this.eventHandlers.has(eventName)) {
            this.eventHandlers.get(eventName).delete(handler);
            if (this.eventHandlers.get(eventName).size === 0) {
                this.eventHandlers.delete(eventName);
            }
        }
    }

    /**
     * Removes all event listeners
     * @param {string} [eventName] - Optional event name to remove listeners for
     */
    removeAllListeners(eventName) {
        if (eventName) {
            super.removeAllListeners(eventName);
            this.eventHandlers.delete(eventName);
        } else {
            super.removeAllListeners();
            this.eventHandlers.clear();
        }
    }

    startConnectionMonitoring() {
        if (this.connectionMonitorInterval) {
            clearInterval(this.connectionMonitorInterval);
        }

        this.connectionMonitorInterval = setInterval(() => {
            if (this.socket?.connected) {
                this.checkConnection();
            }
        }, 30000);
    }

    startLatencyChecks() {
        if (this.latencyCheckInterval) {
            clearInterval(this.latencyCheckInterval);
        }

        this.latencyCheckInterval = setInterval(() => {
            if (this.socket?.connected) {
                const start = Date.now();
                this.socket.emit('ping', () => {
                    const latency = Date.now() - start;
                    this.updateConnectionQuality(latency);
                });
            }
        }, 10000);
    }

    stopLatencyChecks() {
        if (this.latencyCheckInterval) {
            clearInterval(this.latencyCheckInterval);
            this.latencyCheckInterval = null;
        }
    }

    updateConnectionQuality(latency) {
        const previousQuality = this.connectionQuality;
        
        if (latency < 100) {
            this.connectionQuality = 'excellent';
        } else if (latency < 200) {
            this.connectionQuality = 'good';
        } else if (latency < 300) {
            this.connectionQuality = 'fair';
        } else {
            this.connectionQuality = 'poor';
        }

        if (previousQuality !== this.connectionQuality) {
            this.emit('connectionQualityChanged', this.connectionQuality);
        }
    }

    checkConnection() {
        if (!this.socket?.connected) return;

        const start = Date.now();
        this.socket.emit('ping', () => {
            const latency = Date.now() - start;
            if (latency > this.latencyThreshold) {
                console.warn(`High latency detected: ${latency}ms`);
                this.emit('highLatency', latency);
            }
        });
    }

    getReconnectionDelay() {
        const baseDelay = 1000;
        const maxDelay = 10000;
        const exponentialDelay = Math.min(
            baseDelay * Math.pow(2, this.reconnectAttempts),
            maxDelay
        );
        const jitter = exponentialDelay * 0.2 * (Math.random() * 2 - 1);
        return exponentialDelay + jitter;
    }

    handleConnectionError(error) {
        this.reconnectAttempts++;
        console.error(`Connection error (attempt ${this.reconnectAttempts}):`, error);

        if (this.reconnectAttempts > this.maxReconnectAttempts) {
            console.error('Max reconnection attempts reached');
            this.emit('connectionStateChanged', 'failed');
            return;
        }

        if (error.message.includes('Authentication')) {
            this.emit('authenticationError');
        }
    }

    bufferEvent(eventName, data) {
        if (this.connectionState !== 'connected') {
            this.eventBuffer.push({ eventName, data, timestamp: Date.now() });
            return false;
        }
        return true;
    }

    processEventBuffer() {
        const now = Date.now();
        const validEvents = this.eventBuffer.filter(
            event => now - event.timestamp < 300000 // Events not older than 5 minutes
        );

        validEvents.forEach(event => {
            this.socket.emit(event.eventName, event.data);
        });

        this.eventBuffer = [];
    }

    /**
     * Disconnects from the WebSocket server and cleans up resources
     */
    disconnect() {
        this.stopLatencyChecks();
        if (this.connectionMonitorInterval) {
            clearInterval(this.connectionMonitorInterval);
            this.connectionMonitorInterval = null;
        }
        
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
        
        this.connectionState = 'disconnected';
        this.emit('connectionStateChanged', 'disconnected');
    }

    /**
     * Gets the current connection state
     * @returns {ConnectionState} Current connection state
     */
    getConnectionState() {
        return {
            state: this.connectionState,
            quality: this.connectionQuality,
            latency: this.lastLatency,
        };
    }
}

// Create a singleton instance
const websocketService = new WebSocketService();
export default websocketService;
