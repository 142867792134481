import React, { useState } from 'react';
import { 
  Box,
  InputLabel,
  MenuItem,
  Chip,
  Avatar,
  Grid,
  Alert
} from '@mui/material';
import { getAvatarUrl } from '../../services/api';
import {
  StyledTextField,
  StyledFormControl,
  StyledSelect,
  StyledButton
} from './styles';

const ClonerForm = ({
  title,
  setTitle,
  userPrompt,
  setUserPrompt,
  length,
  setLength,
  genre,
  setGenre,
  characters,
  selectedCharacters,
  handleCharacterSelect,
  handleCancel,
  loading,
  error,
  onSubmit
}) => {
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) {
      errors.title = 'Title is required';
    }
    if (!userPrompt.trim()) {
      errors.prompt = 'Prompt is required';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(e);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{error}</pre>
        </Alert>
      )}

      <StyledTextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
        margin="normal"
        error={!!validationErrors.title}
        helperText={validationErrors.title}
      />

      <StyledTextField
        fullWidth
        label="Prompt"
        value={userPrompt}
        onChange={(e) => setUserPrompt(e.target.value)}
        required
        margin="normal"
        multiline
        rows={3}
        error={!!validationErrors.prompt}
        helperText={validationErrors.prompt}
      />

      <StyledFormControl fullWidth margin="normal">
        <InputLabel>Story Length</InputLabel>
        <StyledSelect
          value={length}
          onChange={(e) => setLength(e.target.value)}
          label="Story Length"
        >
          <MenuItem value="short">Short</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="long">Long</MenuItem>
        </StyledSelect>
      </StyledFormControl>

      <StyledFormControl fullWidth margin="normal">
        <InputLabel>Genre</InputLabel>
        <StyledSelect
          value={genre}
          onChange={(e) => setGenre(e.target.value)}
          label="Genre"
        >
          <MenuItem value="fantasy">Fantasy</MenuItem>
          <MenuItem value="scifi">Science Fiction</MenuItem>
          <MenuItem value="mystery">Mystery</MenuItem>
          <MenuItem value="adventure">Adventure</MenuItem>
          <MenuItem value="spooky">Spooky</MenuItem>
          <MenuItem value="romance">Romance</MenuItem>
          <MenuItem value="historical fiction">Historical Fiction</MenuItem>
          <MenuItem value="funny">Funny</MenuItem>
          <MenuItem value="western">Western</MenuItem>
          <MenuItem value="fairytale">Fairytale</MenuItem>
          <MenuItem value="mythology">Mythology</MenuItem>
          <MenuItem value="superhero">Superhero</MenuItem>
          <MenuItem value="real life">Real Life</MenuItem>
        </StyledSelect>
      </StyledFormControl>

      <StyledFormControl fullWidth margin="normal">
        <InputLabel>Characters</InputLabel>
        <StyledSelect
          multiple
          value={selectedCharacters.map(char => char._id)}
          onChange={handleCharacterSelect}
          label="Characters"
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedCharacters.map((char) => (
                <Chip
                  key={char._id}
                  label={char.name}
                  avatar={<Avatar alt={char.name} src={getAvatarUrl(char.avatarUrl)} />}
                />
              ))}
            </Box>
          )}
        >
          {characters.map((char) => (
            <MenuItem key={char._id} value={char._id}>
              {char.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <StyledButton
            onClick={handleCancel}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Cancel
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
          >
            Clone Story
          </StyledButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClonerForm;
