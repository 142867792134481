import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  margin: 0,
}));

const TermsOfUse = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <StyledPaper elevation={3}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          StoriesNow Terms of Use
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph align="center">
          Last Updated: {new Date().toLocaleDateString()}
        </Typography>
        
        <Section title="Introduction">
          <Typography variant="body1" paragraph>
            Welcome to StoriesNow, your premier platform for crafting engaging and immersive stories. These Terms of Use ("Terms") govern your access to and use of the StoriesNow website ("Website"), including any related services provided by us. By using our Website, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using our Website.
          </Typography>
        </Section>

        <Section title="Eligibility">
          <Typography variant="body1" paragraph>
            You must be at least 13 years old to use our Website. By accessing our services, you confirm that you meet the age requirement and have the authority to accept these Terms.
          </Typography>
        </Section>

        <Section title="Account Registration">
          <Typography variant="body1" paragraph>
            Some features of our Website may require you to create an account. You agree to provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Please notify us immediately if you suspect any unauthorized use of your account.
          </Typography>
        </Section>

        <Section title="License Grant and Restrictions">
          <Typography variant="body1" paragraph>
            Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and use our Website for non-commercial purposes.
          </Typography>
          <Typography variant="body1" paragraph>
            You agree not to:
          </Typography>
          <ul>
            <li>Copy, modify, or create derivative works of our Website.</li>
            <li>Reverse engineer, decompile, or disassemble our Website.</li>
            <li>Remove or alter any copyright, trademark, or proprietary rights notices on our Website.</li>
            <li>Sell, rent, lease, or transfer your rights in our Website to any third party.</li>
            <li>Use our Website for any unlawful or unauthorized purpose.</li>
            <li>Use our Website in a manner that violates any applicable laws or regulations.</li>
          </ul>
        </Section>

        <Section title="Content and Intellectual Property">
          <SubSection title="Definition of Content">
            <Typography variant="body1" paragraph>
              Input: Any material or data you submit to the Service.
              Output: The resulting creation or product generated by our Services based on your Input.
              Together, Input and Output are referred to as "Content".
            </Typography>
          </SubSection>

          <SubSection title="Ownership of Content for Premium Users">
            <Typography variant="body1" paragraph>
              If you subscribe to a paid tier, you retain full ownership, including copyright and all related intellectual property rights, of the stories you generate.
              Stories you choose to keep private on StoriesNow will only be accessed by us for service delivery purposes.
              If you make your stories public, you grant StoriesNow a perpetual, global license to display, adapt, distribute, and use the content for service enhancement, promotion, and other related purposes. Other users can also interact with your public content within the functionalities allowed by the platform.
            </Typography>
          </SubSection>

          <SubSection title="Ownership of Content for Free Tier Users">
            <Typography variant="body1" paragraph>
              For free tier users, ownership of the Content (both Input and Output) resides with StoriesNow.
              All stories generated are private by default, but you can choose to make them public for other users and share stories via a link.
              If you choose to make your stories public, StoriesNow may display, adapt, distribute, and use the content for service enhancement, promotion, and other related purposes. Other users can also interact with your public content within the functionalities allowed by the platform.
              Free users receive a global, non-exclusive, royalty-free license to access and use their generated content for personal purposes.
            </Typography>
          </SubSection>

          <SubSection title="Service Delivery and Compliance">
            <Typography variant="body1" paragraph>
              Regardless of your subscription type, StoriesNow retains the right to access and use your Content to ensure smooth service delivery, comply with legal obligations, and enforce our terms and policies. This right persists even if our agreement is terminated.
            </Typography>
          </SubSection>

          <SubSection title="Uniqueness Concerns">
            <Typography variant="body1" paragraph>
              Due to the nature of AI and machine learning, the Output may sometimes resemble creations of other users. It's possible for StoriesNow or other users to generate similar or identical content.
            </Typography>
          </SubSection>

          <SubSection title="Enhancing Our Services">
            <Typography variant="body1" paragraph>
              By using StoriesNow, you agree that we can use your Content to refine and enhance our Services. If you enable privacy settings on the Generations page, your Input and Output will be excluded from this use.
            </Typography>
          </SubSection>

          <SubSection title="Advertising and Promotion">
            <Typography variant="body1" paragraph>
              If you choose to make your content public, we obtain a non-exclusive license to use your Content for advertising and promotional activities, including featuring it on our Website.
            </Typography>
          </SubSection>

          <SubSection title="Visibility of Public Content">
            <Typography variant="body1" paragraph>
              Any Output that you make public may be viewed and potentially used by StoriesNow or its community in ways that might transform or derive from the original.
            </Typography>
          </SubSection>

          <SubSection title="Sharing Content">
            <Typography variant="body1" paragraph>
              You are permitted to share the content you create on social media platforms. However, you are solely responsible for ensuring that the content you share complies with the laws applicable in your region and does not violate these Terms. Sharing content that infringes upon the rights of others or violates any laws or these Terms may lead to termination of your account.
            </Typography>
          </SubSection>
        </Section>

        <Section title="Misuse of Services">
          <Typography variant="body1" paragraph>
            By using StoriesNow, you agree not to misuse our AI models, which generate text and images for stories. Misuse includes, but is not limited to:
          </Typography>
          <ul>
            <li>Creating or distributing images or text that could be perceived as disturbing, distressing, or offensive.</li>
            <li>Producing content that perpetuates stereotypes or bias, including demeaning or harmful representations of individuals, groups, cultures, or religions.</li>
            <li>Using the AI models to disseminate discriminatory content, harmful stereotypes, or impersonate individuals without their express consent.</li>
            <li>Sharing or creating content of a sexual nature without the consent of all potentially involved or affected individuals.</li>
            <li>Misusing the AI models to generate or distribute misleading or false information.</li>
            <li>Creating or sharing representations of extreme violence, gore, or any content that may cause emotional harm or distress.</li>
            <li>Sharing copyrighted or licensed material in violation of its terms of use or creating derivative content without adhering to specific terms.</li>
          </ul>
          <Typography variant="body1" paragraph>
            Violation of these provisions may result in immediate termination of your account and access to our services, and potential legal action. You agree to abide by these rules and acknowledge the potential consequences of violation.
          </Typography>
        </Section>

        <Section title="Subscription Plans">
          <Typography variant="body1" paragraph>
            We offer two subscription tiers to suit different user preferences:
          </Typography>
          <ul>
            <li>Fun Plan: This is our basic subscription plan, offering a set number of credits per month.</li>
            <li>Double the Fun Plan: This plan offers twice the number of credits as the Fun Plan, allowing for more extensive use of our services.</li>
          </ul>
          <SubSection title="Payment Processing">
            <Typography variant="body1" paragraph>
              All payments for our subscription plans are processed through Stripe on our Website. By selecting a subscription plan, you agree to abide by Stripe's terms and conditions, including their policies on payment processing, refunds, and dispute resolution. Any queries or concerns related to payment processing, refunds, or subscription management should be directed to our customer support or to Stripe as appropriate. Please ensure you understand and accept these terms before making any purchases.
            </Typography>
          </SubSection>
        </Section>

        <Section title="Content Filters and Adult Responsibility">
          <Typography variant="body1" paragraph>
            We employ content filters to ensure that stories and images generated within the Website are suitable for all ages. However, we cannot guarantee that all content will meet everyone's standards or be appropriate for all children. Adults are responsible for reviewing all stories produced by the Website before sharing them with children. If you encounter any content you believe is inappropriate or violates these Terms, please report it to us for review.
          </Typography>
        </Section>

        <Section title="Termination">
          <Typography variant="body1" paragraph>
            We reserve the right to revoke your access to the Website and delete your data without notice if you violate these Terms or if we reasonably believe that your actions may harm us, other users, or our services.
          </Typography>
        </Section>

        <Section title="Changes to Terms">
          <Typography variant="body1" paragraph>
            We may revise these Terms periodically. If we make any significant changes, we will notify you through the Website or by email. Your continued use of the Website after such changes are enacted signifies your acceptance of the revised Terms.
          </Typography>
        </Section>

        <Section title="Governing Law">
          <Typography variant="body1" paragraph>
            These Terms are governed by the laws of England and Wales, without regard to its conflict of law provisions. Any disputes arising from these Terms or your use of the Website shall be exclusively resolved by the courts located in England.
          </Typography>
        </Section>

        <Section title="Limitation of Liability">
          <Typography variant="body1" paragraph>
            In no event shall StoriesNow, its officers, directors, employees, agents, or licensors be liable for any damages, including but not limited to loss of profits, data, use, or goodwill, arising from your access to, use of, or inability to use the Website, to the maximum extent permitted by applicable law. This includes damages based on warranty, contract, tort (including negligence), or any other legal theory, regardless of whether we have been informed of such damages.
          </Typography>
        </Section>

        <Section title="Disclaimer of Warranties">
          <Typography variant="body1" paragraph>
            The Website is provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the Website will be error-free, that defects will be corrected, or that the Website or the servers that host it are free of viruses or other harmful components.
          </Typography>
        </Section>

        <Section title="Indemnity">
          <Typography variant="body1" paragraph>
            You agree to defend, indemnify, and hold harmless StoriesNow, its officers, directors, employees, agents, and licensors from any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Website.
          </Typography>
        </Section>

        <Section title="Severability">
          <Typography variant="body1" paragraph>
            If any provision of these Terms is deemed invalid, illegal, or unenforceable by a court of competent jurisdiction, the remaining provisions shall continue in full force and effect.
          </Typography>
        </Section>

        <Section title="Waiver">
          <Typography variant="body1" paragraph>
            No waiver of any provision of these Terms shall be considered a further or continuing waiver of such provision or any other provision. Our failure to assert any right or provision under these Terms does not constitute a waiver of such right or provision.
          </Typography>
        </Section>

        <Section title="Entire Agreement">
          <Typography variant="body1" paragraph>
            These Terms, along with any other agreements or policies incorporated by reference, represent the entire agreement between you and StoriesNow regarding your use of the Website and supersede any prior agreements or understandings, whether oral or written.
          </Typography>
        </Section>

        <Section title="Assignment">
          <Typography variant="body1" paragraph>
            You may not assign or transfer these Terms, by operation of law or otherwise, without our prior written consent. Any attempt to assign or transfer without such consent will be null and void. We may freely assign or transfer these Terms without restriction.
          </Typography>
        </Section>

        <Section title="Force Majeure">
          <Typography variant="body1" paragraph>
            We are not liable for any failure or delay in performing our obligations under these Terms due to circumstances beyond our reasonable control, including but not limited to acts of God, war, terrorism, civil unrest, strikes, labor disputes, accidents, or governmental actions.
          </Typography>
        </Section>

        <Section title="Third-Party Links">
          <Typography variant="body1" paragraph>
            The Website may contain links to third-party websites or services that are not owned or controlled by StoriesNow. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. By using the Website, you expressly release StoriesNow from any and all liability arising from your use of any third-party website or service.
          </Typography>
        </Section>

        <Section title="Privacy">
          <Typography variant="body1" paragraph>
            Your use of the Website is subject to our Privacy Policy, which is incorporated by reference into these Terms. By using the Website, you acknowledge that you have read and understand our Privacy Policy and consent to the collection, use, and sharing of your information as described therein.
          </Typography>
        </Section>

        <Section title="Notices">
          <Typography variant="body1" paragraph>
            All notices or other communications required or permitted under these Terms shall be in writing and shall be deemed given when delivered personally, sent by email, or posted on the Website.
          </Typography>
        </Section>

        <Section title="Headings">
          <Typography variant="body1" paragraph>
            The headings in these Terms are for convenience only and have no legal or contractual effect.
          </Typography>
        </Section>

        <Section title="Interpretation">
          <Typography variant="body1" paragraph>
            In these Terms, the words "include," "includes," and "including" shall be deemed to be followed by the words "without limitation."
          </Typography>
        </Section>
        
        <Section title="Contact">
          <Typography variant="body1" paragraph>
            For any questions or concerns regarding these Terms or our services, please contact us at termsandprivacy@storiesnow.app.
          </Typography>
        </Section>
      </StyledPaper>
    </Container>
  );
};

const Section = ({ title, children }) => (
  <Box mb={3}>
    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>
    {children}
  </Box>
);

const SubSection = ({ title, children }) => (
  <Box ml={2} mb={2}>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    {children}
  </Box>
);

export default TermsOfUse;
