import React, { useState, useEffect } from 'react';
import { Typography, LinearProgress, Box, Button, Paper, Grid, Tooltip, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { styled, useTheme } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: theme.palette.common.white,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  borderRadius: '10px',
  marginBottom: theme.spacing(2),
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 6,
  borderRadius: 3,
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '100%',
  borderRadius: 3,
  backgroundColor: 'transparent',
  '& .MuiLinearProgress-bar': {
    borderRadius: 3,
    backgroundColor: theme.palette.common.white,
  },
}));

const StoryCounter = () => {
  const [storyLimit, setStoryLimit] = useState(0);
  const [storiesCreated, setStoriesCreated] = useState(0);
  const [tierInfo, setTierInfo] = useState('');
  const [canUpgrade, setCanUpgrade] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchStoryLimitInfo();
  }, []);

  const fetchStoryLimitInfo = async () => {
    try {
      setLoading(true);
      const response = await api.getStoryLimitInfo();
      setStoryLimit(response.limit);
      setStoriesCreated(response.created);
      setTierInfo(response.tierInfo);
      setCanUpgrade(response.canUpgrade);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching story limit info:', err);
      setError('Failed to fetch story limit information.');
      setLoading(false);
    }
  };

  const handleUpgrade = () => {
    navigate('/subscription');
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const progress = (storiesCreated / storyLimit) * 100;

  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={8}>
          <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
            {tierInfo}
          </Typography>
          <ProgressWrapper>
            <StyledLinearProgress variant="determinate" value={progress} />
          </ProgressWrapper>
          <Typography variant={isMobile ? "caption" : "body2"}>
            Stories created: {storiesCreated} / {storyLimit}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} container justifyContent="center" alignItems="center">
          {canUpgrade ? (
            <Tooltip title="Upgrade to unlock more stories and features!">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUpgrade}
                startIcon={<StarIcon />}
                fullWidth
                size="small"
                sx={{ 
                  backgroundColor: 'white', 
                  color: '#FE6B8B',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  },
                }}
              >
                Upgrade Now
              </Button>
            </Tooltip>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <StarIcon fontSize="small" />
              <Typography variant={isMobile ? "caption" : "body2"} align="center">
                You're on our top tier!
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default StoryCounter;
