import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import api from '../services/api';
import websocketService from '../services/websocketService';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const logout = useCallback(async () => {
    console.log('Logout called in AuthContext');
    try {
      await api.post('/auth/logout');
      console.log('Logout request sent to server');
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setCurrentUser(null);
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
      websocketService.disconnect();
      console.log('Logout successful, user cleared');
      window.location.reload();
    }
  }, []);

  const fetchUserProfile = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, setting current user to null');
      setCurrentUser(null);
      setIsEmailVerified(false);
      setLoading(false);
      return;
    }

    try {
      console.log('Fetching user profile...');
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await api.get('/auth/profile');
      console.log('User profile fetched:', response.data);
      setCurrentUser(response.data);
      setIsEmailVerified(response.data.isEmailVerified);
      console.log('isEmailVerified set to:', response.data.isEmailVerified);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      if (error.response && error.response.status === 401) {
        console.log('Unauthorized access, logging out');
        logout();
      }
    } finally {
      setLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    console.log('AuthProvider useEffect triggered');
    fetchUserProfile();
  }, [fetchUserProfile]);

  const login = async (email, password) => {
    console.log('Login attempt in AuthContext:', { email, password: '******' });
    try {
      console.log('Sending login request to:', `${api.defaults.baseURL}/auth/login`);
      const response = await api.post('/auth/login', { email, password });
      console.log('Login response:', response.data);
      setCurrentUser(response.data.user);
      setIsEmailVerified(response.data.user.isEmailVerified);
      localStorage.setItem('token', response.data.token);
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      console.log('Login successful, user set:', response.data.user);
      // Refresh the page after successful login
      window.location.reload();
    } catch (error) {
      console.error('Login error in AuthContext:', error);
      console.error('Error response:', error.response);
      let errorMessage = 'An error occurred during login';
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
        console.error('Error response data:', error.response.data);
      } else if (error.message) {
        errorMessage = error.message;
      }
      throw new Error(errorMessage);
    }
  };

  const signup = async (name, email, password) => {
    try {
      const response = await api.post('/auth/register', { name, email, password });
      console.log('Signup response:', response.data);
      
      if (response.data.token && response.data.user) {
        setCurrentUser(response.data.user);
        setIsEmailVerified(response.data.user.isEmailVerified);
        localStorage.setItem('token', response.data.token);
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        console.log('Signup successful, user set:', response.data.user);
      }
      
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      throw new Error(error.response?.data?.message || 'Failed to sign up');
    }
  };

  const verifyEmail = async (token) => {
    try {
      console.log('Verifying email with token:', token);
      const response = await api.get(`/auth/verify-email/${token}`);
      console.log('Email verification response:', response.data);
      
      setCurrentUser(prevUser => {
        if (prevUser) {
          const updatedUser = { ...prevUser, isEmailVerified: true };
          console.log('Updating user after email verification:', updatedUser);
          return updatedUser;
        }
        return prevUser;
      });
      setIsEmailVerified(true);

      await fetchUserProfile();

      return response.data.message;
    } catch (error) {
      console.error('Email verification error:', error);
      throw new Error(error.response?.data?.message || 'Failed to verify email');
    }
  };

  const forgotPassword = async (email) => {
    try {
      const response = await api.post('/auth/forgot-password', { email });
      console.log('Forgot password response:', response.data);
      return response.data.message;
    } catch (error) {
      console.error('Forgot password error:', error);
      throw new Error(error.response?.data?.message || 'Failed to send password reset email');
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      const response = await api.post(`/auth/reset-password/${token}`, { password: newPassword });
      console.log('Reset password response:', response.data);
      return response.data.message;
    } catch (error) {
      console.error('Reset password error:', error);
      throw new Error(error.response?.data?.message || 'Failed to reset password');
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const response = await api.post('/auth/resend-verification-email', { email: currentUser.email });
      console.log('Resend verification email response:', response.data);
      return response.data.message;
    } catch (error) {
      console.error('Resend verification email error:', error);
      throw new Error(error.response?.data?.message || 'Failed to resend verification email');
    }
  };

  const handleEmailVerificationChanged = useCallback(({ userId, isVerified }) => {
    console.log('Received emailVerificationChanged event for userId:', userId, 'isVerified:', isVerified);
    if (currentUser && currentUser.id === userId) {
      console.log('Updating currentUser isEmailVerified status');
      setCurrentUser(prevUser => {
        const updatedUser = { ...prevUser, isEmailVerified: isVerified };
        console.log('Updated currentUser:', updatedUser);
        return updatedUser;
      });
      setIsEmailVerified(isVerified);
      setForceUpdate(prev => prev + 1);
    } else {
      console.log('UserId mismatch or currentUser not set. Current user:', currentUser);
    }
  }, [currentUser]);

  // WebSocket connection management using websocketService
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (currentUser && token) {
      websocketService.on('connect', () => {
        console.log('WebSocket connected successfully');
        setIsSocketConnected(true);
      });

      websocketService.on('disconnect', () => {
        console.log('WebSocket disconnected');
        setIsSocketConnected(false);
      });

      websocketService.on('emailVerificationChanged', handleEmailVerificationChanged);

      websocketService.connect();

      return () => {
        websocketService.off('connect');
        websocketService.off('disconnect');
        websocketService.off('emailVerificationChanged');
        websocketService.disconnect();
      };
    }
  }, [currentUser, handleEmailVerificationChanged]);

  useEffect(() => {
    if (currentUser) {
      console.log('Current user updated:', currentUser);
      setIsEmailVerified(currentUser.isEmailVerified);
    }
  }, [currentUser]);

  const createSubscription = async (paymentMethodId, priceId) => {
    try {
      const response = await api.createSubscription(paymentMethodId, priceId);
      setCurrentUser(prevUser => ({ ...prevUser, subscription: response.data.subscription }));
      return response.data;
    } catch (error) {
      console.error('Create subscription error:', error);
      throw new Error(error.response?.data?.message || 'Failed to create subscription');
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await api.post('/api/subscriptions/cancel');
      setCurrentUser(prevUser => ({ ...prevUser, subscription: null }));
      return response.data;
    } catch (error) {
      console.error('Cancel subscription error:', error);
      throw new Error(error.response?.data?.message || 'Failed to cancel subscription');
    }
  };

  const getSubscriptionStatus = async () => {
    try {
      const response = await api.get('/api/subscriptions/status');
      setCurrentUser(prevUser => ({ ...prevUser, subscription: response.data.subscription }));
      return response.data.subscription;
    } catch (error) {
      console.error('Get subscription status error:', error);
      throw new Error(error.response?.data?.message || 'Failed to get subscription status');
    }
  };

  const value = {
    currentUser,
    setCurrentUser,
    isEmailVerified,
    setIsEmailVerified,
    login,
    signup,
    logout,
    loading,
    fetchUserProfile,
    verifyEmail,
    forgotPassword,
    resetPassword,
    resendVerificationEmail,
    forceUpdate,
    isSocketConnected,
    websocketService,
    createSubscription,
    cancelSubscription,
    getSubscriptionStatus,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useEmailVerificationStatus() {
  const { isEmailVerified, forceUpdate, isSocketConnected } = useAuth();
  return [isEmailVerified, forceUpdate, isSocketConnected];
}
