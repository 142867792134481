import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyledButton } from './styles';
import { getNarrationUrl } from '../../services/api';

const NarrationSection = ({
  narrationUrl,
  handleRegenerateNarration,
  loading,
  isContentChanged,
  handleUpdateAudioTags,
  id
}) => {
  if (!narrationUrl) return null;

  return (
    <Box mb={3} width="100%">
      <Typography variant="h6" gutterBottom>Narration:</Typography>
      <audio controls style={{ width: '100%' }}>
        <source src={getNarrationUrl(narrationUrl)} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <Box mt={1}>
        <StyledButton
          onClick={handleUpdateAudioTags}
          variant="contained"
          color="secondary"
          disabled={loading}
          fullWidth
        >
          Update Audio Tags
        </StyledButton>
      </Box>
      {isContentChanged && (
        <Box mt={1}>
          <StyledButton
            onClick={handleRegenerateNarration}
            variant="contained"
            color="secondary"
            disabled={loading}
            fullWidth
          >
            Save and Regenerate Narration
          </StyledButton>
        </Box>
      )}
    </Box>
  );
};

export default NarrationSection;
