import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import api from '../../services/api';
import { useEmailVerificationStatus } from '../../contexts/AuthContext';
import EmailVerificationAlert from '../EmailVerificationAlert';
import { StyledPaper } from './styles';
import CharacterForm from './CharacterForm';
import CharacterPreview from './CharacterPreview';

const CharacterCreator = ({ onCharacterCreated }) => {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [appearance, setAppearance] = useState('');
  const [personality, setPersonality] = useState('');
  const [background, setBackground] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [createdCharacter, setCreatedCharacter] = useState(null);
  const navigate = useNavigate();
  const [isEmailVerified] = useEmailVerificationStatus();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailVerified) {
      setError('Please verify your email before creating a character.');
      return;
    }
    setLoading(true);
    setError('');

    try {
      const response = await api.createCharacter({ name, age, appearance, personality, background });
      setCreatedCharacter(response.data);
      setLoading(false);
      if (onCharacterCreated) {
        onCharacterCreated(response.data);
      } else {
        navigate('/characters');
      }
    } catch (err) {
      setLoading(false);
      setError('Failed to create character. Please try again.');
      console.error('Error creating character:', err);
    }
  };

  return (
    <Box sx={{ width: '100%', padding: '0 4px', boxSizing: 'border-box' }}>
      <StyledPaper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {!isEmailVerified && <EmailVerificationAlert />}
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Create a New Character
        </Typography>

        <CharacterForm
          name={name}
          setName={setName}
          age={age}
          setAge={setAge}
          appearance={appearance}
          setAppearance={setAppearance}
          personality={personality}
          setPersonality={setPersonality}
          background={background}
          setBackground={setBackground}
          loading={loading}
          error={error}
          isEmailVerified={isEmailVerified}
          onSubmit={handleSubmit}
        />
      </StyledPaper>

      <CharacterPreview character={createdCharacter} />
    </Box>
  );
};

export default CharacterCreator;
