import React from 'react';
import { Box, Grid, FormControl, InputLabel, MenuItem } from '@mui/material';
import { StyledTextField, StyledSelect, StyledButton } from './styles';

const EditorForm = ({
  title,
  setTitle,
  content,
  setContent,
  prompt,
  summary,
  setSummary,
  length,
  setLength,
  genre,
  setGenre,
  handleRegenerateSummary,
  loading,
  validationErrors = {}
}) => {
  return (
    <>
      <StyledTextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
        margin="normal"
        error={!!validationErrors.title}
        helperText={validationErrors.title}
      />

      <StyledTextField
        fullWidth
        label="Original Prompt"
        value={prompt}
        margin="normal"
        multiline
        rows={3}
        InputProps={{
          readOnly: true,
        }}
      />

      <StyledTextField
        fullWidth
        label="Summary"
        value={summary}
        onChange={(e) => setSummary(e.target.value)}
        required
        margin="normal"
        multiline
        rows={3}
      />

      <Box mt={1} mb={2}>
        <StyledButton
          onClick={handleRegenerateSummary}
          variant="contained"
          color="secondary"
          disabled={loading}
          fullWidth
        >
          Regenerate Summary
        </StyledButton>
      </Box>

      <StyledTextField
        fullWidth
        label="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        required
        margin="normal"
        multiline
        rows={20}
        error={!!validationErrors.content}
        helperText={validationErrors.content}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Story Length</InputLabel>
            <StyledSelect
              value={length}
              onChange={(e) => setLength(e.target.value)}
              label="Story Length"
            >
              <MenuItem value="short">Short</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="long">Long</MenuItem>
            </StyledSelect>
          </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Genre</InputLabel>
            <StyledSelect
              value={genre}
              onChange={(e) => setGenre(e.target.value)}
              label="Genre"
            >
              <MenuItem value="fantasy">Fantasy</MenuItem>
              <MenuItem value="scifi">Science Fiction</MenuItem>
              <MenuItem value="mystery">Mystery</MenuItem>
              <MenuItem value="adventure">Adventure</MenuItem>
              <MenuItem value="spooky">Spooky</MenuItem>
              <MenuItem value="romance">Romance</MenuItem>
              <MenuItem value="historical fiction">Historical Fiction</MenuItem>
              <MenuItem value="funny">Funny</MenuItem>
              <MenuItem value="western">Western</MenuItem>
              <MenuItem value="fairytale">Fairytale</MenuItem>
              <MenuItem value="mythology">Mythology</MenuItem>
              <MenuItem value="superhero">Superhero</MenuItem>
              <MenuItem value="real life">Real Life</MenuItem>
            </StyledSelect>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default EditorForm;
