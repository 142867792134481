import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api, { getAvatarUrl, getThumbnailUrl } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Pagination,
  Avatar,
  AvatarGroup,
  Tooltip,
  CardActionArea,
  useTheme,
  useMediaQuery,
  Skeleton,
  IconButton,
  Stack,
  Alert,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const PublicStoriesBrowser = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser } = useAuth();

  const fetchPublicStories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.getPublicStories(page);
      setStories(response.data.stories);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch public stories. Please try again.');
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchPublicStories();
  }, [fetchPublicStories, currentUser]);

  const handleFavoriteToggle = async (e, story) => {
    e.preventDefault(); // Prevent navigation
    e.stopPropagation(); // Prevent event bubbling
    try {
      if (story.isFavorited) {
        await api.removeFromFavorites(story._id);
      } else {
        await api.addToFavorites(story._id);
      }
      // Update the story's favorite status and count in the local state
      setStories(prev => prev.map(s => 
        s._id === story._id ? { 
          ...s, 
          isFavorited: !s.isFavorited,
          favoriteCount: s.favoriteCount + (s.isFavorited ? -1 : 1)
        } : s
      ));
    } catch (err) {
      setError('Failed to update favorites. Please try again.');
      console.error('Error updating favorites:', err);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Discover Public Stories
          </Typography>
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" width="100%" height={0} style={{ paddingTop: '100%', marginBottom: '8px' }} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Discover Public Stories
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {error}
          </Alert>
        )}
        {stories.length === 0 ? (
          <Typography variant="body1">No public stories available at the moment.</Typography>
        ) : (
          <Grid container spacing={2}>
            {stories.map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story._id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardActionArea component={RouterLink} to={`/public-story/${story._id}`} sx={{ flexGrow: 1 }}>
                    <CardMedia
                      component="img"
                      sx={{
                        aspectRatio: '1 / 1',
                        objectFit: 'cover',
                      }}
                      image={story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg'}
                      alt={story.title}
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/placeholder-image.jpg';
                      }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div" sx={{ 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        lineHeight: '1.3em',
                        height: '2.6em'
                      }}>
                        {story.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                      }}>
                        {story.summary || 'No summary available.'}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Box sx={{ p: 1 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <AvatarGroup max={isMobile ? 3 : 4} sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
                        {story.characters && story.characters.map((character) => (
                          <Tooltip title={character.name} key={character._id}>
                            <Avatar
                              alt={character.name}
                              src={getAvatarUrl(character.avatarUrl)}
                              imgProps={{
                                loading: "lazy",
                                onError: (e) => {
                                  e.target.onerror = null;
                                  e.target.src = '/placeholder-avatar.jpg';
                                },
                              }}
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                      <Box display="flex" alignItems="center">
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          By: {story.user && story.user.name ? story.user.name : 'Unknown'}
                        </Typography>
                        {currentUser && (
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            <IconButton
                              onClick={(e) => handleFavoriteToggle(e, story)}
                              color="primary"
                              size="small"
                            >
                              {story.isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                            </IconButton>
                            <Typography variant="caption" color="text.secondary">
                              {story.favoriteCount || 0}
                            </Typography>
                          </Stack>
                        )}
                      </Box>
                      </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        {stories.length > 0 && (
          <Box mt={4} display="flex" justifyContent="center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default PublicStoriesBrowser;
