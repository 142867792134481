export const calculatePasswordStrength = (password) => {
  let strength = 0;
  if (password.length >= 8) strength += 25;
  if (/[A-Z]/.test(password)) strength += 25;
  if (/[0-9]/.test(password)) strength += 25;
  if (/[^A-Za-z0-9]/.test(password)) strength += 25;
  return strength;
};

export const getPasswordStrengthColor = (strength) => {
  if (strength < 50) return 'error';
  if (strength < 100) return 'warning';
  return 'success';
};

export const isStrongPassword = (password) => {
  return calculatePasswordStrength(password) === 100;
};
