import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import api, { getThumbnailUrl, getAvatarUrl } from '../services/api';
import SharePopup from './SharePopup';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Avatar,
  Alert,
  CardActionArea,
  Chip,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import StarIcon from '@mui/icons-material/Star';
import WarningIcon from '@mui/icons-material/Warning';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledCardMedia = styled(CardMedia)({
  paddingTop: '100%',
  backgroundSize: 'cover',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  margin: 'auto',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '300px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
  },
}));

const UserInfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  background: theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
  borderRadius: '10px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: '5px',
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.default
    : 'rgba(255, 255, 255, 0.7)',
}));

const InfoIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  background: theme.palette.mode === 'dark'
    ? theme.palette.action.hover
    : 'rgba(0, 123, 255, 0.1)',
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  marginLeft: 'auto',
  color: theme.palette.text.secondary,
  fontWeight: 500,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(3),
}));

const AdminUserView = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { websocketService } = useAuth();
  const [user, setUser] = useState(null);
  const [stories, setStories] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [newRole, setNewRole] = useState('');
  const [editedUser, setEditedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      const userResponse = await api.getUserById(userId);
      setUser(userResponse.data.user);
      setEditedUser(userResponse.data.user);
      setStories(userResponse.data.stories.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      setCharacters(userResponse.data.characters);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to load user data. Please try again.');
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenDialog = (action) => {
    setDialogAction(action);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogAction('');
    setNewRole('');
    setEditedUser(user);
  };

  const handleConfirmAction = async () => {
    try {
      switch (dialogAction) {
        case 'delete':
          await api.deleteUser(userId);
          navigate('/admin/users');
          break;
        case 'resetPassword':
          await api.resetUserPassword(userId);
          break;
        case 'deactivate':
          await api.deactivateUser(userId, 'Admin deactivation');
          break;
        case 'activate':
          await api.activateUser(userId);
          break;
        case 'changeRole':
          await api.changeUserRole(userId, newRole);
          break;
        case 'editUser':
          await api.updateUser(userId, editedUser);
          setUser(editedUser);
          break;
        default:
          break;
      }
      fetchUserData();
      handleCloseDialog();
    } catch (error) {
      console.error(`Error performing ${dialogAction}:`, error);
      setError(`Failed to ${dialogAction} user. Please try again.`);
    }
  };

  const handleEditUserChange = (event) => {
    const { name, value } = event.target;
    setEditedUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleShareDialogOpen = (story) => {
    setSelectedStory(story);
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
    setSelectedStory(null);
  };

  const handleVisibilityChange = async (newVisibility) => {
    try {
      const response = await api.toggleStoryVisibility(selectedStory._id);
      const updatedStories = stories.map(story => 
        story._id === selectedStory._id ? { ...story, visibility: response.data.visibility } : story
      );
      setStories(updatedStories);
      setSelectedStory({ ...selectedStory, visibility: response.data.visibility });
    } catch (error) {
      console.error('Error changing story visibility:', error);
      setError('Failed to change story visibility. Please try again.');
    }
  };

  const handleShareabilityChange = async (newShareability) => {
    try {
      const response = await api.toggleStoryShareability(selectedStory._id);
      const updatedStories = stories.map(story => 
        story._id === selectedStory._id ? { ...story, isShareable: response.data.isShareable, shareLink: response.data.shareLink } : story
      );
      setStories(updatedStories);
      setSelectedStory({ ...selectedStory, isShareable: response.data.isShareable, shareLink: response.data.shareLink });
    } catch (error) {
      console.error('Error changing story shareability:', error);
      setError('Failed to change story shareability. Please try again.');
    }
  };

  const handleVerifyEmail = async () => {
    try {
      await api.adminVerifyEmail(userId);
      await fetchUserData();
      setSuccessMessage('User email has been verified successfully');
      websocketService.emit('emailVerificationChanged', { userId, isVerified: true });
    } catch (error) {
      setError('Failed to verify user email. Please try again.');
      console.error('Error verifying email:', error);
    }
  };

  const handleUnverifyEmail = async () => {
    try {
      await api.adminUnverifyEmail(userId);
      await fetchUserData();
      setSuccessMessage('User email has been set as unverified successfully');
      websocketService.emit('emailVerificationChanged', { userId, isVerified: false });
    } catch (error) {
      setError('Failed to set user email as unverified. Please try again.');
      console.error('Error unverifying email:', error);
    }
  };
  
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const handleResetPasswordClick = () => {
    setOpenResetPasswordDialog(true);
  };

  const handleResetPasswordConfirm = async () => {
    try {
      await api.resetUserPassword(userId);
      setSuccessMessage('Password reset email has been sent to the user.');
      setOpenResetPasswordDialog(false);
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Failed to reset password. Please try again.');
    }
  };

  // ... (keep other functions)

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!user) {
    return <Typography>User not found</Typography>;
  }

  return (
    <StyledContainer maxWidth="lg">
      <Typography variant="h4" gutterBottom align="center">User Details: {user.name}</Typography>
      <UserInfoCard elevation={3}>
        <InfoItem>
          <InfoIcon><EmailIcon fontSize="large" /></InfoIcon>
          <InfoText>Email:</InfoText>
          <InfoValue>{user.email}</InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoIcon><PersonIcon fontSize="large" /></InfoIcon>
          <InfoText>Role:</InfoText>
          <InfoValue>{user.role}</InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoIcon>
            {user.isActive ? <VerifiedUserIcon fontSize="large" color="success" /> : <LockIcon fontSize="large" color="error" />}
          </InfoIcon>
          <InfoText>Status:</InfoText>
          <InfoValue>{user.isActive ? 'Active' : 'Inactive'}</InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoIcon>
            <EmailIcon fontSize="large" color={user.isEmailVerified ? "success" : "warning"} />
          </InfoIcon>
          <InfoText>Email Verified:</InfoText>
          <InfoValue>{user.isEmailVerified ? 'Yes' : 'No'}</InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoIcon><StarIcon fontSize="large" /></InfoIcon>
          <InfoText>Subscription Tier:</InfoText>
          <InfoValue>{user.subscriptionTier}</InfoValue>
        </InfoItem>
      </UserInfoCard>
      <ButtonContainer>
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog('editUser')}>
          Edit User Details
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog('changeRole')}>
          Change Role
        </Button>
        <Button variant="contained" color="secondary" onClick={handleResetPasswordClick}>
          Reset Password
        </Button>
        {user.isActive ? (
          <Button variant="contained" color="error" onClick={() => handleOpenDialog('deactivate')}>
            Deactivate User
          </Button>
        ) : (
          <Button variant="contained" color="success" onClick={() => handleOpenDialog('activate')}>
            Activate User
          </Button>
        )}
        {user.isEmailVerified ? (
          <Button variant="contained" color="warning" onClick={handleUnverifyEmail}>
            Set Email as Unverified
          </Button>
        ) : (
          <Button variant="contained" color="success" onClick={handleVerifyEmail}>
            Verify Email
          </Button>
        )}
      </ButtonContainer>
      {successMessage && (
        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
          {successMessage}
        </Alert>
      )}
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Stories" />
        <Tab label="Characters" />
      </Tabs>
      <Box mt={3}>
      {tabValue === 0 && (
        <Grid container spacing={2}>
          {stories.map((story) => (
            <Grid item xs={12} sm={6} md={4} key={story._id}>
              <StyledCard>
                <CardActionArea component={RouterLink} to={`/admin/stories/${story._id}`} sx={{ flexGrow: 1 }}>
                  <StyledCardMedia
                    image={getThumbnailUrl(story.thumbnailUrl)}
                    title={story.title}
                  />
                  <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                      <Typography gutterBottom variant="h6" component="div">
                        {story.title}
                      </Typography>
                      <Chip
                        icon={story.visibility === 'public' ? <PublicIcon /> : <LockIcon />}
                        label={story.visibility === 'public' ? 'Public' : 'Private'}
                        color={story.visibility === 'public' ? 'primary' : 'default'}
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShareDialogOpen(story);
                        }}
                      />
                    </Box>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      <strong>Genre:</strong> {story.genre}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      <strong>Length:</strong> {story.length}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      <strong>Created:</strong> {new Date(story.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Summary:</strong> {story.summary}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}
        {tabValue === 1 && (
          <Grid container spacing={2}>
            {characters.map((character) => (
              <Grid item xs={12} sm={6} md={4} key={character._id}>
                <StyledCard>
                  <CardActionArea component={RouterLink} to={`/admin/characters/${character._id}`} sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                      <StyledAvatar
                        alt={character.name}
                        src={getAvatarUrl(character.avatarUrl)}
                      />
                    </Box>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div" align="center">
                        {character.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        <strong>Age:</strong> {character.age}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        <strong>Appearance:</strong> {character.appearance || 'Not specified'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        <strong>Personality:</strong> {character.personality || 'Not specified'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" paragraph>
                        <strong>Background:</strong> {character.background || 'Not specified'}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <StyledDialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogAction === 'editUser' ? 'Edit User Details' : 
           dialogAction === 'changeRole' ? 'Change User Role' :
           `${dialogAction.charAt(0).toUpperCase() + dialogAction.slice(1)} User`}
        </DialogTitle>
        <DialogContent>
          {(dialogAction === 'changeRole' || dialogAction === 'editUser') && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {dialogAction === 'changeRole' 
                ? "Be careful with this function. Changing a user's role can significantly affect their permissions."
                : "Be careful with this function. Changing a user's email will affect their ability to login."}
            </Alert>
          )}
          {dialogAction === 'changeRole' ? (
            <FormControl fullWidth>
              <InputLabel id="new-role-label">New Role</InputLabel>
              <Select
                labelId="new-role-label"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
                label="New Role"
              >
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
          ) : dialogAction === 'editUser' ? (
            <>
              <TextField
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                name="name"
                value={editedUser.name}
                onChange={handleEditUserChange}
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                name="email"
                value={editedUser.email}
                onChange={handleEditUserChange}
              />
            </>
          ) : (
            <DialogContentText>
              Are you sure you want to {dialogAction} this user?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmAction} color="primary">Confirm</Button>
        </DialogActions>
      </StyledDialog>
      {selectedStory && (
        <SharePopup
          open={shareDialogOpen}
          onClose={handleShareDialogClose}
          story={selectedStory}
          onVisibilityChange={handleVisibilityChange}
          onShareabilityChange={handleShareabilityChange}
        />
      )}
      <Dialog
        open={openResetPasswordDialog}
        onClose={() => setOpenResetPasswordDialog(false)}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <WarningIcon color="warning" style={{ marginRight: '8px' }} />
            Confirm Password Reset
          </Box>
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mb: 2 }}>
            Be careful with this function. This will send a password reset email to the user.
          </Alert>
          <DialogContentText>
            Are you sure you want to reset the password for this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResetPasswordDialog(false)}>Cancel</Button>
          <Button onClick={handleResetPasswordConfirm} color="primary" variant="contained">
            Confirm Reset
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default AdminUserView;
