import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    // Redirect to the login page with a message
    return <Navigate to="/login" state={{ from: location, message: 'Please log in to access this page' }} replace />;
  }

  if (adminOnly && currentUser.role !== 'admin') {
    // Redirect to the home page if the user is not an admin
    return <Navigate to="/" state={{ message: 'You do not have permission to access this page' }} replace />;
  }

  return children;
};

export default PrivateRoute;
