import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import api, { getAvatarUrl, getThumbnailUrl } from '../services/api';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Avatar,
  AvatarGroup,
  Tooltip,
  Skeleton,
  CardActionArea,
  Paper,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  width: '100%',
  margin: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.3s ease',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '16.5px 14px',
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
}));

const CharacterDetails = () => {
  const [character, setCharacter] = useState(null);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editing, setEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [avatarLoading, setAvatarLoading] = useState(true);
  const [avatarKey, setAvatarKey] = useState(Date.now());
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchCharacterAndStories = useCallback(async () => {
    try {
      setLoading(true);
      const [characterResponse, storiesResponse] = await Promise.all([
        api.getCharacter(id),
        api.getStories(id)
      ]);
      setCharacter(characterResponse.data);
      setStories(storiesResponse.data);
      setLoading(false);
      setAvatarLoading(true); // Reset avatar loading state
    } catch (err) {
      setError('Failed to fetch character and stories. Please try again.');
      setLoading(false);
      console.error('Error fetching character and stories:', err);
    }
  }, [id]);

  useEffect(() => {
    fetchCharacterAndStories();
  }, [fetchCharacterAndStories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCharacter({ ...character, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      const response = await api.updateCharacter(id, character);
      setCharacter(response.data);
      setEditing(false);
      setSuccessMessage('Character updated successfully!');
    } catch (err) {
      setError('Failed to update character. Please try again.');
      console.error('Error updating character:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleRegenerateAvatar = async () => {
    setLoading(true);
    setError('');
    setSuccessMessage('');
    setAvatarLoading(true);
    try {
      const response = await api.regenerateAvatar(id);
      setCharacter(prevCharacter => ({ ...prevCharacter, avatarUrl: response.data.avatarUrl }));
      setAvatarKey(Date.now());
      setSuccessMessage('Avatar regenerated successfully!');
    } catch (err) {
      setError('Failed to regenerate avatar. Please try again.');
      console.error('Error regenerating avatar:', err);
    } finally {
      setLoading(false);
    }
  };

  const toggleEditing = (e) => {
    e.preventDefault();
    setEditing(!editing);
    setError('');
    setSuccessMessage('');
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!character) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6">Character not found.</Typography>
      </Container>
    );
  }

  const avatarUrl = character.avatarUrl ? getAvatarUrl(`${character.avatarUrl}?${avatarKey}`) : '/placeholder-avatar.jpg';

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <StyledPaper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {editing ? 'Edit Character' : 'Character Details'}
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2, width: '100%' }}>{error}</Alert>}
        {successMessage && <Alert severity="success" sx={{ mb: 2, width: '100%' }}>{successMessage}</Alert>}
        <Box display="flex" justifyContent="center" mb={2}>
          {avatarLoading && (
            <Skeleton variant="circular" width={200} height={200} />
          )}
          <Avatar
            src={avatarUrl}
            alt={character.name}
            sx={{
              width: 200,
              height: 200,
              display: avatarLoading ? 'none' : 'block',
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-avatar.jpg';
              setAvatarLoading(false);
            }}
            onLoad={() => setAvatarLoading(false)}
          />
        </Box>
        <Box display="flex" justifyContent="center" mb={2}>
          <StyledButton onClick={handleRegenerateAvatar} variant="outlined">
            Regenerate Avatar
          </StyledButton>
        </Box>
        <Box component="form" onSubmit={editing ? handleSubmit : toggleEditing} sx={{ width: '100%' }}>
          <StyledTextField
            fullWidth
            label="Name"
            name="name"
            value={character.name}
            onChange={handleInputChange}
            margin="normal"
            disabled={!editing}
          />
          <StyledTextField
            fullWidth
            label="Age"
            name="age"
            value={character.age}
            onChange={handleInputChange}
            margin="normal"
            disabled={!editing}
          />
          <StyledTextField
            fullWidth
            label="Appearance"
            name="appearance"
            value={character.appearance}
            onChange={handleInputChange}
            margin="normal"
            multiline
            rows={3}
            disabled={!editing}
          />
          <StyledTextField
            fullWidth
            label="Personality"
            name="personality"
            value={character.personality}
            onChange={handleInputChange}
            margin="normal"
            multiline
            rows={3}
            disabled={!editing}
          />
          <StyledTextField
            fullWidth
            label="Background"
            name="background"
            value={character.background}
            onChange={handleInputChange}
            margin="normal"
            multiline
            rows={3}
            disabled={!editing}
          />
          <Box mt={2}>
            {editing ? (
              <>
                <StyledButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                  Save Changes
                </StyledButton>
                <StyledButton onClick={toggleEditing} variant="outlined">
                  Cancel
                </StyledButton>
              </>
            ) : (
              <StyledButton type="submit" variant="contained" color="primary">
                Edit Character
              </StyledButton>
            )}
          </Box>
        </Box>

        <Box mt={4} width="100%">
          <Typography variant="h5" gutterBottom>Stories Featuring {character.name}</Typography>
          {stories.length === 0 ? (
            <Typography variant="body1">No stories featuring this character yet.</Typography>
          ) : (
            <Grid container spacing={3}>
              {stories.map((story) => (
                <Grid item xs={12} sm={6} md={4} key={story._id}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardActionArea component={RouterLink} to={`/view/${story._id}`} sx={{ flexGrow: 1 }}>
                      <CardMedia
                        component="img"
                        sx={{
                          aspectRatio: '1 / 1',
                          objectFit: 'cover',
                        }}
                        image={story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg'}
                        alt={story.title}
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder-image.jpg';
                        }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {story.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {story.summary ? story.summary.substring(0, 100) + '...' : 'No summary available.'}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <Box sx={{ p: 1 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <AvatarGroup max={4}>
                          {story.characters.map((char) => (
                            <Tooltip title={char.name} key={char._id}>
                              <Avatar
                                alt={char.name}
                                src={getAvatarUrl(char.avatarUrl)}
                                imgProps={{
                                  loading: "lazy",
                                  onError: (e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/placeholder-avatar.jpg';
                                  },
                                }}
                              />
                            </Tooltip>
                          ))}
                        </AvatarGroup>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Box mt={2} width="100%">
          <StyledButton onClick={() => navigate('/characters')} variant="outlined" fullWidth={isMobile}>
            Back to Characters
          </StyledButton>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default CharacterDetails;
