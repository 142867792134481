import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
  Alert,
  AlertTitle
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const ProgressDialog = ({ open, progress, steps }) => {
  console.log('ProgressDialog rendered with:', { open, progress, steps });

  return (
    <Dialog 
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: 24,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold', mb: 2 }}>
        Creating Your Story
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <CircularProgress size={60} thickness={4} sx={{ mb: 3 }} />
          <Alert 
            severity="info"
            icon={<InfoIcon fontSize="inherit" />}
            sx={{ 
              width: '100%', 
              mb: 2,
              '& .MuiAlert-message': {
                width: '100%',
                textAlign: 'center'
              }
            }}
          >
            <AlertTitle sx={{ textAlign: 'center' }}>{progress}</AlertTitle>
          </Alert>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProgressDialog;
