import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import api, { getAvatarUrl, getThumbnailUrl } from '../services/api';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Alert,
  Skeleton,
  Avatar,
  AvatarGroup,
  Tooltip,
  CardActionArea,
  IconButton,
  Stack,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const PublicFavourites = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFavoriteStories();
  }, []);

  const fetchFavoriteStories = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await api.getFavoriteStories();
      setStories(response.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch favorite stories. Please try again.');
      console.error('Error fetching favorite stories:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFromFavorites = async (story) => {
    try {
      await api.removeFromFavorites(story._id);
      setStories(prev => prev.filter(s => s._id !== story._id));
    } catch (err) {
      setError('Failed to remove story from favorites. Please try again.');
      console.error('Error removing from favorites:', err);
    }
  };

  if (loading) {
    return (
      <Box my={4}>
        <Grid container spacing={2}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton variant="rectangular" width="100%" height={0} style={{ paddingTop: '100%', marginBottom: '8px' }} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box my={4}>
      <Typography variant="h4" component="h1" gutterBottom>
        Public Favourites
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Alert>
      )}
      {stories.length === 0 ? (
        <Typography variant="body1">
          You haven't favourited any stories yet. Browse public stories to find stories you like!
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {stories.map((story) => (
            <Grid item xs={12} sm={6} md={4} key={story._id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardActionArea component={RouterLink} to={`/public-story/${story._id}`} sx={{ flexGrow: 1 }}>
                  <CardMedia
                    component="img"
                    sx={{
                      aspectRatio: '1 / 1',
                      objectFit: 'cover',
                    }}
                    image={story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg'}
                    alt={story.title}
                    loading="lazy"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/placeholder-image.jpg';
                    }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{ 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      lineHeight: '1.3em',
                      height: '2.6em'
                    }}>
                      {story.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}>
                      {story.summary || 'No summary available.'}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <Box sx={{ p: 1 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
                      {story.characters && story.characters.map((character) => (
                        <Tooltip title={character.name} key={character._id}>
                          <Avatar
                            alt={character.name}
                            src={getAvatarUrl(character.avatarUrl)}
                            imgProps={{
                              loading: "lazy",
                              onError: (e) => {
                                e.target.onerror = null;
                                e.target.src = '/placeholder-avatar.jpg';
                              },
                            }}
                          />
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                      <Box display="flex" alignItems="center">
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          By: {story.user && story.user.name ? story.user.name : 'Unknown'}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleRemoveFromFavorites(story);
                            }}
                            color="primary"
                            size="small"
                          >
                            <FavoriteIcon />
                          </IconButton>
                          <Typography variant="caption" color="text.secondary">
                            {story.favoriteCount || 0}
                          </Typography>
                        </Stack>
                      </Box>
                      </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PublicFavourites;
