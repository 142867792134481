import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Dialog, DialogContent, Box } from '@mui/material';
import api from '../../services/api';
import CharacterCreator from '../character-creator/CharacterCreator';
import ProgressDialog from '../ProgressDialog';
import EmailVerificationAlert from '../EmailVerificationAlert';
import StoryCounter from '../StoryCounter';
import { useEmailVerificationStatus } from '../../contexts/AuthContext';
import { StyledPaper } from './styles';
import StoryForm from './StoryForm';
import StoryPreviewDialog from './StoryPreviewDialog';
import useStoryWebSocket from '../../hooks/useStoryWebSocket';

const StoryCreator = () => {
  const [prompt, setPrompt] = useState('');
  const [title, setTitle] = useState('');
  const [length, setLength] = useState('medium');
  const [genre, setGenre] = useState('fantasy');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [createdStory, setCreatedStory] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [openCharacterCreator, setOpenCharacterCreator] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [storyLimit, setStoryLimit] = useState(0);
  const [storiesCreated, setStoriesCreated] = useState(0);
  const navigate = useNavigate();
  const [isEmailVerified] = useEmailVerificationStatus();

  const handleProgress = (data) => {
    setProgressMessage(data.progress);
  };

  const handleComplete = async (data) => {
    setCreatedStory(data.story);
    setLoading(false);
    setProgressDialogOpen(false);
    setOpenPreview(true);
    await fetchStoryLimitInfo();
  };

  const handleError = (data) => {
    console.error('Story creation error:', data.error);
    setError(`Failed to create story: ${data.error}`);
    setLoading(false);
    setProgressDialogOpen(false);
  };

  const { createStory } = useStoryWebSocket({
    onProgress: handleProgress,
    onComplete: handleComplete,
    onError: handleError
  });

  useEffect(() => {
    fetchCharacters();
    fetchStoryLimitInfo();
  }, []);

  const fetchCharacters = async () => {
    try {
      const response = await api.getCharacters();
      setCharacters(response.data);
    } catch (err) {
      console.error('Error fetching characters:', err);
      setError('Failed to fetch characters. Please try again.');
    }
  };

  const fetchStoryLimitInfo = async () => {
    try {
      const response = await api.getStoryLimitInfo();
      console.log('Story limit info response:', response);
      if (response && typeof response.limit !== 'undefined' && typeof response.created !== 'undefined') {
        setStoryLimit(response.limit);
        setStoriesCreated(response.created);
      } else {
        console.error('Invalid story limit info response:', response);
        setError('Failed to fetch story limit information. Please try again.');
      }
    } catch (err) {
      console.error('Error fetching story limit info:', err);
      setError('Failed to fetch story limit information. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailVerified) {
      setError('Please verify your email before creating a story.');
      return;
    }
    if (storiesCreated >= storyLimit) {
      setError('You have reached your story creation limit for this period.');
      return;
    }
    setLoading(true);
    setError('');
    setProgressDialogOpen(true);
    setProgressMessage('Initializing story creation...');

    try {
      const story = await createStory({
        prompt,
        title,
        length,
        genre,
        characters: selectedCharacters.map(char => char._id)
      });

      setCreatedStory(story);
      setStoriesCreated(prevCount => prevCount + 1);
    } catch (err) {
      setLoading(false);
      setProgressDialogOpen(false);
      const errorMessage = err.message || 'An unknown error occurred';
      setError(`Failed to create story: ${errorMessage}`);
      console.error('Error creating story:', err);
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    console.log('Navigating to:', `/view/${createdStory._id}`);
    navigate(`/view/${createdStory._id}`);
  };

  const handleCharacterSelect = (event) => {
    const selectedIds = event.target.value;
    const selectedChars = characters.filter(char => selectedIds.includes(char._id));
    setSelectedCharacters(selectedChars);
  };

  const handleCharacterCreated = (newCharacter) => {
    setCharacters([...characters, newCharacter]);
    setSelectedCharacters([...selectedCharacters, newCharacter]);
    setOpenCharacterCreator(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const isCreateDisabled = !isEmailVerified || storiesCreated >= storyLimit || loading;

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {!isEmailVerified && <EmailVerificationAlert />}
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Create a New Story
        </Typography>
        <Box sx={{ mt: 4, width: '100%' }}>
          <StoryCounter />
        </Box>

        <StoryForm
          title={title}
          setTitle={setTitle}
          prompt={prompt}
          setPrompt={setPrompt}
          length={length}
          setLength={setLength}
          genre={genre}
          setGenre={setGenre}
          characters={characters}
          selectedCharacters={selectedCharacters}
          handleCharacterSelect={handleCharacterSelect}
          handleCancel={handleCancel}
          openCharacterCreator={() => setOpenCharacterCreator(true)}
          loading={loading}
          error={error}
          isCreateDisabled={isCreateDisabled}
          onSubmit={handleSubmit}
        />
      </StyledPaper>

      <ProgressDialog open={progressDialogOpen} progress={progressMessage} />
      
      <StoryPreviewDialog
        open={openPreview}
        onClose={handleClosePreview}
        story={createdStory}
      />

      <Dialog 
        open={openCharacterCreator} 
        onClose={() => setOpenCharacterCreator(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <CharacterCreator onCharacterCreated={handleCharacterCreated} />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default StoryCreator;
