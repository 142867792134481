import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Container, Paper, Typography, Box, CircularProgress, Button, Grid, TextField, LinearProgress, Alert, Avatar, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import api from '../services/api';
import { Navigate, Link as RouterLink } from 'react-router-dom';
import StoryCounter from './StoryCounter';
import { LibraryBooks, Person, CreditCard, Security } from '@mui/icons-material';
import { calculatePasswordStrength, getPasswordStrengthColor, isStrongPassword } from '../utils/passwordUtils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(145deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  margin: 'auto',
  marginBottom: theme.spacing(3),
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1.5, 3),
  fontWeight: 'bold',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1e3a5f' : undefined,
  color: theme.palette.mode === 'dark' ? '#ffffff' : undefined,
}));

const UserProfile = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { currentUser, fetchUserProfile } = useAuth();

  // Password change state
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        await fetchUserProfile();
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError('Failed to load user profile. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadUserProfile();
  }, [fetchUserProfile]);

  useEffect(() => {
    setPasswordStrength(calculatePasswordStrength(newPassword));
  }, [newPassword]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setSuccessMessage('');
    setError('');

    if (newPassword !== confirmNewPassword) {
      setPasswordError('New passwords do not match');
      return;
    }

    if (!isStrongPassword(newPassword)) {
      setPasswordError('Password must be at least 8 characters long and contain at least one capital letter, one number, and one symbol');
      return;
    }

    setIsChangingPassword(true);
    try {
      await api.changePassword(oldPassword, newPassword);
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setSuccessMessage('Password changed successfully');
    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordError(error.response?.data?.message || 'Failed to change password. Please try again.');
    } finally {
      setIsChangingPassword(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!currentUser) {
    console.log('No current user, redirecting to login');
    return <Navigate to="/login" />;
  }

  if (error) {
    return (
      <Container maxWidth="xs">
        <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5" color="error">
            {error}
          </Typography>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()} sx={{ mt: 3 }}>
            Retry
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <StyledAvatar alt={currentUser?.name} src="/path-to-user-avatar.jpg" />
        <Typography component="h1" variant="h4" gutterBottom color="text.primary">
          Welcome, {currentUser?.name}!
        </Typography>
        {currentUser && (
          <Box sx={{ mt: 3, width: '100%', textAlign: 'center' }}>
            <Typography variant="body1" color="text.secondary">
              {currentUser.email} • {currentUser.role}
            </Typography>
          </Box>
        )}
        
        <Box sx={{ mt: 4, width: '100%' }}>
          <StoryCounter />
        </Box>

        <Grid container spacing={3} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={4}>
            <StyledButton
              component={RouterLink}
              to="/library"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<LibraryBooks />}
            >
              My Stories
            </StyledButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledButton
              component={RouterLink}
              to="/characters"
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<Person />}
            >
              My Characters
            </StyledButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledButton
              component={RouterLink}
              to="/subscription"
              variant="contained"
              color="info"
              fullWidth
              startIcon={<CreditCard />}
            >
              Manage Subscription
            </StyledButton>
          </Grid>
        </Grid>

        {/* Security Section */}
        <Box sx={{ mt: 6, width: '100%' }}>
          <Typography component="h2" variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', color: 'text.primary' }}>
            <Security sx={{ mr: 1 }} /> Security Settings
          </Typography>
          {successMessage && (
            <StyledAlert severity="success" sx={{ mt: 2, width: '100%' }}>
              {successMessage}
            </StyledAlert>
          )}
          {passwordError && (
            <StyledAlert severity="error" sx={{ mt: 2, width: '100%' }}>
              {passwordError}
            </StyledAlert>
          )}
          <form onSubmit={handlePasswordChange}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Current Password"
              type="password"
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              sx={{
                '& .MuiInputBase-input': {
                  color: theme.palette.text.primary,
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{
                '& .MuiInputBase-input': {
                  color: theme.palette.text.primary,
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
              }}
            />
            <LinearProgress
              variant="determinate"
              value={passwordStrength}
              color={getPasswordStrengthColor(passwordStrength)}
              sx={{ mt: 1 }}
            />
            <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary' }}>
              Password strength: {passwordStrength}%
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmNewPassword"
              label="Confirm New Password"
              type="password"
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              sx={{
                '& .MuiInputBase-input': {
                  color: theme.palette.text.primary,
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isChangingPassword}
            >
              {isChangingPassword ? <CircularProgress size={24} /> : 'Change Password'}
            </Button>
          </form>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default UserProfile;
