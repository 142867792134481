import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api, { getNarrationUrl } from '../services/api';
import websocketService from '../services/websocketService';

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [currentStory, setCurrentStory] = useState(() => {
    const saved = localStorage.getItem('currentStory');
    return saved ? JSON.parse(saved) : null;
  });
  const [queue, setQueue] = useState(() => {
    const saved = localStorage.getItem('queue');
    return saved ? JSON.parse(saved) : [];
  });
  const [playlists, setPlaylists] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(() => {
    const saved = localStorage.getItem('currentTime');
    return saved ? parseFloat(saved) : 0;
  });
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(() => {
    const saved = localStorage.getItem('volume');
    return saved ? parseFloat(saved) : 1;
  });
  const [isMuted, setIsMuted] = useState(() => {
    const saved = localStorage.getItem('isMuted');
    return saved ? JSON.parse(saved) : false;
  });
  const [shuffle, setShuffle] = useState(() => {
    const saved = localStorage.getItem('shuffle');
    return saved ? JSON.parse(saved) : false;
  });
  const [repeat, setRepeat] = useState(() => {
    const saved = localStorage.getItem('repeat');
    return saved ? JSON.parse(saved) : false;
  });
  const [audio] = useState(new Audio());

  // Initialize audio state from localStorage
  useEffect(() => {
    if (currentStory?.narrationUrl) {
      audio.src = getNarrationUrl(currentStory.narrationUrl);
      audio.currentTime = currentTime;
      audio.volume = volume;
      audio.muted = isMuted;
    }
  }, []);

  // Save state to localStorage
  useEffect(() => {
    if (currentStory) {
      localStorage.setItem('currentStory', JSON.stringify(currentStory));
    }
  }, [currentStory]);

  useEffect(() => {
    localStorage.setItem('queue', JSON.stringify(queue));
  }, [queue]);

  useEffect(() => {
    localStorage.setItem('currentTime', currentTime.toString());
  }, [currentTime]);

  useEffect(() => {
    localStorage.setItem('volume', volume.toString());
  }, [volume]);

  useEffect(() => {
    localStorage.setItem('isMuted', JSON.stringify(isMuted));
  }, [isMuted]);

  useEffect(() => {
    localStorage.setItem('shuffle', JSON.stringify(shuffle));
  }, [shuffle]);

  useEffect(() => {
    localStorage.setItem('repeat', JSON.stringify(repeat));
  }, [repeat]);

  // Load playlists on mount
  useEffect(() => {
    loadPlaylists();
  }, []);

  // Handle audio events
  useEffect(() => {
    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleDurationChange = () => setDuration(audio.duration);
    const handleEnded = () => {
      if (repeat) {
        audio.currentTime = 0;
        audio.play();
      } else {
        playNext();
      }
    };
    const handleLoadedMetadata = () => {
      audio.play()
        .then(() => {
          setIsPlaying(true);
          websocketService.emit('playbackAction', { 
            action: 'play', 
            storyId: currentStory?._id,
            currentTime: 0
          });
        })
        .catch(error => {
          console.error('Error playing audio:', error);
        });
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [audio, currentStory, repeat]);

  // Handle websocket events for multi-device sync
  useEffect(() => {
    websocketService.on('playbackStatus', handlePlaybackStatus);
    websocketService.on('queueUpdated', handleQueueUpdate);
    websocketService.on('playlistUpdated', handlePlaylistUpdate);

    return () => {
      websocketService.off('playbackStatus', handlePlaybackStatus);
      websocketService.off('queueUpdated', handleQueueUpdate);
      websocketService.off('playlistUpdated', handlePlaylistUpdate);
    };
  }, []);

  const handleStopPlayback = useCallback(() => {
    console.log('Received stopPlayback event');
    audio.pause();
    setIsPlaying(false);
    localStorage.setItem('currentTime', audio.currentTime.toString());
  }, [audio]);

  const loadPlaylists = async () => {
    try {
      const response = await api.getPlaylists();
      setPlaylists(response.data);
    } catch (error) {
      console.error('Error loading playlists:', error);
    }
  };

  const createPlaylist = async (name) => {
    try {
      const response = await api.createPlaylist(name);
      const newPlaylist = response.data;
      setPlaylists([...playlists, newPlaylist]);
      websocketService.emit('playlistAction', { 
        action: 'create', 
        playlist: newPlaylist 
      });
      return newPlaylist;
    } catch (error) {
      console.error('Error creating playlist:', error);
      throw error;
    }
  };

  const updatePlaylist = async (playlistId, updates) => {
    try {
      const response = await api.updatePlaylist(playlistId, updates);
      const updatedPlaylist = response.data;
      setPlaylists(playlists.map(p => p._id === playlistId ? updatedPlaylist : p));
      websocketService.emit('playlistAction', { 
        action: 'update', 
        playlist: updatedPlaylist 
      });
      return updatedPlaylist;
    } catch (error) {
      console.error('Error updating playlist:', error);
      throw error;
    }
  };

  const deletePlaylist = async (playlistId) => {
    try {
      await api.deletePlaylist(playlistId);
      setPlaylists(playlists.filter(p => p._id !== playlistId));
      websocketService.emit('playlistAction', { 
        action: 'delete', 
        playlistId 
      });
    } catch (error) {
      console.error('Error deleting playlist:', error);
      throw error;
    }
  };

  const addToPlaylist = async (playlistId, storyId) => {
    try {
      const response = await api.addToPlaylist(playlistId, storyId);
      const updatedPlaylist = response.data;
      setPlaylists(playlists.map(p => p._id === playlistId ? updatedPlaylist : p));
      websocketService.emit('playlistAction', { 
        action: 'addStory', 
        playlistId,
        storyId 
      });
      return updatedPlaylist;
    } catch (error) {
      console.error('Error adding story to playlist:', error);
      throw error;
    }
  };

  const removeFromPlaylist = async (playlistId, storyId) => {
    try {
      const response = await api.removeFromPlaylist(playlistId, storyId);
      const updatedPlaylist = response.data;
      setPlaylists(playlists.map(p => p._id === playlistId ? updatedPlaylist : p));
      websocketService.emit('playlistAction', { 
        action: 'removeStory', 
        playlistId,
        storyId 
      });
      return updatedPlaylist;
    } catch (error) {
      console.error('Error removing story from playlist:', error);
      throw error;
    }
  };

  const reorderPlaylist = async (playlistId, startIndex, endIndex) => {
    try {
      // Find the playlist to get isSystem and type
      const playlist = playlists.find(p => p._id === playlistId);
      if (!playlist) {
        throw new Error('Playlist not found');
      }

      const response = await api.reorderPlaylist(
        playlistId, 
        startIndex, 
        endIndex,
        playlist.isSystem,
        playlist.type
      );
      const updatedPlaylist = response.data;
      setPlaylists(playlists.map(p => p._id === playlistId ? updatedPlaylist : p));
      websocketService.emit('playlistAction', { 
        action: 'reorder', 
        playlistId,
        startIndex,
        endIndex,
        isSystem: playlist.isSystem,
        type: playlist.type
      });
      return updatedPlaylist;
    } catch (error) {
      console.error('Error reordering playlist:', error);
      throw error;
    }
  };

  const handlePlaybackStatus = (status) => {
    setIsPlaying(status.isPlaying);
    setCurrentTime(status.currentTime);
    if (status.storyId !== currentStory?._id) {
      // Pass isPublic flag from status
      loadStory(status.storyId, status.isPublic);
    }
  };

  const handleQueueUpdate = (newQueue) => {
    setQueue(newQueue);
  };

  const handlePlaylistUpdate = (update) => {
    switch (update.action) {
      case 'create':
        setPlaylists([...playlists, update.playlist]);
        break;
      case 'update':
        setPlaylists(playlists.map(p => p._id === update.playlist._id ? update.playlist : p));
        break;
      case 'delete':
        setPlaylists(playlists.filter(p => p._id !== update.playlistId));
        break;
      default:
        console.warn('Unknown playlist update action:', update.action);
    }
  };

  const replaceQueue = (stories) => {
    setQueue(stories);
    websocketService.emit('queueAction', { 
      action: 'replace',
      queue: stories
    });
  };

  const appendToQueue = (stories) => {
    setQueue(prevQueue => [...prevQueue, ...stories]);
    websocketService.emit('queueAction', { 
      action: 'append',
      stories
    });
  };

  const handlePlayPlaylist = (playlist, startIndex = 0) => {
    if (!playlist?.stories?.length) return;

    const firstStory = playlist.stories[startIndex];
    // Pass the isPublic flag from the story object
    loadStory(firstStory._id, firstStory.isPublic);
    play();

    // Replace queue with remaining stories
    const remainingStories = playlist.stories.slice(startIndex + 1);
    replaceQueue(remainingStories);
  };

  const loadStory = async (storyId, isPublic = false) => {
    try {
      const response = await api.getStory(storyId, isPublic);
      const story = response.data;
      
      audio.src = getNarrationUrl(story.narrationUrl);
      
      setCurrentStory({
        ...story,
        isPublic, // Preserve isPublic flag
        metadata: {
          title: story.title,
          artist: story.user?.name || 'Unknown User',
          composer: 'StoriesNow',
          album: 'StoriesNow Stories',
          genre: story.genre,
          year: new Date(story.createdAt).getFullYear(),
          summary: story.summary,
          content: story.content
        }
      });

      audio.load();
    } catch (error) {
      console.error('Error loading story:', error);
      throw error; // Re-throw error to handle it in the caller
    }
  };

  const play = () => {
    audio.play();
    setIsPlaying(true);
    websocketService.emit('playbackAction', { 
      action: 'play', 
      storyId: currentStory?._id,
      currentTime: audio.currentTime 
    });
  };

  const pause = () => {
    audio.pause();
    setIsPlaying(false);
    websocketService.emit('playbackAction', { 
      action: 'pause', 
      storyId: currentStory?._id,
      currentTime: audio.currentTime 
    });
  };

  const togglePlay = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  const seek = (time) => {
    audio.currentTime = time;
    websocketService.emit('playbackAction', { 
      action: 'seek', 
      storyId: currentStory?._id,
      currentTime: time 
    });
  };

  const setVolumeLevel = (level) => {
    const newVolume = Math.max(0, Math.min(1, level));
    audio.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };

  const toggleMute = () => {
    if (isMuted) {
      audio.volume = volume;
      setIsMuted(false);
    } else {
      audio.volume = 0;
      setIsMuted(true);
    }
  };

  const addToQueue = (story) => {
    setQueue(prevQueue => [...prevQueue, story]);
    websocketService.emit('queueAction', { 
      action: 'add', 
      story 
    });
  };

  const removeFromQueueByIndex = (index) => {
    setQueue(prevQueue => {
      const newQueue = [...prevQueue];
      newQueue.splice(index, 1);
      return newQueue;
    });
    websocketService.emit('queueAction', { 
      action: 'removeByIndex', 
      index
    });
  };

  const removeItemsUpToIndex = (index) => {
    setQueue(prevQueue => prevQueue.slice(index + 1));
    websocketService.emit('queueAction', { 
      action: 'removeUpToIndex', 
      index
    });
  };

  const playNext = () => {
    if (queue.length > 0) {
      const nextStory = shuffle 
        ? queue[Math.floor(Math.random() * queue.length)]
        : queue[0];
      // Pass the isPublic flag from the story object
      loadStory(nextStory._id, nextStory.isPublic);
      removeItemsUpToIndex(0);
      websocketService.emit('queueAction', { 
        action: 'removeUpToIndex', 
        index: 0
      });
    }
  };

  const playPrevious = () => {
    if (audio.currentTime > 3) {
      // If more than 3 seconds in, restart current song
      audio.currentTime = 0;
      audio.play();
    } else if (currentStory) {
      // Otherwise restart current song anyway since we don't keep history
      audio.currentTime = 0;
      audio.play();
    }
  };

  const reorderQueue = (startIndex, endIndex) => {
    const items = Array.from(queue);
    const [reorderedItem] = items.splice(startIndex, 1);
    items.splice(endIndex, 0, reorderedItem);
    setQueue(items);
    websocketService.emit('queueAction', { 
      action: 'reorder',
      queue: items
    });
  };

  const toggleShuffle = () => {
    setShuffle(!shuffle);
  };

  const toggleRepeat = () => {
    setRepeat(!repeat);
  };

  const clearQueue = () => {
    setQueue([]);
    websocketService.emit('queueAction', { 
      action: 'clear'
    });
  };

  const value = {
    currentStory,
    queue,
    playlists,
    isPlaying,
    currentTime,
    duration,
    volume,
    isMuted,
    shuffle,
    repeat,
    play,
    pause,
    togglePlay,
    seek,
    setVolume: setVolumeLevel,
    toggleMute,
    playNext,
    playPrevious,
    addToQueue,
    toggleShuffle,
    toggleRepeat,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    addToPlaylist,
    removeFromPlaylist,
    reorderPlaylist,
    loadPlaylists,
    loadStory,
    removeFromQueueByIndex,
    removeItemsUpToIndex,
    reorderQueue,
    replaceQueue,
    appendToQueue,
    handlePlayPlaylist,
    clearQueue,
  };

  return (
    <AudioPlayerContext.Provider value={value}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider');
  }
  return context;
};
