import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import api, { getAvatarUrl, getThumbnailUrl } from '../services/api';
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Avatar,
  AvatarGroup,
  Tooltip,
  Skeleton,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '400px',
  },
}));

const AdminCharacterView = () => {
  const [character, setCharacter] = useState(null);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [avatarLoading, setAvatarLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogStep, setDialogStep] = useState('confirm');
  const { characterId } = useParams();
  const navigate = useNavigate();

  const fetchCharacterAndStories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.adminGetCharacterById(characterId);
      if (response.data && response.data.character) {
        setCharacter(response.data.character);
        setStories(response.data.stories || []);
      } else {
        setError('Character data is missing or invalid');
      }
    } catch (err) {
      console.error('Error in fetchCharacterAndStories:', err);
      setError(`Failed to fetch character and stories. Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [characterId]);

  useEffect(() => {
    fetchCharacterAndStories();
  }, [fetchCharacterAndStories]);

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
    setDialogStep('confirm');
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDialogStep('confirm');
  };

  const handleDeleteCharacter = async () => {
    try {
      await api.adminDeleteCharacter(characterId);
      setDialogStep('success');
    } catch (err) {
      console.error('Error deleting character:', err);
      setError(`Failed to delete character. Error: ${err.message}`);
      handleCloseDeleteDialog();
    }
  };

  const handleSuccessClose = () => {
    handleCloseDeleteDialog();
    navigate(`/admin/users/${character.user._id}`);
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error">{error}</Typography>
        <Button onClick={() => navigate('/admin')} variant="outlined" sx={{ mt: 2 }}>
          Back to Admin Dashboard
        </Button>
      </Container>
    );
  }

  if (!character) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6">Character not found.</Typography>
        <Button onClick={() => navigate('/admin')} variant="outlined" sx={{ mt: 2 }}>
          Back to Admin Dashboard
        </Button>
      </Container>
    );
  }

  const avatarUrl = character.avatarUrl ? getAvatarUrl(character.avatarUrl) : '/placeholder-avatar.jpg';

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Character Details: {character.name}
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Box display="flex" justifyContent="center" mb={2}>
          {avatarLoading && (
            <Skeleton variant="circular" width={200} height={200} />
          )}
          <Avatar
            src={avatarUrl}
            alt={character.name}
            sx={{
              width: 200,
              height: 200,
              display: avatarLoading ? 'none' : 'block',
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-avatar.jpg';
              setAvatarLoading(false);
            }}
            onLoad={() => setAvatarLoading(false)}
          />
        </Box>
        <Box mb={2}>
          <Typography><strong>Name:</strong> {character.name}</Typography>
          <Typography><strong>Age:</strong> {character.age}</Typography>
          <Typography><strong>Appearance:</strong> {character.appearance}</Typography>
          <Typography><strong>Personality:</strong> {character.personality}</Typography>
          <Typography><strong>Background:</strong> {character.background}</Typography>
          <Box mt={1}>
            <Chip
              label={`Created by: ${character.user ? character.user.name : 'Unknown'}`}
              component={RouterLink}
              to={`/admin/users/${character.user ? character.user._id : ''}`}
              clickable
              color="primary"
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleOpenDeleteDialog}
          >
            Delete Character
          </Button>
        </Box>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom>Stories Featuring {character.name}</Typography>
          {stories.length === 0 ? (
            <Typography variant="body1">No stories featuring this character yet.</Typography>
          ) : (
            <Grid container spacing={3}>
              {stories.map((story) => (
                <Grid item xs={12} sm={6} md={4} key={story._id}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardActionArea component={RouterLink} to={`/admin/stories/${story._id}`} sx={{ flexGrow: 1 }}>
                      <CardMedia
                        component="img"
                        sx={{
                          aspectRatio: '1 / 1',
                          objectFit: 'cover',
                        }}
                        image={story.thumbnailUrl ? getThumbnailUrl(story.thumbnailUrl) : '/placeholder-image.jpg'}
                        alt={story.title}
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder-image.jpg';
                        }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {story.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {story.summary ? story.summary.substring(0, 100) + '...' : 'No summary available.'}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <Box sx={{ p: 1 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <AvatarGroup max={4}>
                          {story.characters && story.characters.map((char) => (
                            <Tooltip title={char.name} key={char._id}>
                              <Avatar
                                alt={char.name}
                                src={char.avatarUrl ? getAvatarUrl(char.avatarUrl) : '/placeholder-avatar.jpg'}
                                imgProps={{
                                  loading: "lazy",
                                  onError: (e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/placeholder-avatar.jpg';
                                  },
                                }}
                              />
                            </Tooltip>
                          ))}
                        </AvatarGroup>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Box mt={2}>
          <Button onClick={() => navigate('/admin')} variant="outlined">
            Back to Admin Dashboard
          </Button>
        </Box>
      </Box>

      <StyledDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>
          {dialogStep === 'confirm' ? 'Confirm Character Deletion' : 'Character Deleted Successfully'}
        </DialogTitle>
        <DialogContent>
          {dialogStep === 'confirm' && (
            <>
              <Alert severity="warning" sx={{ mb: 2 }}>
                Be careful with this function. Deleting a character cannot be undone.
              </Alert>
              <DialogContentText>
                Are you sure you want to delete this character?
              </DialogContentText>
            </>
          )}
          {dialogStep === 'success' && (
            <DialogContentText>
              The character has been successfully deleted.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {dialogStep === 'confirm' && (
            <>
              <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
              <Button onClick={handleDeleteCharacter} color="error">Delete</Button>
            </>
          )}
          {dialogStep === 'success' && (
            <Button onClick={handleSuccessClose} color="primary">OK</Button>
          )}
        </DialogActions>
      </StyledDialog>
    </Container>
  );
};

export default AdminCharacterView;
