import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import audioPlayerReducer from './slices/audioPlayerSlice';
import { websocketMiddleware } from './middleware/websocketMiddleware';

const persistConfig = {
  key: 'audioPlayer',
  storage,
  whitelist: ['currentStory', 'queue', 'playlists', 'playback']
};

const persistedReducer = persistReducer(persistConfig, audioPlayerReducer);

export const store = configureStore({
  reducer: {
    audioPlayer: persistedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE']
      }
    }).concat(websocketMiddleware)
});

export const persistor = persistStore(store);
