import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api, { getThumbnailUrl } from '../../services/api';
import ShareComponent from '../ShareComponent';
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { StyledPaper, StyledButton } from './styles';
import ThumbnailSection from './ThumbnailSection';
import NarrationSection from './NarrationSection';
import EditorForm from './EditorForm';
import CharacterSelect from './CharacterSelect';
import PreviewDialog from './PreviewDialog';

const StoryEditor = () => {
  const [story, setStory] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [prompt, setPrompt] = useState('');
  const [summary, setSummary] = useState('');
  const [length, setLength] = useState('');
  const [genre, setGenre] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [narrationUrl, setNarrationUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [thumbnailLoading, setThumbnailLoading] = useState(true);
  const [thumbnailError, setThumbnailError] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchStoryAndCharacters = useCallback(async () => {
    try {
      const [storyResponse, charactersResponse] = await Promise.all([
        api.get(`/stories/${id}`),
        api.getCharacters()
      ]);
      const storyData = storyResponse.data;
      setStory(storyData);
      setTitle(storyData.title);
      setContent(storyData.content);
      setOriginalContent(storyData.content);
      const originalPromptMatch = storyData.prompt.match(/Story Prompt: (.+)$/m);
      setPrompt(originalPromptMatch ? originalPromptMatch[1] : 'Original prompt not found');
      setSummary(storyData.summary || '');
      setLength(storyData.length);
      setGenre(storyData.genre);
      setThumbnailUrl(storyData.thumbnailUrl);
      setNarrationUrl(storyData.narrationUrl);
      setSelectedCharacters(storyData.characters);
      setCharacters(charactersResponse.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch story and characters. Please try again.');
      setLoading(false);
      console.error('Error fetching story and characters:', err.response ? err.response.data : err.message);
    }
  }, [id]);

  useEffect(() => {
    fetchStoryAndCharacters();
  }, [fetchStoryAndCharacters]);

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) {
      errors.title = 'Title is required';
    }
    if (!content.trim()) {
      errors.content = 'Content is required';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await api.updateStory(id, { 
        title, 
        content, 
        prompt: story.prompt,
        summary,
        length, 
        genre, 
        characters: selectedCharacters.map(char => char._id),
        visibility: story.visibility,
        isShareable: story.isShareable
      });
      setStory(response.data);
      setThumbnailUrl(response.data.thumbnailUrl);
      setNarrationUrl(response.data.narrationUrl);
      setOriginalContent(content);
      setLoading(false);
      setOpenPreview(true);
    } catch (err) {
      setLoading(false);
      setError('Failed to update story. Please try again.');
      console.error('Error updating story:', err.response ? err.response.data : err.message);
    }
  };

  const handleRegenerateThumbnail = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await api.regenerateThumbnail(id);
      setThumbnailUrl(response.data.thumbnailUrl);
      setLoading(false);
      setOpenPreview(true);
    } catch (err) {
      setLoading(false);
      setError('Failed to regenerate thumbnail. Please try again.');
      console.error('Error regenerating thumbnail:', err.response ? err.response.data : err.message);
    }
  };

  const handleRegenerateNarration = async () => {
    setLoading(true);
    setError('');

    try {
      await api.updateStory(id, { 
        title, 
        content, 
        prompt: story.prompt,
        summary,
        length, 
        genre, 
        characters: selectedCharacters.map(char => char._id)
      });
      
      const response = await api.regenerateNarration(id);
      setNarrationUrl(response.data.narrationUrl);
      setOriginalContent(content);
      setLoading(false);
      setOpenPreview(true);
    } catch (err) {
      setLoading(false);
      setError('Failed to regenerate narration. Please try again.');
      console.error('Error regenerating narration:', err.response ? err.response.data : err.message);
    }
  };

  const handleRegenerateSummary = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await api.regenerateSummary(id);
      setSummary(response.data.summary);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Failed to regenerate summary. Please try again.');
      console.error('Error regenerating summary:', err.response ? err.response.data : err.message);
    }
  };

  const handleUpdateAudioTags = async () => {
    try {
      setLoading(true);
      await api.rewriteID3Tags(id);
      setLoading(false);
    } catch (err) {
      setError('Failed to update ID3 tags. Please try again.');
      setLoading(false);
      console.error('Error updating ID3 tags:', err);
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    navigate(`/view/${id}`);
  };

  const handleCharacterSelect = (event) => {
    const selectedIds = event.target.value;
    const selectedChars = characters.filter(char => selectedIds.includes(char._id));
    setSelectedCharacters(selectedChars);
  };

  const handleCancel = () => {
    navigate(`/view/${id}`);
  };

  const handleVisibilityChange = async (newVisibility) => {
    try {
      const response = await api.toggleStoryVisibility(id);
      setStory({ ...story, visibility: response.data.visibility });
    } catch (err) {
      setError('Failed to update story visibility. Please try again.');
      console.error('Error updating story visibility:', err.response ? err.response.data : err.message);
    }
  };

  const handleShareabilityChange = async (newShareability) => {
    try {
      const response = await api.toggleStoryShareability(id);
      setStory({ ...story, isShareable: response.data.isShareable, shareLink: response.data.shareLink });
    } catch (err) {
      setError('Failed to update story shareability. Please try again.');
      console.error('Error updating story shareability:', err.response ? err.response.data : err.message);
    }
  };

  const isContentChanged = content !== originalContent;

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error">{error}</Typography>
      </Container>
    );
  }

  if (!story) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6">Story not found.</Typography>
      </Container>
    );
  }

  const fullThumbnailUrl = thumbnailUrl ? getThumbnailUrl(thumbnailUrl) : '/placeholder-image.jpg';

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <StyledPaper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Edit Story
        </Typography>

        <ThumbnailSection
          thumbnailUrl={fullThumbnailUrl}
          title={title}
          thumbnailLoading={thumbnailLoading}
          setThumbnailLoading={setThumbnailLoading}
          thumbnailError={thumbnailError}
          setThumbnailError={setThumbnailError}
          handleRegenerateThumbnail={handleRegenerateThumbnail}
          loading={loading}
        />

        <NarrationSection
          narrationUrl={narrationUrl}
          handleRegenerateNarration={handleRegenerateNarration}
          handleUpdateAudioTags={handleUpdateAudioTags}
          loading={loading}
          isContentChanged={isContentChanged}
          id={id}
        />

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
          <EditorForm
            title={title}
            setTitle={setTitle}
            content={content}
            setContent={setContent}
            prompt={prompt}
            summary={summary}
            setSummary={setSummary}
            length={length}
            setLength={setLength}
            genre={genre}
            setGenre={setGenre}
            handleRegenerateSummary={handleRegenerateSummary}
            loading={loading}
            validationErrors={validationErrors}
          />

          <CharacterSelect
            characters={characters}
            selectedCharacters={selectedCharacters}
            handleCharacterSelect={handleCharacterSelect}
            loading={loading}
          />

          <Box mt={3} mb={2}>
            <ShareComponent 
              story={story}
              onVisibilityChange={handleVisibilityChange}
              onShareabilityChange={handleShareabilityChange}
            />
          </Box>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <StyledButton 
                onClick={handleCancel}
                variant="outlined"
                color="secondary"
                disabled={loading}
                fullWidth
              >
                Cancel
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledButton 
                type="submit" 
                variant="contained" 
                color="primary" 
                disabled={loading}
                fullWidth
              >
                {loading ? 'Updating...' : 'Update Story'}
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>

      <PreviewDialog
        open={openPreview}
        onClose={handleClosePreview}
        title={title}
        thumbnailUrl={fullThumbnailUrl}
        selectedCharacters={selectedCharacters}
        isMobile={isMobile}
      />
    </Container>
  );
};

export default StoryEditor;
