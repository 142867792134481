import React, { useState, useMemo, createContext, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { AudioPlayerProvider } from './contexts/AudioPlayerContext';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { setupWebSocketListeners } from './store/middleware/websocketMiddleware';
import api from './services/api';
import Header from './components/Header';
import Footer from './components/Footer';
import AudioPlayer from './components/player/AudioPlayer';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import StoryCreator from './components/story-creator/StoryCreator';
import StoryCloner from './components/story-cloner/StoryCloner';
import StoryViewer from './components/StoryViewer';
import StoryEditor from './components/story-editor/StoryEditor';
import MyLibrary from './components/MyLibrary';
import CharacterLibrary from './components/CharacterLibrary';
import CharacterDetails from './components/CharacterDetails';
import UserProfile from './components/UserProfile';
import AdminDashboard from './components/AdminDashboard';
import AdminUserView from './components/AdminUserView';
import AdminStoryView from './components/AdminStoryView';
import AdminCharacterView from './components/AdminCharacterView';
import PrivateRoute from './components/PrivateRoute';
import PublicStoriesBrowser from './components/PublicStoriesBrowser';
import PublicStoryViewer from './components/PublicStoryViewer';
import PublicFavourites from './components/PublicFavourites';
import FrontPage from './components/FrontPage';
import SubscriptionPage from './components/SubscriptionPage';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';
import PricingPage from './components/PricingPage';
// Set up WebSocket listeners
setupWebSocketListeners(store);

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function VerifyEmail() {
  const { verifyEmail, fetchUserProfile } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verify = async () => {
      if (verificationAttempted.current) {
        console.log('Verification already attempted, skipping API call');
        return;
      }

      verificationAttempted.current = true;

      try {
        console.log('Attempting to verify email with token:', token);
        const message = await verifyEmail(token);
        console.log('Verification successful:', message);
        setVerificationStatus(message);
        
        // Fetch updated user profile
        await fetchUserProfile();
        
        // Redirect to library page after successful verification
        setTimeout(() => {
          navigate('/library');
        }, 3000);
      } catch (error) {
        console.error('Verification failed:', error);
        console.error('Error details:', error.response?.data);
        setVerificationStatus('Verification failed. Please try again.');
      }
    };

    verify();
  }, [token, verifyEmail, fetchUserProfile, navigate]);

  console.log('VerifyEmail component rendered. Status:', verificationStatus);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Typography variant="h6">{verificationStatus}</Typography>
    </Box>
  );
}

function AppContent() {
  const { currentUser, loading } = useAuth();

  useEffect(() => {
    if (currentUser) {
      // Initialize WebSocket connection
      api.connectWebSocket();
    }
  }, [currentUser]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="App" sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          p: 3, 
          // Add extra padding at bottom when player is present
          pb: currentUser ? 20 : 10 
        }}
      >
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/login" element={currentUser ? <Navigate to="/library" replace /> : <Login />} />
          <Route path="/signup" element={currentUser ? <Navigate to="/library" replace /> : <SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="/create" element={<PrivateRoute><StoryCreator /></PrivateRoute>} />
          <Route path="/view/:id" element={<PrivateRoute><StoryViewer /></PrivateRoute>} />
          <Route path="/edit/:id" element={<PrivateRoute><StoryEditor /></PrivateRoute>} />
          <Route path="/clone/:id" element={<PrivateRoute><StoryCloner /></PrivateRoute>} />
          <Route path="/library" element={<PrivateRoute><MyLibrary /></PrivateRoute>} />
          <Route path="/characters" element={<PrivateRoute><CharacterLibrary /></PrivateRoute>} />
          <Route path="/characters/:id" element={<PrivateRoute><CharacterDetails /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
          <Route path="/subscription" element={<PrivateRoute><SubscriptionPage /></PrivateRoute>} />
          <Route path="/admin" element={<PrivateRoute adminOnly><AdminDashboard /></PrivateRoute>} />
          <Route path="/admin/users/:userId" element={<PrivateRoute adminOnly><AdminUserView /></PrivateRoute>} />
          <Route path="/admin/stories/:id" element={<PrivateRoute adminOnly><AdminStoryView /></PrivateRoute>} />
          <Route path="/admin/characters/:characterId" element={<PrivateRoute adminOnly><AdminCharacterView /></PrivateRoute>} />
          <Route path="/discover" element={<PrivateRoute><PublicStoriesBrowser /></PrivateRoute>} />
          <Route path="/public-favourites" element={<PrivateRoute><PublicFavourites /></PrivateRoute>} />
          <Route path="/public-story/:id" element={<PrivateRoute><PublicStoryViewer /></PrivateRoute>} />
          <Route path="/shared/:shareLink" element={<PublicStoryViewer />} />
          <Route path="/terms" element={<TermsOfUse />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/pricing" element={<PricingPage />} />
        </Routes>
      </Box>
      {currentUser && <AudioPlayer />}
      <Box 
        component="footer" 
        sx={{ 
          // Add margin-bottom when player is present
          mb: currentUser ? '80px' : 0 
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
}

function App() {
  const [mode, setMode] = useState('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: mode === 'light' ? '#1976d2' : '#90caf9',
          },
          secondary: {
            main: mode === 'light' ? '#dc004e' : '#f48fb1',
          },
          background: {
            default: mode === 'light' ? '#f5f5f5' : '#303030',
            paper: mode === 'light' ? '#ffffff' : '#424242',
          },
          text: {
            primary: mode === 'light' ? '#000000' : '#ffffff',
          },
        },
        typography: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
        components: {
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: mode === 'light' ? '#ffffff' : '#303030',
                color: mode === 'light' ? '#000000' : '#ffffff',
              },
            },
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                color: mode === 'light' ? '#1976d2' : '#90caf9',
              },
            },
          },
          MuiListItemButton: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
                },
              },
            },
          },
          MuiListItemText: {
            styleOverrides: {
              primary: {
                color: mode === 'light' ? '#000000' : '#ffffff',
              },
            },
          },
        },
      }),
    [mode],
  );

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
              <AudioPlayerProvider>
                <Router>
                  <AppContent />
                </Router>
              </AudioPlayerProvider>
            </AuthProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </PersistGate>
    </Provider>
  );
}

export default App;
