import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  Switch,
  Chip,
  Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';

const SharePopup = ({ open, onClose, story, onVisibilityChange, onShareabilityChange }) => {
  const getFullShareUrl = () => {
    return `${window.location.origin}/shared/${story.shareLink}`;
  };

  const handleCopyLink = () => {
    if (story && story.shareLink) {
      navigator.clipboard.writeText(getFullShareUrl());
    }
  };

  if (!story) {
    return null;
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Share Story
          <Tooltip title={`Your story is ${story.visibility}`} arrow>
            <Chip
              icon={story.visibility === 'public' ? <PublicIcon /> : <LockIcon />}
              label={capitalizeFirstLetter(story.visibility)}
              color={story.visibility === 'public' ? 'primary' : 'default'}
              variant="outlined"
              size="small"
            />
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <VisibilityIcon color={story.visibility === 'public' ? 'primary' : 'action'} sx={{ mr: 1 }} />
            <Typography>
              <span style={{ fontWeight: 600 }}>Make public</span>: other users can discover your story
            </Typography>
          </Box>
          <Switch
            checked={story.visibility === 'public'}
            onChange={() => onVisibilityChange(story.visibility === 'public' ? 'private' : 'public')}
            color="primary"
          />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <ShareIcon color={story.isShareable ? 'primary' : 'action'} sx={{ mr: 1 }} />
            <Typography>
              <span style={{ fontWeight: 600 }}>Share via a link</span>: anyone can access your story with a link
            </Typography>
          </Box>
          <Switch
            checked={story.isShareable}
            onChange={() => onShareabilityChange(!story.isShareable)}
            color="primary"
          />
        </Box>
        {story.isShareable && story.shareLink && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              variant="outlined"
              value={getFullShareUrl()}
              InputProps={{
                readOnly: true,
              }}
            />
            <IconButton onClick={handleCopyLink} sx={{ ml: 1 }} color="primary">
              <ContentCopyIcon />
            </IconButton>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharePopup;
