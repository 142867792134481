import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardMedia,
  Typography,
  Box,
  Grid,
  Avatar
} from '@mui/material';
import { getAvatarUrl } from '../../services/api';
import { StyledButton } from './styles';

const PreviewDialog = ({
  open,
  onClose,
  title,
  thumbnailUrl,
  selectedCharacters,
  isMobile
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>Story Updated Successfully</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your story "{title}" has been updated. Here's the current thumbnail:
        </DialogContentText>
        <Card sx={{ maxWidth: '100%', mt: 2 }}>
          <CardMedia
            component="img"
            sx={{
              aspectRatio: '1 / 1',
              objectFit: 'cover',
            }}
            image={thumbnailUrl}
            alt={title}
          />
        </Card>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>Characters:</Typography>
          <Grid container spacing={2}>
            {selectedCharacters.map((character) => (
              <Grid item key={character._id} xs={6} sm={4} md={3}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Avatar
                    alt={character.name}
                    src={getAvatarUrl(character.avatarUrl)}
                    sx={{ width: 64, height: 64, mb: 1 }}
                  />
                  <Typography variant="body2" align="center">{character.name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose} color="primary">
          View Full Story
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDialog;
